import { ref } from '@nuxtjs/composition-api'

const modalCount = ref(0)

function addModal() {
  modalCount.value += 1
}

function removeModal() {
  modalCount.value -= 1
}

export default function useModalHelper() {
  return {
    modalCount,
    addModal,
    removeModal,
  }
}
