import crypto from './crypto'

const storage = (function () {
  const _storage = process.server
    ? { getItem: () => '', setItem: () => '' }
    : localStorage
  function get(key) {
    const enc = _storage.getItem(key)
    if (!enc) return null
    const dec = crypto.decrypt(enc)
    return dec
  }
  function set(key, value) {
    const enc = crypto.encrpyt(value)
    _storage.setItem(key, enc)
  }
  function remove(key) {
    _storage.removeItem(key)
  }
  function clear() {
    _storage.clear()
  }
  function setToken(token) {
    const split = token.split('.')
    const data = {
      neural: split[0],
      works: split[1],
      studio: split[2],
    }
    set('NWT', data)
  }
  function getToken() {
    const NWT = get('NWT')
    if (!NWT) return null
    const { neural, works, studio } = NWT
    return [neural, works, studio].join('.')
  }
  function removeToken() {
    remove('NWT')
  }
  return Object.freeze({
    setToken,
    getToken,
    removeToken,
    clear,
    set,
    get,
  })
})()

export default storage
