//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useRoute,
  useRouter,
  watch,
} from '@nuxtjs/composition-api'
import useI18nHelper from '@/helpers/i18nHelper'
import useI18N from '@/translations/i18n'
import navigation from '../configs/navigation'
import useGlobal from '../util/global'
import useSigninState from '../state/auth/signinState'
import storage from '../util/storage'
import useNotificationState from '../state/ns/notifyState'
import useLayoutState from '../state/ns/layoutState'
import useSocket from '../util/socket'
import api from '../util/api'
import useWorkspaceHelper from '../helpers/workspace/workspaceHelper'
import useUploadHelper from '../helpers/useUploadHelper'
import locales from '../configs/locales'
import useGuideHelper from '../helpers/useGuideHelper'
import useModalHelper from '../helpers/useModalHelper'

export default {
  setup(_, ctx) {
    useI18nHelper(ctx.root)
    const route = useRoute()
    const router = useRouter()
    const global = useGlobal()
    const context = useContext()
    const popupRef = ref(null)
    const langRef = ref(null)
    const notiRef = ref(null)
    global.setContext(context, route, router, useSigninState())
    const { menu } = navigation
    const layoutState = useLayoutState(popupRef, langRef)
    const workspaceHelper = useWorkspaceHelper()
    const uploadHelper = useUploadHelper()
    const mobileMenu = ref(false)
    const guideHelper = useGuideHelper()
    const i18n = useI18N()
    const ready = ref(false)
    const isNeuralWorksAdmin = ref(false)

    const { modalCount } = useModalHelper()

    const checkSelected = (to) => {
      return (
        to !== '#' &&
        route.value &&
        !!route.value.path.match(new RegExp(`(/en)?${to}`))
      )
    }

    function handleMobileMenu(flag) {
      mobileMenu.value = flag
      if (document) {
        document.scrollingElement.style.overflow = flag ? 'hidden' : 'auto'
      }
    }

    const logout = () => router.push('/auth/logout')

    const { toggleClosed, notifications } = useNotificationState(notiRef)
    let socket = null

    const email = ref('')

    watch(modalCount, (count) => {
      if (count === 1) {
        document.body.style.overflow = 'hidden'
      } else if (count === 0) {
        document.body.style.overflow = 'initial'
      }
    })

    onMounted(() => {
      if (!storage.getToken()) {
        router.push('/auth/signin')
      }
      workspaceHelper.fetchWorkspace()
      api.get('/users/workspace').then(({ status, data }) => {
        if (status === 200) {
          workspaceHelper.setWorkspaceId(data.id)
        } else {
          router.push('/auth/logout')
        }
      })
      api
        .get('/auth/me/')
        .then(({ status, data }) => {
          if (status === 200) {
            email.value = data.email
            ready.value = true
            isNeuralWorksAdmin.value = data.isNeuralWorksAdmin
          } else {
            router.push('/auth/logout')
          }
        })
        .catch((e) => {
          console.warn('error')
        })
      socket = useSocket()
      socket.addEventListener('*', layoutState.notifySocketEvent)
    })
    onUnmounted(() => {
      if (socket) {
        socket.removeEventListener('*', layoutState.notifySocketEvent)
      }
    })

    function moveTo(path) {
      router.push(path)
    }

    function moveAndClose(path) {
      moveTo(path)
      layoutState.toggleLogout()
    }
    return {
      ...layoutState,
      ...uploadHelper,
      ...workspaceHelper,
      ...guideHelper,
      popupRef,
      langRef,
      notiRef,
      i18n,
      menu,
      email,
      mobileMenu,
      locales,
      modalCount,
      checkSelected,
      logout,
      toggleClosed,
      notifications,
      moveTo,
      moveAndClose,
      handleMobileMenu,
      ready,
      isNeuralWorksAdmin,
    }
  },
}
