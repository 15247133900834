//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCurrentInstance, onMounted, ref } from '@nuxtjs/composition-api'
export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#58aac3',
    },
  },
  setup(props, { emit }) {
    const UID = ref('')
    const styleRef = ref(null)
    const filterClick = ({ target, currentTarget }) => {
      const _index = parseInt(
        (currentTarget || target).getAttribute('index'),
        10
      )
      if (props.index !== _index) {
        emit('change', _index)
      }
    }
    onMounted(() => {
      UID.value = getCurrentInstance().uid
      styleRef.value.textContent = `
        .filter-style-${UID.value} > div.selected::before{
          background: ${props.color}99;
        }
      `
    })
    return { props, UID, styleRef, filterClick }
  },
}
