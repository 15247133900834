//
//
//
//

import { onMounted, onUnmounted } from '@vue/composition-api'
export default {
  setup() {
    let jquery, imp
    onMounted(() => {
      jquery = document.createElement('script')
      imp = document.createElement('script')
      jquery.src = 'https://code.jquery.com/jquery-1.12.4.min.js'
      imp.src = 'https://cdn.iamport.kr/js/iamport.payment-1.1.8.js'
      document.head.appendChild(jquery)
      document.head.appendChild(imp)
    })

    onUnmounted(() => {
      document.head.removeChild(jquery)
      document.head.removeChild(imp)
    })

    function onPayment() {
      const IMP = window.IMP
      IMP.init('imp37463121')

      IMP.request_pay(
        {
          // param
          pg: 'html5_inicis',
          merchant_uid: 'ORD20180131-0000012',
          name: '노르웨이 회전 의자',
          amount: 0,
          buyer_email: 'gildong@gmail.com',
          buyer_tel: '010-4927-2258',
        },
        function (rsp) {
          // callback
          if (rsp.success) {
            // 결제 성공 시 로직,
          } else {
            // 결제 실패 시 로직,
          }
        }
      )
    }

    return {
      onPayment,
    }
  },
}
