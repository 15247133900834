import Vue from 'vue'
import dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import NsContainer from '../components/ns/NsContainer.vue'
import NsButton from '../components/ns/NsButton.vue'
import NsInput from '../components/ns/NsInput.vue'
import NsCheckbox from '../components/ns/NsCheckbox.vue'
import NsRadio from '../components/ns/NsRadio.vue'
import NsTag from '../components/ns/NsTag.vue'
import NsTab from '../components/ns/NsTab.vue'
import NsTable from '../components/ns/NsTable.vue'
import NsTableRow from '../components/ns/NsTableRow.vue'
import NsTableCol from '../components/ns/NsTableCol.vue'
import NsSearch from '../components/ns/NsSearch.vue'
import NsFilter from '../components/ns/NsFilter.vue'
import NsPagination from '../components/ns/NsPagination'
import NsToast from '../components/ns/NsToast'
import NsToastBox from '../components/ns/NsToastBox'
import NsTip from '../components/ns/NsTip.vue'
import NsModal from '../components/ns/NsModal.vue'
import NsProgress from '../components/ns/NsProgress.vue'
import NsSelect from '../components/ns/NsSelect.vue'
import NsAccordion from '../components/ns/NsAccordion.vue'
import NsAccordionPanel from '../components/ns/NsAccordionPanel.vue'
import NsIcon from '../components/ns/NsIcon.vue'
import NsAuthFooter from '../components/ns/NsAuthFooter.vue'
import NsAuthHeader from '../components/ns/NsAuthHeader.vue'
import NsDataTable from '../components/ns/NsDataTable.vue'
import NsNotificationBox from '../components/ns/NsNotificationBox.vue'
import NsNotification from '../components/ns/NsNotification.vue'
import NsLoading from '../components/ns/NsLoading.vue'
import NsConfirm from '../components/ns/NsConfirm'
import NsPlot from '../components/ns/NsPlot.vue'
import NsGraph from '../components/ns/NsGraph.vue'
import NsTooltip from '../components/ns/NsTooltip.vue'
import NsTooltipBox from '../components/ns/NsToolTipBox.vue'
import NsImageView from '../components/ns/NsImageView.vue'
import NsPayment from '../components/ns/NsPayment.vue'
import NsImage from '../components/ns/NsImage.vue'
import api from '../util/api'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

Vue.component('NsVirtual', {
  functional: true,
  render(h, context) {
    return context.props.node
  },
})

Vue.component('NsContainer', NsContainer)
Vue.component('NsAuthHeader', NsAuthHeader)
Vue.component('NsAuthFooter', NsAuthFooter)
Vue.component('NsButton', NsButton)
Vue.component('NsInput', NsInput)
Vue.component('NsCheckbox', NsCheckbox)
Vue.component('NsRadio', NsRadio)
Vue.component('NsTag', NsTag)
Vue.component('NsTab', NsTab)
Vue.component('NsTable', NsTable)
Vue.component('NsTableRow', NsTableRow)
Vue.component('NsTableCol', NsTableCol)
Vue.component('NsSearch', NsSearch)
Vue.component('NsFilter', NsFilter)
Vue.component('NsPagination', NsPagination)
Vue.component('NsToast', NsToast)
Vue.component('NsToastBox', NsToastBox)
Vue.component('NsTip', NsTip)
Vue.component('NsModal', NsModal)
Vue.component('NsProgress', NsProgress)
Vue.component('NsSelect', NsSelect)
Vue.component('NsAccordion', NsAccordion)
Vue.component('NsAccordionPanel', NsAccordionPanel)
Vue.component('NsIcon', NsIcon)
Vue.component('NsDataTable', NsDataTable)
Vue.component('NsNotificationBox', NsNotificationBox)
Vue.component('NsNotification', NsNotification)
Vue.component('NsLoading', NsLoading)
Vue.component('NsConfirm', NsConfirm)
Vue.component('NsPlot', NsPlot)
Vue.component('NsGraph', NsGraph)
Vue.component('NsTooltip', NsTooltip)
Vue.component('NsTooltipBox', NsTooltipBox)
Vue.component('NsImageView', NsImageView)
Vue.component('NsPayment', NsPayment)
Vue.component('NsImg', NsImage)

function getServerInfo(req, $config) {
  if (req === undefined) {
    // client side
    return window.location
  } else {
    // server side
    const protocol =
      (req.headers['x-forwarded-proto'] || req.protocol || 'http') + ':'
    const host = req.headers.host || $config.EXTERNAL_BE_NEURAL_STUDIO
    return {
      protocol,
      host,
    }
  }
}

export default ({ req, $config }) => {
  if (!$config.DEDICATED) return
  if (!$config.REPLACE_HOST) return

  const { protocol, host } = getServerInfo(req, $config)
  const external = $config.EXTERNAL_BE_NEURAL_STUDIO.split('/studio')[0]
  const externalPort =
    external.split(':').length > 2 ? ':' + external.split(':')[2] : ''
  const hostName = host.split(':')[0]
  const newHost = protocol + '//' + hostName + externalPort + '/studio/api'

  api.initApi(newHost)
}
