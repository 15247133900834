//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, ref } from '@nuxtjs/composition-api'
import useToastState from '../../state/ns/toastState'
export default {
  props: {
    id: {
      type: Number,
      default: -1,
    },
    color: {
      type: String,
      default: '#58acc3',
    },
    icon: {
      type: String,
      default: 'cloud_upload',
    },
    description: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    action: Boolean,
    callback: {
      type: Function,
      default: () => {},
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  setup(props) {
    const {
      removeToast,
      bindToastElement,
      dispatchEvent,
      pauseToast,
      resumeToast,
    } = useToastState()
    const status = ref('transition-hided')
    const exited = ref(false)

    const isLarge = props.size === 'large' ? 'large' : ''

    // const exitButtonClicked = () => {
    // 함수이름 변경: exitButtonClicked --> closeThisToast
    const closeThisToast = () => {
      if (!exited.value) {
        exited.value = true
        status.value = 'transition-out-active transition-hided' + isLarge
        removeToast(props.id)
      }
    }

    const transitionEnded = ({ target, currentTarget, propertyName }) => {
      if (currentTarget !== target) {
        return
      }
      if (
        !exited.value &&
        (propertyName === 'transform' || propertyName === 'min-height')
      ) {
        switch (status.value) {
          case 'transition-in':
            status.value = 'transition-in transition-in-active ' + isLarge
            break
          case 'transition-in transition-in-active':
            status.value = ' ' + isLarge
            break
          case 'transition-out-active':
            status.value = 'transition-out-active transition-hided ' + isLarge
            break
          case 'transition-out-active transition-hided':
            closeThisToast()
            break
        }
      }
    }

    const showClick = () => {
      props.callback(props.id)
      dispatchEvent(props.id)
    }

    onMounted(() => {
      bindToastElement(props.id, { closeThisToast })
      setTimeout(() => (status.value = 'transition-in'), 0)
      status.value = isLarge
    })

    return {
      props,
      status,
      exited,
      closeThisToast,
      transitionEnded,
      showClick,
      pauseToast,
      resumeToast,
    }
  },
}
