//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api'
export default {
  props: {
    outlined: Boolean,
    rounded: Boolean,
    sharp: Boolean,
    twoTone: Boolean,
    small: Boolean,
    size: {
      type: Number,
      default: -1,
    },
    color: {
      type: String,
      default: '#585858',
    },
  },
  setup(props) {
    return {
      style: computed(() => {
        if (props.outlined) return '-outlined'
        if (props.rounded) return '-round'
        if (props.sharp) return '-sharp'
        if (props.twoTone) return '-two-tone'
        return ''
      }),

      inlineStyle: computed(() => {
        const style = {
          color: props.color,
        }
        if (props.size !== -1) {
          style.fontSize = `${props.size}px`
        }
        return style
      }),

      isSmall: computed(() => {
        if (props.small) return 'ns-icon-small'
        return ''
      }),
    }
  },
}
