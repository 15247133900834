//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, toRefs } from '@nuxtjs/composition-api'
export default {
  props: {
    max: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 12,
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const { index: _index, max: _max, count: _count } = toRefs(props)
    const leftChevron = ref(false)
    const rightChevron = ref(false)

    const pageList = computed(() => {
      const list = []
      const isExceeded = _max.value > props.count
      const count = Math.min(_max.value, props.count)
      const index = _index.value
      const half = count / 2
      let start = 1
      leftChevron.value = _max.value === 0 ? false : index > count
      rightChevron.value = _max.value - count > index
      if (index < half) {
        start = 1
      } else if (index > _max.value - half) {
        start = _max.value - count + 1
        rightChevron.value = false
      } else {
        start = index - half + 1
      }

      for (let i = start; i < start + count; i++) {
        list.push(i)
      }

      if (isExceeded) {
        if (index > half) {
          list[0] = 1
          list[1] = '-'
        }
        if (index < _max.value - half) {
          list[count - 1] = _max.value
          list[count - 2] = '-'
        }
      }
      return list
    })

    const moveIndex = (toNext = true) => {
      let moveTo = _index.value + props.count * (toNext ? 1 : -1)
      if (moveTo < 1) moveTo = 1
      if (moveTo > _max.value) moveTo = _max.value
      emit('change', moveTo)
    }

    const propChange = (e) => {
      const index = parseInt(e.currentTarget.getAttribute('index'), 10) || 1
      if (_index.value !== index) {
        emit('change', index)
      }
    }

    return {
      props,
      pageList,
      propChange,
      moveIndex,
      leftChevron,
      rightChevron,
      _index,
      _max,
      _count,
    }
  },
}
