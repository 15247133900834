//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@vue/composition-api'
export default {
  props: {
    size: {
      type: [Number, String],
      default: 50,
    },
    white: Boolean,
  },
  setup(props) {
    const width = computed(() => {
      let size = props.size
      if (typeof size !== 'number') {
        size = parseInt(size)
        if (isNaN(size)) {
          size = 50
        }
      }
      return size
    })
    const stroke = computed(() => {
      return parseInt(width.value * 0.1)
    })
    return {
      stroke,
      width,
    }
  },
}
