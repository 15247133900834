//
//
//
//
//
//

import { onMounted } from '@vue/composition-api'
import useToastState from '../../state/ns/toastState'
export default {
  setup() {
    const toastState = useToastState()
    onMounted(() => {
      window.toast = toastState
    })
    return {
      ...toastState,
    }
  },
}
