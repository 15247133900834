import { reactive, toRefs } from '@nuxtjs/composition-api'
import useI18N from '@/translations/i18n'
import api from '../util/api'
import useToastState from '../state/ns/toastState'
const $t = useI18N()
const state = reactive({
  uploadCount: 0,
})

const toastState = useToastState()

function pageUnloadHandler(event) {
  event.preventDefault()
  event.returnValue = ''
  return $t('mesg.helper.upload_exit_if_pageout')
}

function upload(url, data) {
  window.addEventListener('beforeunload', pageUnloadHandler)
  const fileCount = [...data].length
  toastState.addToast({
    message: `${fileCount}${$t('mesg.helper.some_file_upload_start')}`,
  })
  state.uploadCount++
  api
    .post(url, data)
    .then((response) => {
      toastState.addToast({
        message: `${fileCount}${$t('mesg.helper.some_file_upload_done')}`,
      })
      state.uploadCount--
    })
    .catch((error) => {
      toastState.addToast({
        message: `${fileCount}${$t('mesg.helper.some_file_upload_fail')}`,
        description: error.message,
        status: 'error',
      })
      state.uploadCount--
    })
    .finally(() => {
      window.removeEventListener('beforeunload', pageUnloadHandler)
    })
}

export default function useUploadHelper() {
  return {
    ...toRefs(state),
    upload,
  }
}
