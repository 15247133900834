import { render, staticRenderFns } from "./NsToast.vue?vue&type=template&id=4bc5fe57&scoped=true&"
import script from "./NsToast.vue?vue&type=script&lang=js&"
export * from "./NsToast.vue?vue&type=script&lang=js&"
import style0 from "./NsToast.vue?vue&type=style&index=0&id=4bc5fe57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc5fe57",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NsIcon: require('/usr/app/src/components/ns/NsIcon.vue').default})
