import configs from '@/configs'
import translations from '@/translations'

const DEFAULT_LANG = 'ko'

const i18n = (name, defaultValue) => {
  const keys = name.split('.')

  const lang = configs.locales.locale

  const translation = translations[lang]
  const translationDef = translations[DEFAULT_LANG]
  if (keys.length > 0) {
    let translate = translation
    let translateDef = translationDef
    for (const key of keys) {
      if (translate) {
        translate = translate[key]
      }
      if (translateDef) {
        translateDef = translateDef[key]
      }
    }
    return translate || defaultValue || translateDef || '?'
  }
  return defaultValue || '?'
}
export default function useI18N() {
  return i18n
}
