const __rest =
  (this && this.__rest) ||
  function (s, e) {
    const t = {}
    for (const p in s)
      if (Object.prototype.hasOwnProperty.call(s, p) && !e.includes(p))
        t[p] = s[p]
    if (s != null && typeof Object.getOwnPropertySymbols === 'function')
      for (let i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
        if (
          !e.includes(p[i]) &&
          Object.prototype.propertyIsEnumerable.call(s, p[i])
        )
          t[p[i]] = s[p[i]]
      }
    return t
  }
if (!Object.is) {
  // eslint-disable-next-line
  Object.defineProperty(Object, "is", { value: (x, y) => (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y)
  })
}
export const optionsUpdateState = (_lhs, _rhs) => {
  const { width: lhsWidth, height: lhsHeight } = _lhs
  const lhs = __rest(_lhs, ['width', 'height'])
  const { width: rhsWidth, height: rhsHeight } = _rhs
  const rhs = __rest(_rhs, ['width', 'height'])
  let state = 'keep'
  if (lhsHeight !== rhsHeight || lhsWidth !== rhsWidth) {
    state = 'update'
  }
  if (Object.keys(lhs).length !== Object.keys(rhs).length) {
    return 'create'
  }
  for (const k of Object.keys(lhs)) {
    if (!Object.is(lhs[k], rhs[k])) {
      state = 'create'
      break
    }
  }
  return state
}
export const dataMatch = (lhs, rhs) => {
  if (lhs.length !== rhs.length) {
    return false
  }
  return lhs.every((lhsOneSeries, seriesIdx) => {
    const rhsOneSeries = rhs[seriesIdx]
    if (lhsOneSeries.length !== rhsOneSeries.length) {
      return false
    }
    return lhsOneSeries.every(
      (value, valueIdx) => value === rhsOneSeries[valueIdx]
    )
  })
}
