//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    selected: Boolean,
    disabled: Boolean,
    expandable: Boolean,
    expand: Boolean,
    sub: Boolean,
  },
  setup(props, { emit }) {
    const nsClick = (e) => emit('click', e, props.expandable)
    return { props, nsClick }
  },
}
