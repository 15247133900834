import api from '../util/api'
import useGlobal from '../util/global'
import storage from '../util/storage'
export default (context) => {
  const global = useGlobal()
  global.setEnv(context.$config)

  // api.get('/auth/me').then(async (r) => {
  //   if (r.status === 410) {
  //     const response = await api.post('/users/workspace/default')
  //     if (response === 200) {
  //       storage.setToken(response.data.accessToken)
  //     }
  //   }
  // })

  const workspaceCheckTargets = {
    datasets: ['datasets', 'rawdatasets'],
    job: ['jobs'],
    deploy: ['inferences'],
  }
  const { route } = context
  const path = route.path
  const target = Object.keys(workspaceCheckTargets).find((name) =>
    path.includes(`/${name}/`)
  )
  if (target) {
    const { id: rawId } = route.params
    if (rawId) {
      const id = rawId.split('-')[0]
      // 워크스페이스가 동일한지 체크
      const types = workspaceCheckTargets[target]
      Promise.all(
        types.map((type) => api.post('/workspace/check', { id, type }))
      )
        .then(async (results) => {
          const successResult = results.find((result) => result.status < 205)
          if (successResult) {
            try {
              const { status, data: workspace } = await api.get(
                '/users/workspace'
              )
              if (status === 200) {
                const { workspaceId } = successResult.data
                if (workspaceId !== workspace.id) {
                  try {
                    const {
                      status: changeStatus,
                      data: workspaceToken,
                    } = await api.put(`/users/workspace/${workspaceId}`)
                    if (changeStatus === 200) {
                      api.cancelRequests()
                      storage.setToken(workspaceToken.accessToken)
                      setTimeout(() => {
                        location.href = location.href + ''
                      }, 0)
                    }
                  } catch (e) {
                    console.error(e)
                    context.redirect(307, '/instruction')
                  }
                }
              } else {
                throw new Error('cannot fetch workspace')
              }
            } catch (e) {}
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }
}
