import { render, staticRenderFns } from "./NsNotification.vue?vue&type=template&id=6395db08&scoped=true&"
import script from "./NsNotification.vue?vue&type=script&lang=js&"
export * from "./NsNotification.vue?vue&type=script&lang=js&"
import style0 from "./NsNotification.vue?vue&type=style&index=0&id=6395db08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6395db08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NsIcon: require('/usr/app/src/components/ns/NsIcon.vue').default})
