//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, watch } from '@nuxtjs/composition-api'
import uuid from 'short-uuid'
export default {
  props: {
    length: {
      type: Number,
      default: 50,
    },
    label: {
      type: String,
      default: '레이블',
    },
    placeholder: {
      type: [String, Number, Object],
      default: '',
    },
    tip: {
      type: String,
      default: '도움말',
    },
    inputName: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    error: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    required: Boolean,
    area: Boolean,
    help: Boolean,
    hideLabel: Boolean,
    hideTip: Boolean,
    hideCount: Boolean,
    hideBottom: Boolean,
    small: Boolean,
    autofill: {
      type: String,
      default: 'on',
    },
  },
  setup(props, { emit }) {
    const text = ref(props.value)
    const focus = ref(false)
    const changeText = ({ target: { value } }) =>
      emit('input', (text.value = value))
    const inputId = ref(uuid().generate())

    const iconClick = ({ currentTarget, target }) =>
      emit('iconClick', (currentTarget || target).textContent)

    const changeFocus = ({ type }) => (focus.value = type === 'focus')

    watch(
      () => props.value,
      (value) => (text.value = value)
    )

    return {
      props,
      text,
      changeText,
      focus,
      changeFocus,
      iconClick,
      inputId,
    }
  },
}
