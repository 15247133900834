//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api'
export default {
  props: {
    disabled: Boolean,
    checked: Boolean,
    name: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const isChecked = ref(props.checked)
    const changeCheck = () => {
      let index = -1
      if (window && document) {
        const inputs = document.querySelectorAll(`input[name=${props.name}]`)
        inputs.forEach((input, _index) => input.checked && (index = _index))
      }
      emit('change', index)
    }

    return {
      props,
      isChecked,
      changeCheck,
    }
  },
}
