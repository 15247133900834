//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api'
export default {
  setup(_, { emit, slots }) {
    const tipRef = ref(null)
    const closeClick = () => {
      if (tipRef.value) {
        tipRef.value.style = 'animation-name: fadeOut;'
        setTimeout(() => {
          emit('close', slots.default())
        }, 250)
      }
    }
    return {
      tipRef,
      closeClick,
    }
  },
}
