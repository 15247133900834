import translations from '../translations'

const defaultLocale = 'ko'

export default {
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'i18n_redirected',
    redirectOn: 'root', // recommended
  },
  locale: defaultLocale,
  locales: [
    {
      code: 'ko',
      flag: 'kr',
      name: '한국어',
      file: 'ko.js',
    },
    {
      code: 'en',
      flag: 'us',
      name: 'English',
      file: 'en.js',
    },
    {
      code: 'ja',
      flag: 'jp',
      name: '日本語',
      file: 'ja.js',
    },
  ],
  lazy: true,
  langDir: 'translations/',
  defaultLocale,
  vueI18n: {
    fallbackLocale: defaultLocale,
    message: translations,
  },
}
