export default {
  test: '테스트',
  nest: {
    test: '중첩 테스트',
  },

  SYSVARS: {
    inquiryEmail: 'manager@neuralworks.io',
    productName: 'NeuralWorks',
    studioName: 'Neural Studio',
  },

  image: {
    logo_login: '/studio/logo/logo_login.svg',
    logo_image: '/studio/logo/NeuralWorks_logo.png',
    logo_text: '/studio/logo/NeuralStudio.svg',
    logo_annotation: '/studio/logo/annotation_logo.jpg',
  },

  menu: {
    training: '학습 하기',
    result: '학습 관리',
    deployment: '추론 하기',

    dataset: '데이터 관리',
    handling: '데이터 다루기',
    model: '모델 보기',

    EDA: 'EDA',

    comparison: '비교 하기',
    annotation: '어노테이션',
    GPU: '컴퓨팅 자원',
  },

  common: {
    email: '이메일',
    password: '비밀번호',
    login: '로그인',
    signup: '회원가입',
    logout: '로그아웃',
    register: '회원가입',
    search: '검색',
    referral: '추천인',
    search_placeholder: '검색어를 입력하세요',
    search_result: '검색 결과',
    search_result_none: '검색 결과가 없습니다',
    cancel: '취소',
  },

  page: {
    dataset_list: '데이터셋 목록',
    dataset_detail: '데이터셋 상세',
    raw_data: '원천 데이터',
    training_data: '학습 데이터',
    EDA: '탐색적 데이터 분석 (EDA)',

    model_list: '모델 목록',
    model_detail: '모델 상세',
    model_category: '모델 유형',
    model_name: '모델 이름',
    model_type: '모델 유형',
    library: '라이브러리',
    detail: '상세 정보',
    selection: '선택',
    image: '이미지',
    image_classification: '이미지 분류',
    object_detection: '객체 감지',
    object_segmentation: '객체 분할',
    semantic_segmentation: '시맨틱 분할',
    training_list: '학습 목록',
    data_analisys: '데이터 분석',
    training_detail: '학습 상세',
    regression: '회귀 분석',
    classification: '분류',
    clustering: '클러스터링',
    timeseries_analysis: '시계열 분석',
    anomaly_detection: '이상 탐지',
    result: '결과',
    waiting: '대기 중',
    on_training: '학습 중',
    done: '완료',
    recent: '최근',
    training_name: '학습 이름',
    status: '상태',
    training_time: '학습 시간',
    compare_results_of_training: '학습 결과 비교',
    model_deployment: '모델 배포',
    annotation_list: '어노테이션 목록',
  },

  mesg: {
    // 각 페이지별로
    annotation_list: {
      title_description1: '총 ',
      title_description2:
        '개의 어노테이션이 있습니다. 어노테이션 이름을 눌러 상세 정보를 확인해 보세요.',
      ing: '진행중',
      finished: '완료',
      new_anno: '신규 어노테이션',
      search_msg: '작업 이름, 설명을 입력하세요',
      no_name: '이름이 없습니다.',
      no_data: '데이터셋이 없습니다.',
      no_search: '검색 내용을 찾을 수 없습니다.',
      no_job: '작업이 없습니다.',
      add_anno: '어노테이션 생성',
      select_data: '데이터 선택',
      detail_option: '세부 설정',
      label_set: '라벨 지정',
      no_image_data: '이미지 데이터셋이 없습니다.',
      name: '이름',
      anno_placeholder: '어노테이션 이름을 입력하세요.',
      explanation: '설명',
      explanation_placeholder: '어노테이션 설명을 입력하세요.',
      label_list: '학습된 라벨 목록',
      label_name: '레이블 이름',
      label_placeholder: 'ex) 개, 고양이',
      label_tip: '어노테이션 라벨 이름을 입력해주세요.',
      add: '추가',
      color_short_key: '색/단축키',
      label_name2: '라벨 이름',
      anno_new_mesg: '어노테이션을 생성하였습니다.',
      anno_new_fail_mesg: '어노테이션을 생성하지 못 했습니다.',
      loading: '불러오는 중',
      anno_desc:
        '데이터셋을 확인하고 어노테이션 버튼을 클릭해 어노테이션을 시작하세요.',
      label: '라벨',
      label_add: '라벨 추가',
      worker_manage: '작업자 관리',
      kind: '종류',
      general_worker: '일반 작업자',
      number: '수',
      nummber_unit: '명',
      work_num_placeholder: '작업 번호를 입력하세요.',
      work_num: '작업 번호',
      worker: '작업자',
      prover: '검수자',
      work_ratio: '작업 진행률',
      modify_time: '최근 수정일',
      annotation: '어노테이션',
      no_work: '작업이 없습니다.',
      auto_anno: 'Auto 어노테이션',
      input_name: '이름 입력',
      confirm: '확인',
      kind_anno: '어노테이션 유형',
      work_load_unit: '어노테이션 작업 할당 단위',
      num_image: '이미지 수',
      auto_anno_model: 'Auto 어노테이션 모델',
      auto_anno_check: '오토 어노테이션 사용',
      model_list: '모델 목록',
      input_label: '라벨 입력',
      new_label: '신규 레이블',
      user_set: '사용자 지정',
      original_label: '기존 레이블',
      auto_anno_name: '오토 어노테이션',
      dataset: '데이터셋',
      size: '용량',
      learning_model: '학습 모델',
      learning_data: '학습 데이터',
      number2: '개수',
      category: '카테고리',
      bounding_box: '바운딩 박스',
      polygon: '폴리곤',
      label_error_msg: '이미 존재하는 라벨입니다.',
      number3: '개',
      data_ing: '"데이터셋" 데이터셋을 어노테이션 중입니다.',
      store: '저장',
      submit: '제출하기',
    },

    signin: {
      title: '로그인',
      page_guide: '환영합니다 !',
      service_guide: '뉴럴웍스 스튜디오 베타 서비스입니다.',
      new_service_guide: '노코드 AI 뉴럴스튜디오 !',
      login_guide_kakao: '개인용(카카오) 로그인과',
      login_guide_org: '기업용/학교용 로그인 중에 선택하세요.',
      start_button: '시작하기',
      request_to_use: '(문의 manager@neuralworks.io)',
      wrong_email: '잘못된 이메일 주소입니다.',
      wrong_password: '잘못된 비밀번호입니다.',
      send_code: '인증번호 받기',
      enter_code_sent: '인증번호를 입력하세요',
      verify_code: '인증번호 확인',
      forgot_password: '비밀번호 찾기',
      reset_password: '비밀번호 재설정',
      reset_password_success: '비밀번호 재설정 완료',
      reset_password_fail: '비밀번호 재설정 실패',
      reset_password_email_sent: '비밀번호 재설정 이메일이 발송되었습니다',
      forgot_password2: '비밀번호를 잊으셨나요?',
      authcheck_finished: '인증 완료',
      recv_code: '인증 코드 받기',
      code: '인증코드',
      ask_code: '이메일로 전송된 코드를 입력해주세요.',
      auth_check: '인증하기',
      pw_info: '영문, 숫자 조합',
      pw_info2: '6자리 이상',
      agree_info: '개인정보 수집 및 이용, 뉴럴웍스 이용약관에 동의합니다.',
      view_agree_info: '약관보기',
      start_info: '뉴럴웍스 스튜디오 시작하기',
      new_password: '새로운 비밀번호',
      change_pw: '비밀번호 변경하기',
      login_fail: '로그인에 실패하였습니다.',
      login_session_expire: '로그인 세션이 만료되었습니다.',
      signup_success: '회원가입에 성공하였습니다.',
      signup_failed: '회원가입에 실패하였습니다.',
      change_pw_success: '비밀번호 변경에 성공하였습니다.',
      change_pw_failed: '비밀번호 변경에 실패하였습니다.',
      invalid_referral_code: '유효하지 않은 추천인 코드입니다.',
      kakao: '본 계정은 카카오로 로그인해주세요.',
      kakao_login_success: '카카오로 로그인하였습니다.',
      kakao_login_failed: '카카오 로그인에 실패하였습니다.',
    },

    dataset_list: {
      page_guide: '데이터셋을 업로드하고 가공하여 머신러닝 작업을 준비하세요.',
    },

    dataset_detail: {
      page_guide: '데이터셋의 정보를 조회합니다.',
    },

    datasets: {
      title: '데이터셋 관리',
      page_info: '기존 데이터 보기 및 새 데이터 올리기를 할 수 있습니다.',
      upload_data: '데이터셋 업로드 하기',
      upload_from_server: '서버에서 가져오기',
      search_placeholder: '데이터셋 이름으로 검색',
      view_only_mydata: '내 데이터셋만 보기',
      no_name: '이름이 없습니다.',
      no_info: '정보 없음',
      do_learning: '학습하기',
      origin: '출처',
      image_classification: '이미지 분류',
      detection: '객체 검출',
      segmentation: '객체 분할',
      image: '이미지',
      text: '텍스트',
      general: '일반',
      data_analysis: '데이터 분석',
      text_analysis: '텍스트 분석',
      wordcloud: '워드클라우드',
      tagger: 'Tagger',
      word2vec: 'Word2Vec',
      title2: '파일스토어',
      raw_data: '원천 데이터',
      data_summary: '요약',
      dataset: '데이터셋',
      folder: '폴더',
      file: '파일',
      unit: '개',
      is_loading: '로딩중 ...',
      no_file_loading: '파일을 불러올 수 없습니다.',
      is_loading2: '불러오는 중',
      no_data_name: '데이터셋 이름이 없습니다.',
      not_find_data: '데이터셋을 찾을 수 없습니다.',
      not_find_dataset_files: '데이터셋 파일을 찾지 못했습니다.',
      page_info2: '데이터의 요약 정보를 확인해 보세요.',
      view_file: '파일보기',
      detailed_analysis: '상세 분석',
      download: '다운로드',
      download_failed: '다운로드에 실패하였습니다.',
      convert: '변환하기',
      annotation: '어노테이션',
      view_preprocess: '시각화/전처리하기',
      data: '데이터',
      no_data: '데이터가 없어요.',
      basic_stat: '기본통계',
      relation_var: '상관계수',
      remove_data: '데이터 삭제',
      modify_data: '데이터셋 정보 수정',
      dataset_name: '데이터셋 이름',
      explain: '설명',
      server_path: '서버 경로',
      server_path_input: '경로를 입력하세요.',
      explain_title: '이름을 입력하세요.',
      explain_placeholder:
        '이 데이터셋을 설명하는 간략한 메모를 남겨보세요. 입력하지 않아도 내보낼 수 있어요.',
      change: '바꾸기',
      preprocess_info: '전처리 내역',
      no_preprocess_info: '전처리 내역이 없습니다.',
      no_data_name2: '데이터 이름이 없습니다.',
      page_info3: '데이터를 전처리하여 학습 데이터로 만드세요.',
      send_data: '학습데이터로 내보내기',
      pre_process: '전처리',
      summary: '요약',
      add: '추가',
      cancel: '취소',
      modify: '수정',
      delete: '삭제',
      done: '완료',
      add_btn_info: '추가 버튼을 클릭하세요.',
      select_preprocess: '전처리를 선택하세요.',
      select_var: '1. 변수 선택',
      select_all: '전체 선택',
      unselect_all: '전체 선택 해제',
      select_process_type: '전처리 종류 선택',
      filter_condition_set: '필터 조건 설정',
      select_method: '이상치 판단 방법 선택',
      select_result: '3. 이상치 처리 방안 선택',
      condition: '조건식',
      condition_info: '조건식이 없어요. 아래에서 조건을 설정하세요.',
      select_left_list: '왼쪽 목록에서 변수를 선택하세요.',
      operator_placeholder: '연산자',
      value_placeholder: '값',
      pre_value: '기존 값',
      next_value: '대체 값',
      no_input_placeholder: '입력하지 않으면 결측치로 대체',
      missing_value: '결측치',
      preview: '미리 보기',
      no_relation_var: '상관 계수 데이터가 없습니다.',
      select_preprocess2: '전처리 목록을 선택하세요.',
      set_data_name: '학습 데이터의 이름을 설정해 주세요.',
      send: '내보내기',
      category_var_convert: '범주형 변수 변환',
      scaling: '스케일링',
      missing_value_process: '결측치 처리',
      missing_value_delete: '결측치 제거(행 삭제)',
      missing_value_set: '결측치 값 지정',
      set_value: '값 지정',
      mean: '평균 (mean)',
      median: '중앙값 (median)',
      mode: '최빈값 (mode)',
      minimum: '최솟값 (minimum)',
      maximum: '최댓값 (maximum)',
      direct_input: '직접입력',
      outlier: '이상치',
      outlier_handling: '이상치 처리',
      outlier_add: '이상치 여부 표시 (새로운 열 추가)',
      outlier_remove: '이상치 제거 (데이터 행 삭제)',
      filtering: '필터링',
      var_change: '값 대체',
      unit_var_input: '단일 값 입력',
      range_var_input: '범위 입력',
      no_basic_stat: '기본 통계가 없습니다.',
      ask_info: '이 작업을 저장하지 않고 나가시겠어요?',
      exit_info: '나가기',
      continue_modify: '계속 수정',
      yes_info: '네, 안 할래요',
      ask_info2: '(을)를 수정하지 않고 나가시겠어요?',
      del_info:
        '이 작업을 삭제하면\n이후에 했던 작업까지 모두 삭제되고, 되돌릴 수 없어요.\n삭제하시겠어요?',
      del_yes_info: '네, 삭제할게요',
      modify_info:
        '이 작업 을 수정하면\n이후에 한 작업이 모두 삭제되고, 되돌릴 수 없어요.\n수정하시겠어요?',
      modify_yes_info: '네, 수정할게요',
      select_op_value: '연산자 또는 값을 선택하세요',
      proc_no_rows: '전처리 결과 데이터 행이 존재하지 않습니다.',
      proc_only_for_str: '이 전처리는 문자열 타입에만 적용하는 것이 좋습니다',
      proc_only_for_number:
        '이 전처리는 숫자형 타입에만 적용하는 것이 좋습니다',
      add_value_replace: '값 대체를 추가하세요.',
      select_var_info: '변수를 선택하세요.',
      upload_success: '전처리 요청에 성공하였습니다.',
      upload_fail: '업로드에 실패하였습니다.',
      before_process: '처리 전',
      after_process: '처리 후',
      row: '행',
      column: '열',
      convert_result: '변환 결과',
      outlier_number: '이상치 개수',
      pre_min: '기존 최솟값',
      pre_max: '기존 최댓값',
      new_min: '새 최솟값',
      new_max: '새 최댓값',
      mean2: '평균',
      std: '표준편차',
      preview_fail: '미리 보기에 실패 하였습니다.',
      yes: '네',
      no: '아니요',
      select_add_btn: '추가 버튼을 클릭하세요.',
      select_pre_type: '전처리 유형을 선택하세요.',
      add2: '단계 추가',
      do_preprocess: '전처리하기',
      quartile1: '1분위(25%)',
      quartile3: '3분위(75%)',
      remove_msg_1: '이', // this의 의미
      remove_msg_2: '데이터를 삭제하면 복구할 수 없어요.\n삭제하시겠어요?',
      remove_msg_3: '데이터는 삭제되지 않아요.',
    },

    EDA: {
      page_guide:
        '기본 통계와 그래프를 확인하고 데이터에 대한 인사이트를 얻으세요.',
      title: '탐색적 데이터 분석',
      page_info: '변수를 선택하면 기본 통계와 그래프를 확인할 수 있어요.',
      data: '데이터셋',
      data_placeholder: '데이터셋을 선택하세요.',
      x_var: 'x축 변수',
      y_var: 'y축 변수',
      y_value: 'y축 값',
      legend_vars: '색상 변수',
      // ask_info: '데이터와 변수를 선택하세요.',
      m1: '빈도',
      m2: '총합',
      m3: '평균',
      m4: '분포',
      m5: '막대',
      m6: '선',
      m7: '점',
      m8: '파이',
      m9: '박스',
      m10: '막대 그래프',
      msg1: '데이터셋을 찾을 수 없습니다.',
      raw: '원천',
      learn: '학습',
    },

    model_list: {
      page_guide:
        '뉴럴스튜디오에서 제공하는 AI모델(딥러닝, 머신러닝)을 확인할 수 있습니다.',
      title: '모델 보기',
      page_info:
        '뉴럴스튜디오에서 제공하는 AI모델(딥러닝, 머신러닝)을 확인할 수 있습니다.',
      model_type: '모델 종류',
      model_small_type: '소분류',
      add_model: '모델 추가하기',
      search_placeholder: '모델 이름, 설명을 입력하세요.',
      do_learning: '학습하기',
      no_search: '검색 내용이 없습니다.',
      prepare_model: '모델 준비 중입니다.',
      is_loading: '모델을 불러오는 중',
      model_type2: '모델 유형',
      publish_time: '발표 날짜',
      bench_mark: '벤치마크 성능',
      analysis_method: '분석 기법',
      keyword: '키워드',
      more_view: '더 알아보기',
      public_document: '공식문서',
      wiki: '위키백과',
      no_model: '모델 설명이 없습니다.',
      paper: '논문',
      delete_model: '모델 삭제',
      no_paper: '논문이 없습니다.',
      no_model_info: '모델을 찾을 수 없습니다.',
    },
    model_detail: {
      page_guide: '',
    },
    model_category: {
      page_guide: '',
    },

    profile: {
      title: '마이페이지',
      page_info: '개인 프로필을 수정할 수 있습니다.',
      change_picture: '사진 변경',
      email: '이메일',
      name: '이름',
      password: '비밀번호',
      change_name: '이름 변경',
      change_password: '비밀번호 변경',
      enter_password: '비밀번호 입력',
      reenter_password: '비밀번호 재입력',
      change_picture_ok: '프로필 이미지를 변경하였습니다.',
      change_picture_fail: '프로필 이미지 변경에 실패하였습니다',
      enter_name: '이름 입력',
      profile: '프로필',
      pricing_plan: '멤버십',
      my_pricing_plan: '나의 멤버십',
      pricing: '멤버십',
      payment_history: '결제 내역',
      payment_request: '결제 요청',
      payment_fail: '결제 실패',
      payment_complete: '결제 완료',
      payment_ready: '결제 준비',
      current_point: '현재 포인트',
      point_history_created_at: '날짜',
      point_need: '포인트가 충분하지 않습니다.',
      point_info: '포인트 정보',
      point_history: '포인트 내역',
      point: '포인트',
      point_desc: '포인트 설명',
      no_point_history: '포인트 내역이 없습니다.',
      refund_request: '환불 요청',
      refund: '환불',
      service_usage_information: '서비스 이용정보',
      recommendation_code: '추천인 코드',
      recommendation_info: '추천인 정보',
      recommendation_url: '가입 주소',
      recommendation_result: '추천 실적',
      usage_period: '이용 기간',
      next_payment: '다음 결제',
      unlimited: '무제한',
      free: '무료',
      none: '없음',
      general_payment: '일반 결제',
      regular_payment: '정기결제',
      month: '개월',
      not_found_user_information: '유저 정보를 불러올 수 없습니다.',
      change: '변경하기',
      no_payment_history: '결제 기록이 없습니다.',
      plan: '플랜',
      payment_type: '결제 타입',
      period: '기간',
      state: '상태',
      created_date: '생성일',
      won: '원',
    },

    handling: {
      title: '데이터 다루기',
      page_info: '데이터 시각화, 데이터 전처리를 할 수 있어요',
      table_data: '테이블 데이터',
      eda: '탐색적 데이터 분석 (EDA)',
      eda_info:
        '데이터를 분석하기 전 그래프나 통계적인 수치를 통해 직관적으로 바라보는 과정입니다.',
      data_preprocess: '데이터 전처리',
      data_preprocess_info:
        '분석에 적합한 형태로 데이터를 가공하는 작업입니다.',
      data_concat: '데이터 병합',
      data_concat_info: '다른 데이터셋과 병합할 수 있습니다.',
      title2: '데이터 병합',
      page_info2: '여러 데이터를 병합할 수 있습니다.',
      data_concat2: '데이터 결합',
      vertical: '세로',
      horizontal: '가로',
      original_view: '원본보기',
      preview: '미리보기',
      data_store: '데이터 저장',
      data_row: '데이터 (행)',
      var_column: '변수 (열)',
      table_close: '테이블 접기',
      table_open: '펴기',
      no_image_data: '이미지 데이터셋이 없습니다.',
      data_concat_ing: '데이터를 병합하고 있습니다.',
      data_concat3: '데이터 병합하기',
      dataset_name: '데이터셋 이름',
      dataset_name_placeholder: '데이터셋 이름을 입력하세요.',
      explain: '설명',
      explain_placeholder:
        '이 데이터셋을 설명하는 간략한 메모를 남겨보세요. 입력하지 않으셔도 업로드 할 수 있어요.',
      upload: '업로드하기',
      nan_value: '결측',
      select_dataset: '전처리할 데이터셋 선택',
    },

    workspace: {
      title: '워크스페이스',
      page_info: '워크스페이스를 관리하고 수정 할 수 있어요.',
      title2: '워크스페이스 관리',
      add_workspace: '워크스페이스 생성',
      member_number: '참여인원 : 20명',
      manage: '관리',
      create_info: '워크스페이스를 생성하고 있습니다.',
      workspace_modal_title: '워크스페이스 이름과 설명을 입력해주세요',
      cover_set: '커버 설정',
      name: '이름',
      name_placeholder: '워크스페이스의 이름을 입력하세요.',
      explain: '설명',
      explain_placeholder: '워크스페이스의 설명을 입력하세요.',
      create: '생성하기',
      update: '수정하기',
      create_info2: '워크스페이스를 생성하였습니다.',
      create_fail: '워크스페이스 생성에 실패하였습니다.',
      update_info2: '워크스페이스를 수정하였습니다.',
      update_fail: '워크스페이스 수정에 실패하였습니다.',
      page_info2: '워크스페이스의 기본 정보와 팀원을 관리할 수 있습니다.',
      cover_change: '커버 변경',
      delete: '삭제',
      raw_data: '원천 데이터',
      dataset: '학습 데이터',
      annotation: '어노테이션',
      learn: '학습',
      zero: '0개',
      member_manage: '팀원 관리',
      member_invite: '팀원 초대',
      total_member: '전체 인원',
      manager: '관리자',
      normal: '일반',
      waiting: '대기중',
      unit: '명',
      unit2: '개',
      confirm_info: '명의 인원을 삭제하시겠습니까?',
      yes: '네, 삭제할게요',
      enter_email: '워크스페이스로 초대할 유저의 이메일을 입력하세요.',
      enter_email_small: '가입자가 아닌 경우에는 계정이 자동으로 생성됩니다.',
      enter_info: '완료 버튼을 누르면 가입 이메일을 보내드립니다.',
      email: '이메일',
      invite: '초대',
      add: '추가',
      remove: '제거',
      done: '완료',
      normal_user: '일반유저',
      owner: '소유자',
      old: '기존',
      new: '신규',
      email_domain: '이메일 도메인',
      default_password: '기본 비밀번호',
      enter_description: '설명을 입력하세요',
      enter_email_domain: '이메일 도메인을 입력하세요',
      enter_password: '비밀번호를 입력하세요',
      password: '비밀번호',
      email_domain_tip: '이메일 도메인은 , 로 구분합니다.',
      password_condition: '영문/숫자 조합으로 6자 이상 입력하세요.',
      change: '변경하기',
      delete_workspace: '워크스페이스를 삭제하시겠습니까?',
    },

    invite: {
      msg1: '초대받은 사용자가 아닙니다.',
      to_main: '메인으로',
      member_size: '참여인원',
      member_unit: '명',
      apply: '참여',
      no_workspace: '워크스페이스를 찾을 수 없습니다.',
      not_valid_token: '유효한 토큰이 아닙니다.',
      workspace_error1: '이미 참여중인 워크스페이스 입니다.',
      workspace_error2: '워크스페이스를 찾을 수 없습니다.',
      apply_workspace: '워크스페이스에 참여하였습니다.',
      not_apply_workspace: '워크스페이스에 참여하지 못했습니다.',
    },

    instruction: {
      title: 'AI모델로 데이터를 학습/분석 하기',
      page_info: '먼저 학습/분석의 종류를 선택하고, 데이터와 모델만 선택하세요',
      image: '이미지 분석',
      image_classification: '이미지 분류',
      image_classification_info: '이미지를 카테고리별로 분류합니다.',
      detection: '객체 탐지',
      detection_info: '이미지 속 객체를 탐지하고 분류합니다.',
      segmentation: '객체 분할',
      segmentation_info: '이미지 속 객체를 픽셀 단위로 분할합니다.',
      data_analysis: '데이터 분석',
      regression: '회귀 분석',
      regression_info: '변수들의 관계를 모델링합니다.',
      classification_analysis: '분류 분석',
      classification_analysis_info:
        '여러 개의 변수를 분석하여 사전에 정해진 범주로 분류합니다.',
      clustering_analysis: '군집 분석',
      clustering_analysis_info:
        '사전에 정해진 범주가 없는 상태에서 특성이 유사한 개체끼리 그룹화합니다.',
      timeseries_analysis: '시계열 분석',
      timeseries_analysis_info: '시간 데이터를 분석하여 미래를 예측합니다.',
      anomaly_detection: '이상 검출',
      anomaly_detection_info: '데이터 안의 비정상적인 값을 탐지합니다.',
      page_info2: '데이터셋, 변수, 모델을 선택하면 학습을 시작할 수 있어요.',
      dataset: '데이터셋',
      rechoice: '다시 선택',
      search_placeholder: '데이터셋 이름, 디스크립션을 입력하세요.',
      no_info: '정보 없음',
      model: '모델',
      search_placeholder_model: '모델 이름, 디스크립션을 입력하세요.',
      start_learning: '학습 시작',
      start_learning_info: '학습을 시작합니다.',
      view_learning_list: '학습 목록 보기',
      redirect_to_learning_page: '학습 진행 페이지로 이동합니다.',
      learning_ing_info: '학습을 만들고 있습니다 !',
      gpu_check_ing_info: 'GPU 확인중...',
      delay_info: '약 10초 정도 소요됩니다.',
      add_model: '모델 추가',
      learning_name: '학습 이름',
      ask_learning_name: '학습 이름을 입력해주세요.',
      explain: '설명',
      explain_placeholder: '이 학습을 설명하는 간략한 메모를 남겨보세요.',
      inform_learning_done: '학습이 끝나면 이메일로 알림 받기',
      split_dataset: '데이터셋 training/test 분할하기',
      random_seed: 'Random Seed 고정하기',
      start_learning2: '학습 시작하기',
      confirm_msg1: '모델을 삭제하면 되돌릴 수 없어요.\n삭제하시겠어요?',
      yes: '네',
      data_model_select_info: '데이터셋 또는 모델을 선택하세요.',
      error_not_valid_input_string: '문자열 타입은 입력변수가 될 수 없습니다',
      error_not_valid_output_string: '문자열 타입은 결과변수가 될 수 없습니다',
      error_only_int_for_decision: '분류분석의 결과변수는 정수만 가능합니다.',
      error_nan_value_exists: '빈 값이 존재하는 변수가 있습니다.',
      text: '텍스트 분석',
      normal: '일반 분석',
      wordcloud: '워드 클라우드',
      wordcloud_info: '워드클라우드',
      tagger: '형태소 분석',
      tagger_info: '형태소 분석',
      word2vec: '워드투벡터',
      word2vec_info: '워드투벡터',
      sentiment: '감성분석',
      association: '연관 분석',
      association_info: '연관 분석',
    },

    image: {
      page_guide: 'Image',
    },

    image_classification: {
      page_guide: '이미지를 클래스로 분류합니다.',
    },

    object_detection: {
      page_guide: '이미지 속 객체를 탐지하고 분류합니다.',
    },

    object_segmentation: {
      page_guide: '이미지 속 객체를 픽셀 단위로 분할합니다.',
    },

    semantic_segmentation: {
      page_guide: 'semantic segmentation을 수행합니다.',
    },

    training_list: {
      page_guide: 'Select the machine learning task type.',
    },

    training_detail: {
      page_guide: 'Select the machine learning task type.',
    },

    data_analisys: {
      page_guide: '데이터를 분석하기 위한 머신러닝 기법을 활용하세요.',
    },

    regression: {
      page_guide: '변수들의 관계를 모델링하고 값을 예측합니다.',
    },

    classificatoin: {
      page_guide: '새로운 데이터를 분류하기 위한 예측을 수행합니다',
    },

    clustering: {
      page_guide:
        '사전에 카테고리를 정의하지 않고, 특성이 유사한 개체끼리 그룹화합니다.',
    },

    timeseries_analysis: {
      page_guide: '시계열 데이터를 분석하여 미래를 예측합니다.',
    },
    anomaly_detection: {
      page_guide: '비정상적인 값/이상 현상을 탐지합니다.',
    },

    result: {
      cannot_get_link: '링크를 가져올 수 없습니다',
      page_guide:
        '학습이 완료된 모델입니다. 모델 이름을 눌러 상세 정보를 확인해 보세요.',
      title: '학습 관리',
      d1: '총 ',
      d2: '개의 학습. 학습 진행 상황과 결과를 확인하고 배포할 수 있어요.',
      page_info: '학습 진행 상황과 결과를 확인하고 배포할 수 있어요.',
      waiting: '대기',
      running: '진행중',
      done: '완료',
      search_placeholder: '학습 이름, 설명을 입력하세요',
      no_name: '학습 이름이 없습니다.',
      done2: '종료',
      go: '바로가기',
      deploy: '배포하기',
      no_search: '검색 내용을 찾을 수 없습니다.',
      no_waiting: '대기 중인 학습이 없습니다.',
      no_running: '진행 중인 학습이 없습니다.',
      no_done: '완료된 학습이 없습니다.',
      no_learning: '학습이 없습니다.',
      no_name2: '이름이 없습니다.',
      is_loading: '불러오는 중',
      no_loading: '학습을 불러올 수 없습니다.',
      graph: '학습 그래프',
      result: '결과표',
      test_set_result: '테스트셋 결과',
      save_resultcsv: '예측값 저장',
      test_graph: '테스트 그래프',
      no_graph: '그래프를 그릴 수 없습니다.',
      test_graph2: '테스트 그래프 (정렬)',
      test_graph3: '학습 · 테스트 그래프',
      group_graph: '군집별 실루엣 그래프',
      elbow_chart: '엘보우 차트',
      var_value_graph: '변수 중요도 그래프',
      not_finished_learning: '아직 완료되지 않은 학습입니다.',
      delete_learning: '학습 삭제',
      no_image_loading: '이미지를 불러올 수 없습니다.',
      learning_result: '학습 결과',
      model_test: '모델 테스트',
      confirm_text: '학습을 삭제하면 되돌릴 수 없어요.\n학습을 삭제하시겠어요?',
      confirm_yes: '네, 삭제할게요',
      model_deploy: '모델 배포하기',
      model_deploy_title:
        '학습을 정상적으로 완료했습니다! 모델 성능을 테스트하고, 배포하여 사용해 보세요.',
      go_deploy: '배포 바로가기',
      deploy_title2:
        '열심히 학습하고 있습니다! 실시간 학습 현황을 확인해 보세요.',
      prediction_value: '예측값',
      no_dataset_loading: '데이터셋을 불러올 수 없습니다.',
      log_error: '로그를 불러올 수 없습니다.',
      delete_learning_info: '학습이 삭제되었습니다.',
      delete_learning_error: '학습 삭제에 실패하였습니다.',
      move_to_deploy: '배포로 이동',
      regression_detail_result: '회귀분석 결과 상세',
      classification_detail_result: '분류분석 결과 상세',
      error_log: '에러 로그',
      show_my_result: '내 결과만 보기',
    },

    compare_results_of_training: {
      page_guide:
        '학습에 사용한 데이터와 모델 유형을 선택하고 성능을 비교할 수 있습니다.',
      title: '학습 결과 성능 비교',
      page_info:
        '학습에 사용한 데이터와 모델 유형을 선택하면 성능을 비교할 수 있어요.',
      learning_data: '학습 데이터',
      data_placeholder: '학습에 사용한 데이터를 선택하세요.',
      model_type: '학습 모델 유형',
      select_type: '유형 선택',
      view: '조회',
      data: '데이터 (행)',
      error_info: '조회 과정에서 오류가 발생했습니다.',
      retry_info: '다시 시도해 주세요.',
      error_info2: '조회 결과가 없어요.',
      retry_info2: '학습일을 다시 설정해 보세요.',
      main_info_p: '대표 성능 지표',
      select_info_p: '지표 선택',
      result_var: '출력 변수',
      result_var_placeholder: '특정 출력 변수를 선택할 수 있어요.',
      used_var: '학습에 사용한 변수',
      basic_option: '기본 옵션',
      high_option: '고급 옵션',
      input_var: '입력 변수',
      time_var: '날짜 변수',
      data_view: '데이터 보기',
      regression: '회귀 분석',
      clustering: '군집 분석',
      classification: '분류 분석',
      timeseries: '시계열 분석',
      anomaly: '이상 검출',
      msg1: '데이터셋을 선택하세요',
      msg2: '모델 유형을 선택하세요',
      msg3: '날짜를 선택하세요',
      msg4: '데이터셋이 유효하지 않습니다.',
      msg5: '학습 유형이 유효하지 않습니다.',
      no_name_learning: '이름 없는 학습',
      learning_name: '학습 이름',
      model: '모델',
      besides: '외', // ~외에 n개 할 때 사용
      count: '개', // 위와 동일
    },

    model_deployment: {
      page_guide:
        '현재 배포된 모델들입니다. 모델 이름을 누르고 상세 정보를 확인해 보세요.',
      title: '추론 하기',
      info1: '배포된 모델이 ',
      info2: '개 있습니다. 학습된 AI모델을 배포하고 추론할 수 있어요',
      page_info: '학습된 AI모델에 새 데이터를 적용하여 추론할 수 있어요',
      btn_deploy: '다른 모델 배포하기',
      search_placeholder: '모델 이름, 설명을 입력하세요.',
      active: '활성화',
      no_active: '비활성화',
      deploy_ing: '배포중',
      no_deploy_msg: '배포 중인 학습이 없습니다.',
      is_loading: '불러오는 중',
      do_active: '활성화하기',
      model_detail: '모델 상세',
      log: '추론 로그',
      state: '상태',
      learning_name: '학습 이름',
      type: '유형',
      dataset: '학습 데이터셋',
      model: '학습 모델',
      deploy_time: '배포일',
      copy: '복사',
      is_loading2: '로딩 중 입니다.',
      inference: '추론',
      del_deploy: '배포 삭제',
      classification: '이미지 분류',
      detection: '객체 검출',
      segmentation: '객체 분할',
      msg1: '복사에 성공하였습니다.',
      msg2: '복사에 실패하였습니다.',
      msg3: '배포를 삭제하였습니다.',
      msg4: '삭제에 실패하였습니다.',
      confirm_delete: '배포를 삭제하시겠습니까?',
      yes: '네',
      non_deployable_job: '배포할 수 없는 학습입니다.',
      already_deployed_job: '이미 배포 중인 학습입니다.',
      move_to_app: '앱 바로가기',
      edit_app: '앱 편집하기',
      publish_app: '앱 출시하기',
      not_published: '배포되지 않음',
    },
    gpu: {
      page_guide: 'GPU서버의 상태를 확인하실 수 있습니다.',
      title: '서버 자원',
      page_info: '서버의 상태를 확인해 보세요.',
      container_info: 'Container 목록',
      gpu_info: '현재 GPU 서버 상태를 불러오고 있어요.',
      memory_info: '가용 메모리',
      cpu_info: 'CPU 사용률',
      memory_usage: '메모리 사용률',
      gpu_memory_info: 'GPU 가용 메모리',
      gpu_usage_ratio: 'GPU 사용률',
      update_info: '정보 업데이트',
    },
    membership: {
      payment_step: '구매 단계',
      my_order: '내 주문',
      check_product: '선택한 제품을 확인하세요.',
      general_payment: '일반 결제',
      regular_payment: '정기 결제',
      monthly_payment: '매월 결제',
      regular: '정기',
      month: '개월',
      won: '원',
      paying: '구매',
      payment_info_save_fail: '결제 정보 저장에 실패하였습니다',
      succeed_payment: '결제에 성공하였습니다.',
      failed_payment: '결제에 실패하였습니다.',
      neural_plan: '뉴럴 스튜디오 플랜',
      point_history: '포인트 기록',
      payment_history: '결제 기록',
      history_description: '결제를 요청했던 목록을 모아 보세요',
      payment_request: '결제 요청',
      payment_complete: '결제 완료',
      payment_fail: '결제 실패',
      refund_request: '환불 요청',
      refund: '환불',
      payment_type: '결제 타입',
      period: '기간',
      state: '상태',
      created_date: '생성일',
      membership: '멤버십',
      membership_description: '변경할 멤버십 플랜을 선택하세요',
      current_pricing: '현재 멤버십',
      regular_subscription: '정기 구독',
      unsubscription: '멤버십 정지',
      one_month: '1개월',
      three_months: '3개월',
      six_months: '6개월',
      twelve_months: '12개월',
      max_csv_row_count: 'csv row 수',
      max_csv_column_count: 'csv col 수',
      create_workspace: '워크스페이스 생성',
      create_dataset_inJoined_workspace:
        '다른 워크스페이스에서 데이터셋 업로드',
      create_job_inJoined_workspace: '참여한 워크스페이스에서 학습 생성',
      max_workspace_member_count: '워크스페이스에 참여가능한 팀원 수',
      max_workspace_count: '생성할 수 있는 워크스페이스 수',
      max_workspace_dataset_count:
        '워크스페이스에 업로드할 수 있는 데이터셋 수',
      max_workspace_job_count: '워크스페이스에 생성할 수 있는 학습 수',
      max_workspace_inference_count: '워크스페이스에 생성할 수 있는 추론 수',
      price: '가격',
      free: '무료',
      select: '선택',
    },

    components: {
      class_list: '클래스 목록',
      guide_box: '가이드 박스',
      image_move: '이미지 이동',
      bounding_box: '바운딩 박스',
      poly_box: '폴리곤 박스',
      pretrained_info: 'Pre-trained 정보',
      learning_data: '학습 데이터',
      model: '모델',
      view_per_class: '클래스별 보기',
      label: '라벨',
      kind: '종류',
      learning_type: '학습 유형',
      select_learning_type: '학습 유형을 선택하세요.',
      format: '포맷',
      learning_data_name: '학습 데이터셋 이름',
      explain: '설명',
      explain_placeholder2: '이 데이터셋을 설명하는 간략한 설명을 남겨보세요.',
      explain_placeholder:
        '데이터셋을 설명하는 간략한 메모를 남겨 보세요. 입력하지 않으셔도 변환할 수 있어요.',
      convert: '변환하기',
      start_convert: '변환을 시작합니다!',
      convert_info: '학습 데이터 목록에서 확인할 수 있어요.',
      no_convert: '이 파일을 변환할 수 없습니다.',
      no_convert_info:
        '파일의 포맷과 어노테이션 상태를 확인하고 다시 시도해 주세요.',
      retry: '다시 하기',
      select_anno_method: '어노테이션 방법을 선택해 주세요.',
      file_convert: '파일 변환하기',
      use_neuralworks: '뉴럴웍스 툴 사용',
      prepare_ing: '(준비중)',
      vanish_info: '이 창은 3초 후에 닫힙니다.',
      data_name: '데이터셋 이름',
      done: '완료',
      select_format: '포맷을 선택하고 파일을 올려주세요.',
      drag_info: '여기에 어노테이션 zip 파일을 끌어다 놓으세요.',
      find_file: '파일 찾기',
      camera: '카메라',
      check_before_upload: '파일 올리기 전 꼭 확인하기',
      raw_data_anno: '원천 데이터의 모든 이미지에 어노테이션이 되어있다.',
      raw_data_name: '각 파일은 원천 데이터의 파일과 이름이 같다.',
      class_zip: '라벨명을 나열한 "class.txt" 파일이 zip 파일에 있다.',
      anno_data_name: '어노테이션 데이터셋 이름',
      image_classification: '이미지 분류',
      detection: '객체 검출',
      segmentation: '객체 분할',
      step1: '단계1. 데이터셋의 종류를 선택하세요',
      from_server_title: '서버에서 가져오기',
      // 서버에서 가져오기를 가져오기로 변경한 버전
      from_server: '가져오기',
      image: '이미지',
      video: '비디오',
      general: '일반',
      lang: '자연어',
      voice: '음성',
      step2: '단계2. zip 파일을 업로드하세요 (drag & drop)',
      zip_upload: 'zip 파일을 올려주세요',
      data_accept_types_csv: '단계2. csv 파일을 업로드하세요 (drag & drop)',
      data_accept_types_image: '사용할 수 있는 파일: png, jpg, jpeg',
      data_accept_types_text: '단계2. txt 파일을 업로드하세요 (drag & drop)',
      image_upload_check: '이미지 업로드 주의사항',
      upload: '업로드 하기',
      upload_start: '업로드를 시작합니다!',
      upload_status: '화면 상단에서 진행 상황을 확인할 수 있어요.',
      data_upload_start: '데이터셋 업로드 시작',
      confirm: '확인',
      start_deploy: '추론 수행을 위해 모델 배포하기',
      learning_name: '학습 이름',
      learning_data2: '학습 데이터셋',
      learning_model: '학습 모델',
      api_address: 'API 주소',
      name_tip: '영어 대/소문자, 숫자만 입력하세요',
      confirm2: '중복 확인',
      do_deploy: '배포하기',
      deploy_cancel_info: '배포를 취소하면 되돌릴 수 없습니다.',
      cancel_ask: '취소하시겠어요?',
      cancel_no: '아니요, 계속 할게요',
      cancel_yes: '네, 취소할게요',
      start_deploy_ing: '배포를 시작합니다!',
      no_file: '파일이 없습니다.',
      select_var: '변수 목록에서 변수를 선택하세요',
      select_var_info:
        '출력 변수를 먼저 선택하면 상관계수를 보면서 입력 변수를 선택할 수 있어요.',
      var_list: '변수 목록',
      var_input: '입력 변수',
      var_output: '출력 변수',
      var_time: '날짜 변수',
      view_data: '데이터 보러가기',
      var_selection_done: '변수 선택 완료',
      data_error: '데이터셋에 오류가 있습니다.',
      set_model_option: '모델의 옵션을 설정하세요.',
      basic_option: '기본 옵션 (필수)',
      basic_value: '기본값 : ',
      set_value_init: '기본값으로 초기화',
      no_basic_option: '기본 옵션이 없어요.',
      high_option: '고급 옵션 (선택)',
      no_high_option: '고급 옵션이 없어요.',
      set_done: '설정 완료',
      set_select_model_option: '모델을 선택하고 옵션을 설정하세요',
      page_out_info: '페이지를 나가면 내용이 저장되지 않아요.',
      exit_info: '나가시겠어요?',
      no: '아니요',
      yes: '네, 나갈게요',
      original: '원본',
      apply_preprocess: '전처리 적용',
      save_resultcsv: '예측값 저장',
      test_file: '다른 파일로 테스트하기',
      performance: '성능',
      no_performance: '성능 정보가 없습니다.',
      inference: '추론하기',
      error_for_infer: '추론 중 문제가 발생했습니다.',
      retry_info: '잠시 후 다른 파일로 다시 시도해 주세요.',
      retry_info2: '잠시 후 다른 이미지로 다시 시도해 주세요.',
      drag_file: '여기에 파일을 끌어다 놓으세요!',
      can_use_file_info: '사용할 수 있는 파일: csv (최대 1개)',
      can_use_file_info2: '사용할 수 있는 파일: png, jpg (최대 10개)',
      contain_all_input_var: '모델 학습에 사용한 입력 변수가 모두 들어있다.',
      same_info: '입력 변수의 이름과 값의 형식이 원천 데이터와 같다.',
      no_valid_column: '유효한 컬럼이 없습니다.',
      short_valid_column: '유효한 컬럼이 부족합니다.',
      infer_value: '예측값',
      no_server_response: '서버가 응답하지 않습니다.',
      cannot_request: '서버에 요청을 보낼 수 없습니다.',
      basic_info: '기본 정보',
      test_p: '테스트셋 성능',
      delete: '삭제',
      add: '추가',
      deployed_model: '이미 배포 중인 학습입니다.',
      test_image: '다른 이미지로 테스트 하기',
      infer_result: '추론 결과',
      no_detection: '객체 미검출',
      total: '전체',
      detail_result: '상세 결과',
      error: '오류',
      detected_object: '검출된 객체',
      data: '데이터',
      row: '데이터(행)',
      column: '변수(열)',
      class: '클래스',
      size: '크기',
      used_var: '학습에 사용한 변수',
      input_var: '입력 변수',
      result_var: '출력 변수',
      indentifier_var: '식별자 변수',
      only_one_var_allowed: '는 한개만 가능합니다.',
      time_var: '날짜 변수',
      data_option3: '데이터 옵션',
      flip: '좌우 뒤집기, 상하 뒤집기',
      rotate: '왼쪽으로 90도 회전',
      not_find_data: '데이터셋을 찾을 수 없습니다.',
      not_find_dataset_files: '데이터셋 파일을 찾지 못했습니다.',
      library: '라이브러리',
      method: '분석 기법',
      bench_mark: '벤치마크 성능',
      tag: '태그',
      basic_option2: '기본 옵션',
      no_basic_option2: '기본 옵션이 없습니다.',
      high_option2: '고급 옵션',
      no_high_option2: '고급 옵션이 없습니다.',
      no_model: '모델을 찾을 수 없습니다.',
      view_data2: '데이터 보기',
      done2: '종료',
      data_model_option: '데이터셋, 모델, 옵션 정보',
      back: '뒤로가기',
      no_table_header: '테이블 헤더가 비어있습니다.',
      table_empty: '테이블이 비어있습니다.',
      error_alarm: '잘못된 알림입니다.',
      no_alarm: '알림 내용이 없습니다.',
      inform: '알림',
      all_delete: '모두 삭제',
      no_message: '알림 메시지가 없습니다.',
      when_no_input: '입력하지 않으면',
      use_default_value: '기본값 사용',
      use_recommended_value: '자동 설정',
      confirm_yes: '네',
      confirm_no: '아니요',
    },

    js: {
      fail_auto_anno: '오토 어노테이션에 실패하였습니다.',
      store_info: '저장되었습니다.',
      fail_store: '저장에 실패하였습니다',
      no_email_form: '이메일 형식이 아닙니다',
      pw_rule: '비밀번호는 영문, 숫자 포함 6자리 이상입니다',
      pw_incorrect: '비밀번호가 틀립니다.',
      not_our_email: '가입하지 않은 이메일 주소입니다.',
      fail_email_send: '이메일 전송에 실패하였습니다.',
      reenter_code: '인증할 수 없는 코드입니다. 코드를 다시 받아주세요.',
      need_email_verification: '이메일 인증이 필요합니다.',
      need_code_verfication: '코드 인증이 필요합니다.',
      need_agreement: '약관에 동의가 필요합니다.',
      reenter_code2: '인증할 수 없는 코드입니다. 인증 코드를 확인해주세요.',
      expire_code: '인증 시간이 지났습니다. 인증 코드를 다시 받아주세요.',
      same_email: '이미 존재하는 이메일 입니다.',
      not_found_email: '존재하지 않는 이메일 입니다.',
      raw_data: '원천 데이터',
      learning_data: '학습 데이터',
      dataset: '데이터셋',
      type: '유형',
      count: '개수',
      modify_time: '최근 수정일',
      same_name: '이미 존재하는 이름입니다.',
      upload_error: '업로드에 오류가 발생했습니다.',
      network_error: '네트워크 오류가 발생했습니다.',
      kind: '종류',
      size: '크기',
      model_name: '모델 이름',
      learning_dataset: '학습 데이터셋',
      learning_model: '학습 모델',
      deploy_time: '배포일',
      state: '상태',
      image_classification: '이미지 분류',
      detection: '객체 탐지',
      segmentation: '객체 분류',
      no_info: '정보 없음',
      not_load_data: '데이터셋을 불러올 수 없습니다.',
      sample_count: '샘플 수',
      model: '모델',
      model_type: '모델 유형',
      library: '라이브러리',
      method: '분석기법',
      detail: '상세',
      param_number: '파라미터 수',
      publish: '발표',
      regression: '회귀 분석',
      cluster: '군집 분석',
      decision: '분류 분석',
      timeanalysis: '시계열 분석',
      anomaly: '이상 탐지',
      select_data: ' 학습에 사용할 데이터를 선택해 주세요.',
      select_model: ' 학습에 사용할 모델을 선택해 주세요.',
      set_option: '학습의 이름을 정하고 옵션을 설정해 보세요.',
      same_deploy_model: '이미 배포된 모델입니다.',
      check_ing: '확인 중',
      recent: '최근',
      waiting: '대기',
      ing: '진행 중',
      done: '종료',
      learning_name: '학습 이름',
      performance: '성능',
      learn_request: '학습 요청',
      learn_start: '학습 시작',
      learn_ing_info: '학습 진행률',
      deploy: '배포',
      owner: '소유자',
      total: '전체',
      image: '이미지',
      csv: '데이터 분석',
      lang: '자연어',
      sound: '음성',
      detection2: '객체 검출',
      segmentation2: '객체 분할',
      image_create: '이미지 생성',
      learning_waiting: '학습 대기',
      learn_done: '학습 완료',
      learn_fail: '학습 실패',
      raw_dataset_init: '원천 데이터셋 업로드 준비',
      raw_dataset_doing: '원천 데이터셋 업로드 시작',
      raw_dataset_done: '원천 데이터셋 업로드 완료',
      raw_dataset_fail: '원천 데이터셋 업로드 실패',
      learning_dataset_init: '학습 데이터셋 업로드 준비',
      learning_dataset_doing: '학습 데이터셋 업로드 시작',
      learning_dataset_sync: '학습 데이터셋 동기화 시작',
      learning_dataset_done: '학습 데이터셋 업로드 완료',
      learning_dataset_fail: '학습 데이터셋 업로드 실패',
      cannot_deploy: '배포할 수 없는 학습입니다.',
      already_deploy: '이미 배포 중인 학습입니다.',
      general: '일반',
      association: '연관분석',
    },

    helper: {
      total: '전체',
      ing: '진행 중',
      review_ing: '리뷰 중',
      done: '완료',
      no_name: '이름이 없습니다.',
      bounding_box: '바운딩 박스',
      polygon: '폴리곤',
      no_find_anno: '어노테이션을 찾을 수 없습니다.',
      done2: '종료',
      job_name: '작업 이름',
      type: '유형',
      dataset: '데이터셋',
      job_ing: '작업 진행률',
      job_create_time: '작업 생성일',
      data_name: '데이터셋 이름',
      data_count: '데이터 수',
      size: '크기',
      upload_date: '업로드 날짜',
      modify_time: '최근 수정일',
      image_classification: '이미지 분류',
      detection: '객체 탐지',
      bool: '논리형',
      int: '정수형',
      float: '실수형',
      object: '문자형',
      date: '날짜형',
      modify_data_info: '데이터셋 정보를 수정하였습니다.',
      fail_modify_data_info: '데이터셋 정보 수정에 실패하였습니다.',
      yes: '네, 삭제할게요',
      explain: '설명',
      no_explain: '설명이 없습니다.',
      data_row: '데이터 (행)',
      column: '변수 (열)',
      preprocess: '전처리',
      view_detail: '내역보기',
      decide: '판단',
      remove: '제거',
      mean: '평균',
      median: '중앙값',
      mode: '최빈값',
      min: '최솟값',
      max: '최댓값',
      direct_input: '직접입력',
      filtering: '필터링',
      replace_value: '값 대체',
      no_data: '데이터가 없어요.',
      sample_count: '샘플 수',
      not_load_data: '데이터를 불러올 수 없습니다.',
      learning: '학습',
      raw_data: '원천',
      delete_info: '삭제 되었습니다.',
      delete_fail: '삭제에 실패 하였습니다.',
      not_know_error: '알 수 없는 오류가 발생했습니다.',
      type_error: '두 컬럼의 타입이 동일하지 않습니다.',
      server_error: '데이터 처리 서버가 응답하지 않습니다.',
      select_data: '데이터셋을 선택하세요',
      enter_name: '이름을 입력하세요',
      request_upload: '데이터셋 업로드를 요청하였습니다.',
      data_error: '데이터를 처리하지 못했습니다.',
      learning_name: '학습 이름',
      model_type: '모델 유형',
      data: '데이터셋',
      model: '모델',
      learning_start: '학습 시작',
      learning_done: '학습 종료',
      learning_time: '학습 시간',
      estimated_end_time: '예상종료',
      csv_classification: '분류 분석',
      detection2: '객체 검출',
      segmentation: '객체 분할',
      regression: '회귀 분석',
      cluster: '군집 분석',
      anomaly: '이상 탐지',
      time: '시계열 분석',
      stat: '통계 분석',
      ML: '머신러닝',
      DL: '딥러닝',
      no_info: '알 수 없음',
      manager: '관리자',
      user: '일반유저',
      owner: '소유자',
      sort_type1: '권한순',
      sort_type2: '추가순',
      sort_type3: '알파벳순',
      load_ing: '불러오는 중 입니다',
      no_workspace: '워크스페이스가 없습니다.',
      cover_change_ok: '커버 변경에 성공했습니다',
      cover_change_fail: '커버 변경에 실패하였습니다',
      cover_delete_ok: '커버 삭제에 성공했습니다.',
      cover_delete_fail: '커버 삭제에 실패했습니다.',
      authority_change_ok: '권한을 수정하였습니다.',
      authority_change_fail: '권한을 수정하지 못했습니다.',
      error: '오류가 발생했습니다.',
      user_delete_ok: '사용자 삭제에 성공했습니다.',
      user_delete_fail: '사용자 삭제에 실패했습니다.',
      user_not_exist: '존재하지 않는 사용자입니다.',
      need_time: '적용까지 시간이 소요됩니다.',
      invite_info: ' 명의 사용자를 초대하였습니다.',
      invite_fail: '사용자를 초대하는데 실패하였습니다.',
      workspace_modify_fail: '워크스페이스 변경에 실패하였습니다.',
      workspace_not_load: '워크스페이스를 불러올 수 없습니다.',
      upload_exit_if_pageout: '페이지를 벗어나면 업로드가 종료됩니다',
      existing_user: '이미 워크스페이스에 가입되어 있습니다',
      existing_in_list: '이미 초대 목록에 추가되었습니다',
      domain_error: '가입 가능한 이메일 주소가 아닙니다',
      load_packages: '필요한 패키지를 불러오고 있습니다.',
      preproc_stage_1: '단계 추가 버튼을 클릭하세요.',
      preproc_stage_2: '전처리 유형을 선택하세요',
      preproc_stage_3: '변수를 선택하세요',
      preproc_stage_4: '처리 방법을 선택 후 미리보기나 완료를 누르세요',
      preproc_stage_5: '변수나 처리 방법을 수정 후 완료를 누르세요',
      eda_stage_1: '데이터셋을 선택하세요.',
      eda_stage_2_1: '필터링할 변수를 선택하세요.',
      eda_stage_2_2: '조건식을 완성한 후 완료 버튼을 클릭하세요.',
      eda_stage_3: '변수를 선택하여 다양한 그래프로 데이터를 확인하세요.',
      for_learning: '학습용',
      cannot_create_summary: '요약을 생성할 수 없습니다.',
      cannot_export: '내보내기 오류가 발생했습니다.',
      day: '일',
      hour: '시간',
      minute: '분',
      second: '초',
      fail_get_workspace_info: '워크스페이스 정보를 가져오는데 실패하였습니다.',
      success_edit_email_domain: '이메일 도메인 목록 수정에 성공하였습니다.',
      fail_edit_email_domain: '이메일 도메인 목록 수정에 실패하였습니다.',
      success_change_pw: '비밀번호 변경에 성공하였습니다.',
      fail_change_pw: '비밀번호 변경에 실패하였습니다.',
      success_change_name: '이름 변경에 성공하였습니다.',
      fail_change_name: '이름 변경에 실패하였습니다.',
      somebody_workspace: '워크스페이스입니다.',
      allowed_people_exceed: '허용된 초대 인원 수를 초과하였습니다.',
      still_in_ready: '아직 준비 중 입니다.',
      file_move_success: '파일 이동 요청에 성공하였습니다.',
      file_move_fail: '파일 이동 요청에 실패하였습니다.',
      some_file_upload_start: '개 파일 업로드 시작',
      some_file_upload_done: '개 파일 업로드 완료',
      some_file_upload_fail: '개 파일 업로드 실패',
      pw_rule_length: '비밀번호는 6자 이상이어야 합니다.',
      pw_rule_alphabet: '비밀번호는 영문을 포함해야 합니다.',
      pw_rule_number: '비밀번호는 숫자를 포함해야 합니다.',
      pw_not_match: '비밀번호가 일치하지 않습니다.',
      wordcloud: '워드클라우드',
      tagger: '형태소 분석',
      word2vec: '워드투벡터',
      sentiment: '감성분석',
      association: '연관분석',
    },

    layout: {
      guide: '사용법',
      contactus: '문의하기',
      company_info_top:
        '뉴럴웍스랩(주) | 서울특별시 강남구 테헤란로 2길 27, 9층 | 전화 02-6737-0255',
      company_info_bottom:
        '대표 한대희 | 사업자등록번호 810-86-00605 | 통신판매업신고번호 2017-서울강남-04611',
      profile: '마이페이지',
      workspace: '워크스페이스',
      logout: '로그아웃',
      error_info: '사이트 오류로 인해 요청하신 작업을 처리할 수 없어요.',
      back_to_prepage: '이전 페이지로 돌아가기',
    },
  },

  preproc: {
    scaler: {
      string: '스케일링은 정수 혹은 실수 변수만 가능합니다.',
    },
    categorical: {
      none: '변수에 결측치가 있습니다.',
    },
    missigValue: {
      mean: '문자열은 평균을 사용할 수 없습니다. 직접 입력해주세요.',
      median: '문자열은 중앙값을 사용할 수 없습니다. 직접 입력해주세요.',
      mode: '문자열은 최빈값을 사용할 수 없습니다. 직접 입력해주세요.',
      minimum: '문자열은 최솟값을 사용할 수 없습니다. 직접 입력해주세요.',
      maximum: '문자열은 최댓값을 사용할 수 없습니다. 직접 입력해주세요.',
    },
    replace: {
      stringRange: '문자형 변수는 범위를 입력할 수 없습니다',
    },
    filtering: {
      none: '필터링된 값이 없습니다.',
      number: '조건에 실수 혹은 정수를 입력해주세요.',
      stringRange: '문자형 변수는 범위를 지정할 수 없습니다.',
    },
    outlier: {
      string: '이상치 처리는 정수 혹은 실수 변수만 가능합니다.',
    },
  },
}
