var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ns-data-table-wrap"},[_c('table',{staticClass:"ns-data-table",class:{
      'no-line': _vm.noLine,
      white: _vm.white,
      gray: !_vm.white && !_vm.summary,
      summary: _vm.summary,
      border: _vm.border,
    },on:{"click":_vm.tableClick}},[_c('thead',[_c('tr',{ref:"theadRef",staticClass:"ns-data-table-head"},[(_vm.head.length > 0)?[(!_vm.noNumber)?_c('th',{staticClass:"number"}):_vm._e(),_vm._v(" "),_vm._l((_vm.head),function(_head,index){return _c('th',{key:(_head + "-" + index),class:{ accent: _vm.isAccented(index) },attrs:{"title":_head}},[_c('span',[(_vm.getType(_head))?_c('ns-img',{staticClass:"ns-data-table-icon",attrs:{"src":require(("~/assets/images/datasets/var_" + (_vm.getType(
                    _head
                  )) + ".svg"))}}):_vm._e(),_vm._v(" "),(_vm.data[0][index] && _vm.data[0][index].name)?_c('span',[_c('katex-element',{attrs:{"expression":_vm.data[0][index].name}})],1):_c('span',[_vm._v(" "+_vm._s(_head)+" ")])],1),_vm._v(" "),(_vm.values.length > 0 && _vm.options)?_c('ns-select',{staticClass:"ns-data-table-select",attrs:{"small":"","hide-label":"","options":_vm.options,"value":_vm.values[index]},on:{"change":function (option) { return _vm.optionChange(index, option); }}}):_vm._e()],1)})]:_c('th',{staticClass:"no-data"},[_vm._v("\n          "+_vm._s(_vm.$t('mesg.components.no_table_header'))+"\n        ")])],2)]),_vm._v(" "),(_vm.data.length > 0)?_c('tbody',{class:_vm.wide ? 'wide' : '',on:{"dblclick":_vm.onDbClick}},_vm._l((_vm.data),function(_row,rowIndex){return _c('tr',{key:("row-" + rowIndex)},[(!_vm.noNumber)?_c('td',{staticClass:"number"},[_vm._v(_vm._s(rowIndex + 1))]):_vm._e(),_vm._v(" "),_vm._l((_row),function(_col,colIndex){return _c('td',{key:("col-" + colIndex),class:{ accent: _vm.isAccented(colIndex) },attrs:{"title":_col}},[(_vm.json)?_vm._l((_vm.parseJson(_col)),function(line,index){return _c('span',{key:'ns-data-table-row' + line + '-' + index},[(index !== 0)?_c('br'):_vm._e(),_vm._v(" "),_c('span',{class:{
                  true: (line || '').toString() === 'true',
                  false: (line || '').toString() === 'false',
                }},[_vm._v(_vm._s(line))])])}):_c('span',[_vm._v(_vm._s(_col))])],2)})],2)}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('mesg.components.table_empty')))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }