import { computed, reactive, toRefs } from '@nuxtjs/composition-api'
import useI18N from '@/translations/i18n'
import api from '../../util/api'
import storage from '../../util/storage'
import useSocket from '../../util/socket'

const $t = useI18N()
const state = reactive({
  isLoading: false,
  type: 'password',
  find: false,
  register: false,
  policy: false,
  loginStatus: 0,
  loginEmail: '',
  loginPassword: '',
  registerStatus: 0,
  registerEmail: '',
  registerPassword: '',
  codeSended: false,
  codeInvalid: false,
  code: '',
  validated: false,
  acceptPolicy: true,
  codeButton: true,
  timeout: 0,
  intervalHandler: null,
  remain: '',
  registerPassType: 'password',
  // liveklass
  loginUserName: '',
  loginPhoneNumber: '',
  loginTeacherEmail: '',
})

/* 라이브 클래스 인풋 관련 설정 시작 */
function setLoginUserName(name) {
  state.loginUserName = name
  if (state.loginStatus !== 0) {
    state.loginStatus = 0
  }
}

function setLoginPhoneNumber(number) {
  state.loginPhoneNumber = number
  if (state.loginStatus !== 0) {
    state.loginStatus = 0
  }
}

function setLoginTeacherEmail(email) {
  state.loginTeacherEmail = email
  if (state.loginStatus !== 0) {
    state.loginStatus = 0
  }
}

/* 라이브 클래스 인풋 관련 설정 끝 */

function setLoginEmail(email) {
  state.loginEmail = email
  if (state.loginStatus !== 0) {
    state.loginStatus = 0
  }
}

function setLoginPassword(password) {
  state.loginPassword = password
  if (state.loginStatus !== 0) {
    state.loginStatus = 0
  }
}
function setRegisterEmail(email) {
  state.registerEmail = email
  state.codeButton = !validateEmail(state.registerEmail)
  if (state.registerStatus !== 0) {
    state.registerStatus = 0
  }
}
function setRegisterPassword(password) {
  state.registerPassword = password
}
function setCode(code) {
  state.code = code
  if (state.registerStatus !== 0) {
    state.registerStatus = 0
  }
}

const eyeClick = () => {
  state.type = state.type === 'password' ? 'text' : 'password'
}
const registerEyeClick = () => {
  state.registerPassType =
    state.registerPassType === 'password' ? 'text' : 'password'
}

const openFind = () => (state.find = true)
const openRegister = () => (state.register = true)
const openPolicy = () => (state.policy = true)
const resetRegister = () => {
  state.registerEmail = ''
  state.registerPassword = ''
  state.registerPassType = 'password'
  state.registerStatus = 0
  state.codeSended = false
  state.validated = false
  state.code = ''
  state.codeButton = true
}
const closeFind = () => {
  resetRegister()
  state.find = false
}
const closeRegister = () => {
  resetRegister()
  state.register = false
}
const closePolicy = () => {
  state.policy = false
}

const enc = encodeURI

function validateEmail(email) {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return email.match(regex)
}

function validatePass(password) {
  return validatePassA(password) && validatePassB(password)
}

function validatePassA(password) {
  return password.match(/[a-z]/) && password.match(/[0-9]/)
}

function validatePassB(password) {
  return password.length > 5
}

async function signin() {
  if (!validateEmail(state.loginEmail)) {
    state.loginStatus = ErrorStatusCode.NoEmailForm
    return false
  } else if (!validatePass(state.loginPassword)) {
    state.loginStatus = ErrorStatusCode.PasswordRule
    return false
  }
  state.isLoading = true
  const response = await api.post(
    '/auth/login',
    JSON.stringify({
      email: enc(state.loginEmail),
      password: enc(state.loginPassword),
    })
  )
  state.loginStatus = response.status
  state.isLoading = false
  if (response.status === 200) {
    storage.setToken(response.data.accessToken)
    storage.set('NWUID', response.data.userId)
    useSocket().connect()
    return response.data
  }
  return false
}

async function signup(name, accountType = 'GENERAL', referralCode) {
  if (!state.codeSended) {
    state.registerStatus = ErrorStatusCode.NeedEmailVerification
    return false
  }
  if (!state.validated) {
    state.registerStatus = ErrorStatusCode.NeedEmailVerification
    return false
  }
  if (!state.acceptPolicy) {
    state.registerStatus = ErrorStatusCode.NeedAgreement
    return false
  }
  state.isLoading = true

  const email = state.registerEmail

  if (name === 'name' || !name) {
    name = email.substring(0, email.lastIndexOf('@')) || 'name'
  }
  const payload = {
    email,
    password: state.registerPassword,
    name,
    accountType,
  }

  if (referralCode) {
    payload.referralCode = referralCode
  }
  const response = await api.post('/auth/signup', payload)
  state.registerStatus = response.status
  state.isLoading = false
  if (response.status === 201) {
    closeRegister()
    return true
  }
  return false
}

async function handleSendCodeApi() {
  state.codeSended = false
  state.validated = false
  state.codeInvalid = false
  const response = await api.post(
    '/auth/send/code',
    JSON.stringify({
      email: state.registerEmail,
    })
  )
  if (response.status === 200) {
    state.timeout = new Date().getTime() + 180000
    state.intervalHandler = setInterval(() => {
      const _remain = (state.timeout - new Date().getTime()) / 1000
      if (_remain < 0) {
        clearInterval(state.intervalHandler)
        state.codeSended = false
        state.registerStatus = ErrorStatusCode.ExpireCode
        state.codeInvalid = true
      }
      const second = parseInt(_remain % 60)
      const minutes = parseInt(_remain / 60)
      state.remain = minutes + ':' + (second < 10 ? '0' + second : second)
    }, 250)
    state.codeSended = true
  } else {
    state.registerStatus = ErrorStatusCode.FailEmailSend
  }
}

async function sendPasswordFindCode() {
  if (!validateEmail(state.registerEmail)) {
    state.registerStatus = ErrorStatusCode.NoEmailForm
    return
  }
  state.isLoading = true
  const provider = await api.get(
    '/users/provider/' + encodeURI(state.registerEmail)
  )

  if (provider.status === 404) {
    state.registerStatus = ErrorStatusCode.NotFoundEmail
  } else if (
    provider.status === 200 &&
    provider.data.authProvider === 'KAKAO'
  ) {
    state.registerStatus = ErrorStatusCode.KakaoSignin
  } else {
    await handleSendCodeApi()
  }
  state.isLoading = false
}

async function sendCode() {
  if (!validateEmail(state.registerEmail)) {
    state.registerStatus = ErrorStatusCode.NoEmailForm
    return
  }
  state.isLoading = true
  const available = await api.get(
    '/users/email/' + encodeURI(state.registerEmail)
  )
  if (available.status === 200) {
    await handleSendCodeApi()
  } else {
    state.registerStatus = ErrorStatusCode.SameEmail
  }
  state.isLoading = false
}

async function validate() {
  state.isLoading = true
  const response = await api.post(
    '/auth/validate',
    JSON.stringify({
      email: state.registerEmail,
      code: state.code,
    })
  )
  if (response.status === 200) {
    clearInterval(state.intervalHandler)
    state.validated = true
    state.registerStatus = 0
  } else {
    state.registerStatus = ErrorStatusCode.ReenterCode2
  }
  state.isLoading = false
  return response
}

async function updatePassword() {
  if (!state.codeSended) {
    state.registerStatus = ErrorStatusCode.NeedEmailVerification
    return false
  }
  if (!state.validated) {
    state.registerStatus = ErrorStatusCode.NeedCodeVerification
    return false
  }
  state.isLoading = true
  const response = await api.post(
    '/auth/password',
    JSON.stringify({
      email: state.registerEmail,
      password: state.registerPassword,
    })
  )
  state.registerStatus = response.status
  state.isLoading = false
  if (response.status === 200) {
    closeFind()
    return true
  }
  return false
}

const ErrorStatusCode = {
  NoEmailForm: 600,
  FailEmailSend: 601,
  NeedEmailVerification: 602,
  NeedCodeVerification: 603,
  NeedAgreement: 604,
  ReenterCode2: 605,
  ExpireCode: 606,
  SameEmail: 607,
  NotFoundEmail: 608,
  KakaoSignin: 609,
  PasswordRule: 610,
}
const loginError = computed(() => {
  if (state.loginStatus === 600) {
    return {
      errorEmail: $t('mesg.js.no_email_form'),
      errorPass: '',
    }
  } else if (state.loginStatus === 610) {
    return {
      errorEmail: '',
      errorPass: $t('mesg.js.pw_rule'),
    }
  }
  if (state.loginStatus === 401) {
    return {
      errorEmail: '',
      errorPass: $t('mesg.js.pw_incorrect'),
    }
  } else if (state.loginStatus === 404) {
    return {
      errorEmail: $t('mesg.js.not_our_email'),
      errorPass: '',
    }
  }
  return {
    errorEmail: '',
    errorPass: '',
  }
})

const registerError = computed(() => {
  if (state.registerStatus === 600) {
    return {
      errorEmail: $t('mesg.js.no_email_form'),
      errorCode: '',
    }
  }
  if (state.registerStatus === 601) {
    return {
      errorEmail: $t('mesg.js.fail_email_send'),
      errorCode: '',
    }
  }
  if (state.registerStatus === 602) {
    return {
      errorEmail: $t('mesg.js.need_email_verification'),
      errorCode: '',
    }
  }
  if (state.registerStatus === 603) {
    return {
      errorEmail: '',
      errorCode: $t('mesg.js.need_code_verification'),
    }
  }
  if (state.registerStatus === 604) {
    return {
      errorEmail: $t('mesg.js.need_agreement'),
      errorCode: '',
    }
  }
  if (state.registerStatus === 605) {
    return {
      errorEmail: '',
      errorCode: $t('mesg.js.reenter_code2'),
    }
  }
  if (state.registerStatus === 606) {
    return {
      errorEmail: '',
      errorCode: $t('mesg.js.expire_code'),
    }
  }
  if (state.registerStatus === 607) {
    return {
      errorEmail: $t('mesg.js.same_email'),
      errorCode: '',
    }
  }
  if (state.registerStatus === 608) {
    return {
      errorEmail: $t('mesg.js.not_found_email'),
      errorCode: '',
    }
  }
  if (state.registerStatus === 609) {
    return {
      errorEmail: $t('mesg.signin.kakao'),
      errorCode: '',
    }
  }
  return {
    errorEmail: '',
    errorCode: '',
  }
})

export default function useSigninState() {
  return {
    ...toRefs(state),
    eyeClick,
    registerEyeClick,
    openFind,
    openRegister,
    openPolicy,
    closeFind,
    closeRegister,
    closePolicy,
    signin,
    signup,
    sendCode,
    validate,
    updatePassword,
    setLoginEmail,
    setLoginPassword,
    setRegisterEmail,
    setRegisterPassword,
    setCode,
    validateEmail,
    validatePass,
    validatePassA,
    validatePassB,
    loginError,
    registerError,
    setLoginUserName,
    setLoginPhoneNumber,
    setLoginTeacherEmail,
    sendPasswordFindCode,
  }
}
