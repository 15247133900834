//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api'
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const count = ref(0)
    const accordions = ref([])
    function bindAccordion(accordion) {
      if (accordion.number && accordion.number !== 0) {
        return accordions.value.indexOf(accordion) + 1
      } else {
        accordions.value.push(accordion)
        accordion.number = count.value += 1
        return accordion.number
      }
    }
    function toggleOpen(number) {
      if (number === props.index) {
        emit('change', 0)
      } else {
        emit('change', number)
      }
    }
    return {
      accordions,
      bindAccordion,
      toggleOpen,
    }
  },
}
