//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  setup(props, { attrs, emit }) {
    function onClick() {
      emit('click')
    }

    return {
      attrs,
      onClick,
    }
  },
}
