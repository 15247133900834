import ko from './ko'
import en from './en'
import ja from './ja'

const translations = {
  ko,
  en,
  ja,
}

export default translations
