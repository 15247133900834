var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ns-table"},[_c('style',{ref:"styleRef"}),_vm._v(" "),_c('div',{class:{ 'ns-table-wrap': true, auto: _vm.auto }},[(!!_vm.headers)?_c('div',{staticClass:"ns-table-head"},[_c('div',{staticClass:"ns-table-row"},_vm._l((_vm.headers),function(ref,index){
var text = ref.text;
return _c('div',{key:text + index,staticClass:"ns-table-col",style:(_vm.sizing[index] || '')},[_c('p',[_vm._v(_vm._s(text))])])}),0)]):_vm._e(),_vm._v(" "),(!!_vm.bodies)?_c('div',{staticClass:"ns-table-body",class:_vm.uid},_vm._l((_vm.bodies),function(_body,_index){return _c('div',{key:_body.toString() + _index,staticClass:"ns-table-row",class:{
          selected: _vm.rowIndex === _index,
          disabled: _vm.rowIndex === _index,
        },attrs:{"index":_index},on:{"click":_vm.changeRow}},_vm._l((_body),function(ref,index){
        var text = ref.text;
        var subtext = ref.subtext;
        var type = ref.type;
return _c('div',{key:text + index,staticClass:"ns-table-col",style:(_vm.sizing[index] || '')},[(subtext && !type)?_c('div',{staticClass:"ns-table-col-double"},[_c('p',[_vm._v(_vm._s(text))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(subtext))])]):(type === 'button')?_c('ns-button',[_vm._v(_vm._s(text))]):_c('p',[_vm._v(_vm._s(text))])],1)}),0)}),0):_c('div',{staticClass:"ns-table-body",class:_vm.uid},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }