export default {
  test: 'test',
  nest: {
    test: 'nested test',
  },

  SYSVARS: {
    inquiryEmail: 'manager@neuralworks.io',
    productName: 'NeuralWorks',
    studioName: 'Neural Studio',
  },

  menu: {
    dataset: 'Dataset',
    handling: 'Data Handling',
    EDA: 'EDA',
    model: 'Model',
    training: 'Training',
    result: 'Result',
    comparison: 'Comparison',
    deployment: 'Deployment',
    annotation: 'Annotation',
    GPU: 'GPU Server',
  },

  common: {
    email: 'Email',
    password: 'Password',
    login: 'Sign In',
    signup: 'Sign Up',
    logout: 'Sign Out',
    register: 'Sign Up',
    search: 'Search',
    search_placeholder: 'Enter search keyword',
    search_result: 'Search Result',
    search_result_none: 'No search result',
  },

  page: {
    dataset_list: 'Dataset List',
    dataset_detail: 'Dataset Detail',
    raw_data: 'Raw Data',
    training_data: 'Training Data',
    EDA: 'Exploratory Data Analysis (EDA)',

    model_list: 'Model List',
    model_detail: 'Model Detail',
    model_category: 'Model Category',
    model_name: 'Model Name',
    model_type: 'Model Type',
    library: 'Library',
    detail: 'Detail',
    selection: 'Selection',

    image: 'Image',
    image_classification: 'Image Classification',
    object_detection: 'Object Detection',
    object_segmentation: 'Object Segmentation',
    semantic_segmentation: 'Semantic Segmentation',

    training_list: 'Training List',
    data_analisys: 'Data Analisys',
    training_detail: 'Training Detail',
    regression: 'Regression',
    classificatoin: 'Classification',
    clustering: 'Clustering',
    timeseries_analysis: 'Time Series Analysis',
    anomaly_detection: 'Anomaly Detection',

    result: 'Result',
    waiting: 'Waiting',
    on_training: 'On Training',
    done: 'Done',

    recent: 'Recent',

    training_name: 'Training Name',
    status: 'Status',
    training_time: 'Training Time',
    compare_results_of_training: 'Comparing Results of Training',

    model_deployment: 'Model Deployment',

    GPU: 'GPU Server',
  },

  mesg: {
    // 각 페이지별로
    annotation_list: {
      title_description1: 'There are a total of ',
      title_description2:
        ' annotations. Click on the annotation name to view detailed information.',
      ing: 'In Progress',
      finished: 'Completed',
      new_anno: 'New Annotation',
      search_msg: 'Enter job name or description',
      no_name: 'No Name',
      no_data: 'No Dataset Available',
      no_search: 'Search content not found',
      no_job: 'No Job Available',
      add_anno: 'Create Annotation',
      select_data: 'Select Data',
      detail_option: 'Detailed Settings',
      label_set: 'Set Label',
      no_image_data: 'No Image Dataset Available',
      name: 'Name',
      anno_placeholder: 'Enter the name of the annotation',
      explanation: 'Description',
      explanation_placeholder: 'Enter a description for the annotation',
      label_list: 'List of Trained Labels',
      label_name: 'Label Name',
      label_placeholder: 'e.g., Dog, Cat',
      label_tip: 'Please enter the name of the annotation label',
      add: 'Add',
      color_short_key: 'Color/Shortcut Key',
      label_name2: 'Label Name',
      anno_new_mesg: 'Annotation Created',
      anno_new_fail_mesg: 'Failed to Create Annotation',
      loading: 'Loading',
      anno_desc:
        'Review the dataset and start annotating by clicking the annotation button',
      label: 'Label',
      label_add: 'Add Label',
      worker_manage: 'Worker Management',
      kind: 'Type',
      general_worker: 'General Worker',
      number: 'Number',
      nummber_unit: 'People',
      work_num_placeholder: 'Enter job number',
      work_num: 'Job Number',
      worker: 'Worker',
      prover: 'Verifier',
      work_ratio: 'Work Progress',
      modify_time: 'Last Modified',
      annotation: 'Annotation',
      no_work: 'No Work Available',
      auto_anno: 'Auto Annotation',
      input_name: 'Enter Name',
      confirm: 'Confirm',
      kind_anno: 'Annotation Type',
      work_load_unit: 'Annotation Work Assignment Unit',
      num_image: 'Number of Images',
      auto_anno_model: 'Auto Annotation Model',
      auto_anno_check: 'Use Auto Annotation',
      model_list: 'Model List',
      input_label: 'Enter Label',
      new_label: 'New Label',
      user_set: 'User Defined',
      original_label: 'Original Label',
      auto_anno_name: 'Auto Annotation',
      dataset: 'Dataset',
      size: 'Size',
      learning_model: 'Training Model',
      learning_data: 'Training Data',
      number2: 'Count',
      category: 'Category',
      bounding_box: 'Bounding Box',
      polygon: 'Polygon',
      label_error_msg: 'Label Already Exists',
      number3: 'Items',
      data_ing: 'Annotating the "Dataset" dataset',
      store: 'Save',
      submit: 'Submit',
    },

    signin: {
      title: 'Login',
      page_guide: 'Welcome!',
      service_guide: 'NeuralWorks Studio Beta Service.',
      new_service_guide: 'No-code AI NeuralStudio!',
      start_button: 'Get Started',
      request_to_use: '(Inquiries to manager@neuralworks.io)',
      wrong_email: 'Invalid Email Address.',
      wrong_password: 'Invalid Password.',
      send_code: 'Receive Verification Code',
      enter_code_sent: 'Enter the Verification Code',
      verify_code: 'Verify Code',
      forgot_password: 'Find Password',
      reset_password: 'Reset Password',
      reset_password_success: 'Password Reset Complete',
      reset_password_fail: 'Password Reset Failed',
      reset_password_email_sent: 'Password Reset Email Sent',
      forgot_password2: 'Forgot Your Password?',
      authcheck_finished: 'Authentication Complete',
      recv_code: 'Receive Code',
      code: 'Verification Code',
      ask_code: 'Please enter the code sent to your email.',
      auth_check: 'Authenticate',
      pw_info: 'Alphanumeric Combination',
      pw_info2: 'At least 6 characters',
      agree_info:
        'I agree to the collection and use of personal information and the NeuralWorks Terms of Service.',
      view_agree_info: 'View Terms',
      start_info: 'Start NeuralWorks Studio',
      new_password: 'New Password',
      change_pw: 'Change Password',
      login_fail: 'Login Failed.',
      login_session_expire: 'Login Session Expired.',
      signup_success: 'Signup Successful.',
      signup_failed: 'Signup Failed.',
      change_pw_success: 'Password Change Successful.',
      change_pw_failed: 'Password Change Failed.',
      invalid_referral_code: 'Invalid Referral Code.',
      kakao_login_success: 'Logged in with Kakao.',
      kakao_login_failed: 'Kakao Login Failed.',
    },

    dataset_list: {
      page_guide: 'Load data and apply transformations.',
    },
    dataset_detail: {
      page_guide: 'Basic information on the dataset.',
    },

    datasets: {
      title: 'Dataset List',
      page_info: 'Upload and process datasets to start training.',
      upload_data: 'Upload Dataset',
      upload_from_server: 'Fetch from Server',
      search_placeholder: 'Enter dataset name or description.',
      view_only_mydata: 'View Only My Datasets',
      no_name: 'No Name',
      no_info: 'No Information',
      do_learning: 'Start Training',
      origin: 'Origin',
      image_classification: 'Image Classification',
      detection: 'Object Detection',
      segmentation: 'Object Segmentation',
      image: 'Image',
      text: 'Text',
      general: 'General',
      data_analysis: 'Data Analysis',
      text_analysis: 'Text Analysis',
      wordcloud: 'Word Cloud',
      tagger: 'Tagger',
      word2vec: 'Word2Vec',
      title2: 'Filestore',
      raw_data: 'Raw Data',
      data_summary: 'Summary',
      dataset: 'Dataset',
      folder: 'Folder',
      file: 'File',
      unit: 'Units',
      is_loading: 'Loading...',
      no_file_loading: 'Unable to Load File.',
      is_loading2: 'Loading',
      no_data_name: 'No Dataset Name.',
      not_find_data: 'Cannot Find Dataset.',
      not_find_dataset_files: 'Cannot Find Dataset files.',
      page_info2: 'Check the summary information of the data.',
      view_file: 'View File',
      detailed_analysis: 'Detailed Analysis',
      download: 'Download',
      download_failed: 'Download Failed.',
      convert: 'Convert',
      annotation: 'Annotation',
      view_preprocess: 'Visualize/Preprocess',
      data: 'Data',
      no_data: 'No Data Available.',
      basic_stat: 'Basic Statistics',
      relation_var: 'Correlation Coefficients',
      remove_data: 'Delete Data',
      modify_data: 'Edit Dataset Information',
      dataset_name: 'Dataset Name',
      explain: 'Description',
      server_path: 'Server Path',
      server_path_input: 'Enter Path.',
      explain_title: 'Enter Name.',
      explain_placeholder:
        'Leave a brief memo describing this dataset. You can export without entering.',
      change: 'Change',
      preprocess_info: 'Preprocessing Details',
      no_preprocess_info: 'No Preprocessing Details.',
      no_data_name2: 'No Data Name.',
      page_info3: 'Preprocess data to create training data.',
      send_data: 'Export as Training Data',
      pre_process: 'Preprocess',
      summary: 'Summary',
      add: 'Add',
      cancel: 'Cancel',
      modify: 'Edit',
      delete: 'Delete',
      done: 'Done',
      add_btn_info: 'Click the Add Button.',
      select_preprocess: 'Select Preprocessing.',
      select_var: '1. Select Variable',
      select_all: 'Select All',
      unselect_all: 'Unselect All',
      select_process_type: 'Select Processing Method',
      filter_condition_set: 'Set Filter Condition',
      select_method: 'Select Outlier Detection Method',
      select_result: '3. Choose Outlier Processing Method',
      condition: 'Condition',
      condition_info: 'No condition set. Set conditions below.',
      select_left_list: 'Select a variable from the left list.',
      operator_placeholder: 'Operator',
      value_placeholder: 'Value',
      pre_value: 'Original Value',
      next_value: 'Replacement Value',
      no_input_placeholder: 'Enter nothing to replace with missing value',
      missing_value: 'Missing Value',
      preview: 'Preview',
      no_relation_var: 'No Correlation Coefficient Data.',
      select_preprocess2: 'Select from Preprocessing List.',
      set_data_name: 'Set the Name for the Training Data.',
      send: 'Export',
      category_var_convert: 'Categorical Variable Conversion',
      scaling: 'Scaling',
      missing_value_process: 'Missing Value Processing',
      missing_value_delete: 'Delete Missing Value (Row Deletion)',
      missing_value_set: 'Specify Missing Value',
      set_value: 'Set Value',
      mean: 'Mean',
      median: 'Median',
      mode: 'Mode',
      minimum: 'Minimum',
      maximum: 'Maximum',
      direct_input: 'Direct Input',
      outlier: 'Outlier',
      outlier_handling: 'Outlier Handling',
      outlier_add: 'Mark Outliers (Add New Column)',
      outlier_remove: 'Remove Outliers (Delete Data Row)',
      filtering: 'Filtering',
      var_change: 'Value Replacement',
      unit_var_input: 'Single Value Input',
      range_var_input: 'Range Input',
      no_basic_stat: 'No Basic Statistics.',
      ask_info: 'Are you sure you want to exit without saving?',
      exit_info: 'Exit',
      continue_modify: 'Continue Editing',
      yes_info: "Yes, I don't want to",
      ask_info2: 'Are you sure you want to exit without making changes?',
      del_info:
        'Deleting this will remove all subsequent actions and cannot be undone.\nDo you want to delete?',
      del_yes_info: 'Yes, I will delete it',
      modify_info:
        'Modifying this will remove all subsequent actions and cannot be undone.\nDo you want to modify?',
      modify_yes_info: 'Yes, I will modify it',
      select_op_value: 'Select Operator or Value',
      proc_only_for_str:
        'This preprocessing is best applied to string types only.',
      proc_only_for_number:
        'This preprocessing is best applied to numeric types only.',
      add_value_replace: 'Add Value Replacement',
      select_var_info: 'Select a Variable',
      upload_success: 'Successfully requested preprocessing.',
      upload_fail: 'Failed to upload.',
      before_process: 'Before Processing',
      after_process: 'After Processing',
      row: 'Row',
      column: 'Column',
      convert_result: 'Conversion Result',
      outlier_number: 'Number of Outliers',
      pre_min: 'Original Minimum',
      pre_max: 'Original Maximum',
      new_min: 'New Minimum',
      new_max: 'New Maximum',
      mean2: 'Mean',
      std: 'Standard Deviation',
      preview_fail: 'Preview Failed',
      yes: 'Yes',
      no: 'No',
      select_add_btn: 'Click the Add Button',
      select_pre_type: 'Select Preprocessing Type',
      add2: 'Add Step',
      do_preprocess: 'Preprocess',
      quartile1: '1st Quartile (25%)',
      quartile3: '3rd Quartile (75%)',
      remove_msg_1: 'This', // meaning of 'this'
      remove_msg_2:
        'Deleting this data cannot be undone.\nDo you want to delete?',
      remove_msg_3: 'The data will not be deleted.',
    },

    EDA: {
      page_guide:
        'Check basic statistics and graphs to gain insights into your data.',
      title: 'Exploratory Data Analysis',
      page_info: 'Select a variable to view basic statistics and graphs.',
      data: 'Dataset',
      data_placeholder: 'Select a dataset.',
      x_var: 'X-axis Variable',
      y_var: 'Y-axis Variable',
      y_value: 'Y-axis Value',
      legend_vars: 'Color Variable',
      // ask_info: 'Select data and variables.',
      m1: 'Frequency',
      m2: 'Total',
      m3: 'Average',
      m4: 'Distribution',
      m5: 'Bar',
      m6: 'Line',
      m7: 'Dot',
      m8: 'Pie',
      m9: 'Box',
      m10: 'Bar Graph',
      msg1: 'Dataset not found.',
      raw: 'Raw',
      learn: 'Learn',
    },

    model_list: {
      page_guide: 'Try out the latest and greatest models.',
      title: 'Model List',
      page_info:
        'Explore the latest SOTA (State of the Art) models provided by NeuralWorks.',
      model_type: 'Model Classification',
      model_small_type: 'Subcategory',
      add_model: 'Add Model',
      search_placeholder: 'Enter model name or description.',
      do_learning: 'Start Training',
      no_search: 'No search results.',
      prepare_model: 'Preparing model.',
      is_loading: 'Loading models',
      model_type2: 'Model Type',
      publish_time: 'Publication Date',
      bench_mark: 'Benchmark Performance',
      analysis_method: 'Analysis Technique',
      keyword: 'Keyword',
      more_view: 'Learn More',
      public_document: 'Official Documentation',
      wiki: 'Wikipedia',
      no_model: 'No model description available.',
      paper: 'Paper',
      no_paper: 'No paper available.',
      no_model_info: 'Model not found.',
    },

    model_detail: {
      page_guide: '',
    },
    model_category: {
      page_guide: '',
    },

    profile: {
      title: 'My Page',
      page_info: 'You can edit your personal profile.',
      change_picture: 'Change Picture',
      email: 'Email',
      name: 'Name',
      password: 'Password',
      change_name: 'Change Name',
      change_password: 'Change Password',
      enter_password: 'Enter Password',
      reenter_password: 'Re-enter Password',
      change_picture_ok: 'Profile image changed successfully.',
      change_picture_fail: 'Failed to change profile image',
      enter_name: 'Enter Name',
      profile: 'Profile',
      pricing_plan: 'Pricing Plan',
      my_pricing_plan: 'My Pricing Plan',
      pricing: 'Pricing',
      payment_history: 'Payment History',
      payment_request: 'Payment Request',
      payment_fail: 'Payment Failed',
      payment_complete: 'Payment Complete',
      payment_ready: 'Payment Ready',
      point_history_created_at: 'Date',
      point_info: 'Point History',
      point_history: 'Point Information',
      point: 'Point',
      point_desc: 'Point',
      no_point_history: 'No Point Record',
      refund_request: 'Refund Request',
      refund: 'Refund',
      service_usage_information: 'Service Usage Information',
      recommendation_code: 'Referral Code',
      recommendation_info: 'Referral Information',
      recommendation_url: 'Sign Up URL',
      recommendation_result: 'Referral Performance',
      usage_period: 'Usage Period',
      next_payment: 'Next Payment',
      unlimited: 'Unlimited',
      free: 'Free',
      none: 'None',
      general_payment: 'General Payment',
      regular_payment: 'Recurring Payment',
      month: 'Month(s)',
      not_found_user_information: 'Unable to load user information.',
      change: 'Change',
      no_payment_history: 'No Payment History',
      plan: 'Plan',
      payment_type: 'Payment Type',
      period: 'Period',
      state: 'Status',
      created_date: 'Created Date',
      won: 'Won',
    },

    handling: {
      title: 'Data Handling',
      page_info: 'Please select the task to perform',
      table_data: 'Table Data',
      eda: 'Exploratory Data Analysis (EDA)',
      eda_info:
        'This is the process of looking at data intuitively through graphs or statistical figures before analysis.',
      data_preprocess: 'Data Preprocessing',
      data_preprocess_info:
        'The process of manipulating data into a format suitable for analysis.',
      data_concat: 'Data Merge',
      data_concat_info: 'You can merge with other datasets.',
      title2: 'Data Merging',
      page_info2: 'You can merge multiple data sets.',
      data_concat2: 'Data Combination',
      vertical: 'Vertical',
      horizontal: 'Horizontal',
      original_view: 'Original View',
      preview: 'Preview',
      data_store: 'Data Storage',
      data_row: 'Data (Row)',
      var_column: 'Variable (Column)',
      table_close: 'Collapse Table',
      table_open: 'Expand',
      no_image_data: 'No Image Dataset Available.',
      data_concat_ing: 'Merging Data.',
      data_concat3: 'Merge Data',
      dataset_name: 'Dataset Name',
      dataset_name_placeholder: 'Enter Dataset Name.',
      explain: 'Description',
      explain_placeholder:
        'Leave a brief memo describing this dataset. You can upload even without entering.',
      upload: 'Upload',
      nan_value: 'Missing',
      select_dataset: 'Select Dataset for Preprocessing',
    },

    workspace: {
      title: 'Workspace',
      page_info: 'Manage and edit your workspace.',
      title2: 'Workspace Management',
      add_workspace: 'Create Workspace',
      member_number: 'Participants: 20',
      manage: 'Manage',
      create_info: 'Creating a workspace.',
      workspace_modal_title:
        'Please enter a name and description for the workspace',
      cover_set: 'Cover Settings',
      name: 'Name',
      name_placeholder: 'Enter the name of the workspace.',
      explain: 'Description',
      explain_placeholder: 'Enter a description for the workspace.',
      create: 'Create',
      create_info2: 'Workspace has been created.',
      create_fail: 'Failed to create workspace.',
      page_info2: 'Manage basic information and team members of the workspace.',
      cover_change: 'Change Cover',
      delete: 'Delete',
      raw_data: 'Raw Data',
      dataset: 'Training Data',
      annotation: 'Annotation',
      learn: 'Learn',
      zero: '0 Items',
      member_manage: 'Team Member Management',
      member_invite: 'Invite Team Member',
      total_member: 'Total Members',
      manager: 'Manager',
      normal: 'Regular',
      waiting: 'Waiting',
      unit: 'Members',
      unit2: 'Items',
      confirm_info: 'Do you want to delete these members?',
      yes: 'Yes, delete them',
      enter_email: 'Enter the email of the user to invite to the workspace.',
      enter_email_small:
        'If they are not a member, an account will be automatically created.',
      enter_info: 'Click finish to send an invitation email.',
      email: 'Email',
      invite: 'Invite',
      add: 'Add',
      remove: 'Remove',
      done: 'Done',
      normal_user: 'Regular User',
      owner: 'Owner',
      old: 'Existing',
      new: 'New',
      email_domain: 'Email Domain',
      default_password: 'Default Password',
      enter_description: 'Enter a description',
      enter_email_domain: 'Enter the email domain',
      enter_password: 'Enter a password',
      password: 'Password',
      email_domain_tip: 'Separate email domains with a comma.',
      password_condition:
        'Enter at least 6 characters combining letters and numbers.',
      change: 'Change',
      delete_workspace: 'Do you want to delete this workspace?',
    },

    invite: {
      msg1: 'You are not an invited user.',
      to_main: 'To Main',
      member_size: 'Participants',
      member_unit: 'Members',
      apply: 'Join',
      no_workspace: 'Workspace not found.',
      not_valid_token: 'Invalid token.',
      workspace_error1: 'You are already a participant in this workspace.',
      workspace_error2: 'Workspace not found.',
      apply_workspace: 'You have joined the workspace.',
      not_apply_workspace: 'Unable to join the workspace.',
    },

    instruction: {
      title: 'Select Analysis & Training Type',
      page_info: 'Please choose the type of problem for analysis and training',
      image: 'Image Analysis',
      image_classification: 'Image Classification',
      image_classification_info: 'Classify images into categories.',
      detection: 'Object Detection',
      detection_info: 'Detect and classify objects in images.',
      segmentation: 'Object Segmentation',
      segmentation_info: 'Segment objects in images at the pixel level.',
      data_analysis: 'Data Analysis',
      regression: 'Regression Analysis',
      regression_info: 'Model the relationship between variables.',
      classification_analysis: 'Classification Analysis',
      classification_analysis_info:
        'Analyze multiple variables to classify them into predefined categories.',
      clustering_analysis: 'Clustering Analysis',
      clustering_analysis_info:
        'Group similar items together when no predefined categories exist.',
      timeseries_analysis: 'Time Series Analysis',
      timeseries_analysis_info: 'Analyze time data to predict the future.',
      anomaly_detection: 'Anomaly Detection',
      anomaly_detection_info: 'Detect abnormal values in data.',
      page_info2:
        'You can start training by selecting datasets, variables, and models.',
      dataset: 'Dataset',
      rechoice: 'Choose Again',
      search_placeholder: 'Enter dataset name or description.',
      no_info: 'No Information',
      model: 'Model',
      search_placeholder_model: 'Enter model name or description.',
      start_learning: 'Start Training',
      start_learning_info: 'Beginning the training process.',
      view_learning_list: 'View Training List',
      redirect_to_learning_page: 'Redirect to job page',
      learning_ing_info: 'Creating a training session!',
      gpu_check_ing_info: 'Checking GPU...',
      delay_info: 'This may take about 10 seconds.',
      add_model: 'Add Model',
      learning_name: 'Training Name',
      ask_learning_name: 'Please enter a name for the training.',
      explain: 'Description',
      explain_placeholder: 'Leave a brief note describing this training.',
      inform_learning_done:
        'Receive an email notification when training is complete',
      split_dataset: 'Split Dataset for Training/Test',
      random_seed: 'Fix the Random Seed',
      start_learning2: 'Start Training',
      confirm_msg1:
        'Once a model is deleted, it cannot be undone.\nDo you want to delete it?',
      yes: 'Yes',
      data_model_select_info: 'Please select a dataset or model.',
      error_not_valid_input_string: 'String type cannot be an input variable',
      error_not_valid_output_string: 'String type cannot be an output variable',
      error_only_int_for_decision:
        'Only integers are allowed for output variables in classification analysis.',
      error_nan_value_exists: 'There are variables with missing values.',
      text: 'Text Analysis',
      normal: 'General Analysis',
      wordcloud: 'Word Cloud',
      wordcloud_info: 'Word Cloud',
      tagger: 'Morphological Analysis',
      tagger_info: 'Morphological Analysis',
      word2vec: 'Word2Vec',
      word2vec_info: 'Word2Vec',
      sentiment: 'Sentiment Analysis',
      association: 'Association Analysis',
      association_info: 'Association Analysis',
    },

    image: {
      page_guide: 'Image',
    },
    image_classification: {
      page_guide: 'Recognize various classes of images.',
    },
    object_detection: {
      page_guide:
        'Computer vision techniques for locating instances of objects.',
    },
    object_segmentation: {
      page_guide: 'Dividing an image into multiple segments.',
    },

    semantic_segmentation: {
      page_guide: 'Perform the semantic segmentation.',
    },

    training_list: {
      page_guide: 'Select the machine learning task type.',
    },
    training_detail: {
      page_guide: 'Select the machine learning task type.',
    },

    data_analisys: {
      page_guide: 'Use Machine learning to analyzes data.',
    },
    regression: {
      page_guide: 'Predict values within a continuous range.',
    },
    classificatoin: {
      page_guide:
        'Identify the category of new observations on the basis of training data.',
    },
    clustering: {
      page_guide:
        'Dividing the population or data points into a number of groups.',
    },

    timeseries_analysis: {
      page_guide: 'ML Models For Time-Series Forecasting',
    },
    anomaly_detection: {
      page_guide: 'Identify rare items, events, or observations.',
    },

    result: {
      cannot_get_link: 'Cannot retrieve the link',
      page_guide:
        'This is a completed training model. Click on the model name to view detailed information.',
      title: 'Model Training Results',
      d1: 'There are a total of ',
      d2:
        'trained models. Click on the model name to view detailed information.',
      waiting: 'Waiting',
      running: 'In Progress',
      done: 'Completed',
      search_placeholder: 'Enter training name or description',
      no_name: 'No training name',
      done2: 'Finished',
      go: 'Go',
      deploy: 'Deploy',
      no_search: 'No search results found',
      no_waiting: 'No trainings are waiting',
      no_running: 'No trainings in progress',
      no_done: 'No completed trainings',
      no_learning: 'No trainings',
      no_name2: 'No name',
      is_loading: 'Loading',
      no_loading: 'Cannot load trainings',
      graph: 'Training Graph',
      result: 'Result Table',
      test_set_result: 'Test Set Results',
      save_resultcsv: 'Save Prediction',
      test_graph: 'Test Graph',
      no_graph: 'Cannot generate the graph',
      test_graph2: 'Test Graph (Sorted)',
      test_graph3: 'Training · Test Graph',
      group_graph: 'Cluster Silhouette Graph',
      elbow_chart: 'Elbow Chart',
      var_value_graph: 'Variable Importance Graph',
      not_finished_learning: 'The training is not yet completed',
      delete_learning: 'Delete Training',
      no_image_loading: 'Cannot load the image',
      learning_result: 'Training Result',
      model_test: 'Model Test',
      confirm_text:
        'Once deleted, the training cannot be recovered.\nDo you want to delete the training?',
      confirm_yes: 'Yes, delete it',
      model_deploy: 'Deploy Model',
      model_deploy_title:
        'Training completed successfully! Test the model performance and deploy it for use.',
      go_deploy: 'Go to Deployment',
      deploy_title2: 'Training hard! Check the real-time training status.',
      prediction_value: 'Prediction Value',
      no_dataset_loading: 'Cannot load the dataset',
      log_error: 'Cannot retrieve the log',
      delete_learning_info: 'Training has been deleted',
      delete_learning_error: 'Failed to delete the training',
      move_to_deploy: 'Move to Deployment',
      regression_detail_result: 'Detailed Regression Analysis Results',
      classification_detail_result: 'Detailed Classification Analysis Results',
      error_log: 'Error Log',
      show_my_result: 'Show Only My Results',
    },

    compare_results_of_training: {
      page_guide:
        'Select the data and model type used in training to compare performance.',
      title: 'Compare Model Training Results',
      page_info:
        'By selecting the data and model type used in training, you can compare performances.',
      learning_data: 'Training Data',
      data_placeholder: 'Select the data used for training.',
      model_type: 'Training Model Type',
      select_type: 'Select Type',
      view: 'View',
      data: 'Data (Row)',
      error_info: 'An error occurred during retrieval.',
      retry_info: 'Please try again.',
      error_info2: 'No results found.',
      retry_info2: 'Try resetting the training date.',
      main_info_p: 'Primary Performance Indicator',
      select_info_p: 'Select Indicator',
      result_var: 'Output Variable',
      result_var_placeholder: 'You can select a specific output variable.',
      used_var: 'Variables Used in Training',
      basic_option: 'Basic Options',
      high_option: 'Advanced Options',
      input_var: 'Input Variables',
      time_var: 'Date Variables',
      data_view: 'View Data',
      regression: 'Regression Analysis',
      clustering: 'Cluster Analysis',
      classification: 'Classification Analysis',
      timeseries: 'Time Series Analysis',
      anomaly: 'Anomaly Detection',
      msg1: 'Please select a dataset',
      msg2: 'Please select a model type',
      msg3: 'Please select a date',
      msg4: 'The dataset is not valid.',
      msg5: 'The training type is not valid.',
      no_name_learning: 'Unnamed Training',
      learning_name: 'Training Name',
      model: 'Model',
      besides: 'and', // Used when mentioning 'n' more items
      count: 'more', // Used in conjunction with the above
    },

    model_deployment: {
      page_guide:
        'These are the currently deployed models. Click on the model name to view detailed information.',
      title: 'Model Deployment',
      info1: 'There are ',
      info2:
        'deployed models. Click on the model name to view detailed information.',
      btn_deploy: 'Deploy Another Model',
      search_placeholder: 'Enter model name or description.',
      active: 'Active',
      no_active: 'Inactive',
      deploy_ing: 'Deploying',
      no_deploy_msg: 'There are no models currently being deployed.',
      is_loading: 'Loading',
      page_info: 'Perform inference tasks or copy and use the API',
      do_active: 'Activate',
      model_detail: 'Model Details',
      log: 'Inference Log',
      state: 'Status',
      learning_name: 'Training Name',
      type: 'Type',
      dataset: 'Training Dataset',
      model: 'Training Model',
      deploy_time: 'Deployment Date',
      copy: 'Copy',
      is_loading2: 'Loading',
      inference: 'Inference',
      del_deploy: 'Delete Deployment',
      classification: 'Image Classification',
      detection: 'Object Detection',
      segmentation: 'Object Segmentation',
      msg1: 'Copy successful.',
      msg2: 'Copy failed.',
      msg3: 'Deployment deleted.',
      msg4: 'Failed to delete.',
      confirm_delete: 'Do you want to delete this deployment?',
      yes: 'Yes',
      non_deployable_job: 'Training not deployable.',
      already_deployed_job: 'Training already being deployed.',
      move_to_app: 'Go to App',
      edit_app: 'Edit App',
      publish_app: 'Publish App',
      not_published: 'Not Deployed',
    },

    gpu: {
      page_guide: 'You can check the status of the GPU servers.',
      title: 'Server Resources',
      page_info: 'Check the status of the server.',
      gpu_info: 'Loading the current GPU server status.',
      memory_info: 'Available Memory',
      cpu_info: 'CPU Usage',
      memory_usage: 'Memory Usage',
      gpu_memory_info: 'GPU Available Memory',
      gpu_usage_ratio: 'GPU Usage Ratio',
      update_info: 'Update Information',
    },

    membership: {
      payment_step: 'Purchase Steps',
      my_order: 'My Order',
      check_product: 'Check the selected product.',
      general_payment: 'One-Time Payment',
      regular_payment: 'Recurring Payment',
      monthly_payment: 'Monthly Payment',
      regular: 'Regular',
      month: 'Month(s)',
      won: 'Won',
      paying: 'Purchase',
      payment_info_save_fail: 'Failed to save payment information',
      succeed_payment: 'Payment successful',
      failed_payment: 'Payment failed',
      neural_plan: 'Neural Studio Plan',
      payment_history: 'Payment History',
      history_description: 'View your payment request history',
      payment_request: 'Payment Request',
      payment_complete: 'Payment Complete',
      payment_fail: 'Payment Failed',
      refund_request: 'Refund Request',
      refund: 'Refund',
      payment_type: 'Payment Type',
      period: 'Period',
      state: 'Status',
      created_date: 'Creation Date',
      membership: 'Membership',
      membership_description: 'Select the pricing plan you want to change',
      current_pricing: 'Current Pricing',
      regular_subscription: 'Regular Subscription',
      one_month: '1 Month',
      three_months: '3 Months',
      six_months: '6 Months',
      twelve_months: '12 Months',
      max_csv_row_count: 'Max CSV Row Count',
      max_csv_column_count: 'Max CSV Column Count',
      create_workspace: 'Create Workspace',
      create_dataset_inJoined_workspace: 'Upload Dataset in Other Workspaces',
      create_job_inJoined_workspace: 'Create Training in Joined Workspaces',
      max_workspace_member_count: 'Max Team Members in Workspace',
      max_workspace_count: 'Max Workspaces that can be Created',
      max_workspace_dataset_count:
        'Max Datasets that can be Uploaded to Workspace',
      max_workspace_job_count: 'Max Trainings that can be Created in Workspace',
      max_workspace_inference_count:
        'Max Inferences that can be Created in Workspace',
      price: 'Price',
      free: 'Free',
      select: 'Select',
    },

    components: {
      class_list: 'Class List',
      guide_box: 'Guide Box',
      image_move: 'Move Image',
      bounding_box: 'Bounding Box',
      poly_box: 'Polygon Box',
      pretrained_info: 'Pre-trained Information',
      learning_data: 'Training Data',
      model: 'Model',
      view_per_class: 'View by Class',
      label: 'Label',
      kind: 'Type',
      learning_type: 'Training Type',
      select_learning_type: 'Select Training Type',
      format: 'Format',
      learning_data_name: 'Training Dataset Name',
      explain: 'Description',
      explain_placeholder2: 'Leave a brief description for this dataset.',
      explain_placeholder:
        'Leave a brief note explaining the dataset. You can convert without entering this.',
      convert: 'Convert',
      start_convert: 'Starting conversion!',
      convert_info: 'You can check in the training data list.',
      no_convert: 'This file cannot be converted.',
      no_convert_info:
        'Please check the file format and annotation status and try again.',
      retry: 'Retry',
      select_anno_method: 'Please select an annotation method.',
      file_convert: 'Convert File',
      use_neuralworks: 'Use NeuralWorks Tool',
      prepare_ing: '(Preparing)',
      vanish_info: 'This window will close in 3 seconds.',
      data_name: 'Dataset Name',
      done: 'Done',
      select_format: 'Select format and upload files.',
      drag_info: 'Drag and drop annotation zip file here.',
      find_file: 'Find File',
      camera: 'Camera',
      check_before_upload: 'Check before uploading',
      raw_data_anno: 'All images in raw data are annotated.',
      raw_data_name: 'Each file has the same name as the raw data file.',
      class_zip: 'There is a "class.txt" file listing labels in the zip file.',
      anno_data_name: 'Annotation Dataset Name',
      image_classification: 'Image Classification',
      detection: 'Object Detection',
      segmentation: 'Object Segmentation',
      step1: 'Step 1. Select the type of dataset',
      from_server_title: 'Import from Server',
      from_server: 'Import',
      image: 'Image',
      video: 'Video',
      general: 'General',
      lang: 'Natural Language',
      voice: 'Voice',
      step2: 'Step 2. Upload a zip file (drag & drop)',
      zip_upload: 'Upload a zip file',
      data_accept_types_csv: 'Step 2. Upload a csv file: (drag & drop)',
      data_accept_types_image: 'Acceptable files: png, jpg, jpeg',
      data_accept_types_text: 'Step 2. Upload a txt file: (drag & drop)',
      image_upload_check: 'Image Upload Caution',
      upload: 'Upload',
      upload_start: 'Starting upload!',
      upload_status: 'You can check the progress at the top of the screen.',
      data_upload_start: 'Dataset Upload Start',
      confirm: 'Confirm',
      start_deploy: 'Deploy model for inference',
      learning_name: 'Training Name',
      learning_data2: 'Training Dataset',
      learning_model: 'Training Model',
      api_address: 'API Address',
      name_tip: 'Enter only English letters and numbers',
      confirm2: 'Duplicate Check',
      do_deploy: 'Deploy',
      deploy_cancel_info: 'Cancellation of deployment cannot be undone.',
      cancel_ask: 'Do you want to cancel?',
      cancel_no: 'No, continue',
      cancel_yes: 'Yes, cancel',
      start_deploy_ing: 'Starting deployment!',
      no_file: 'No file found.',
      select_var: 'Select variables from the list',
      select_var_info:
        'If you select the output variable first, you can choose input variables while viewing the correlation.',
      var_list: 'Variable List',
      var_input: 'Input Variable',
      var_output: 'Output Variable',
      var_time: 'Date Variable',
      view_data: 'Go to Data',
      var_selection_done: 'Variable Selection Done',
      data_error: 'There is an error in the dataset.',
      set_model_option: 'Set options for the model.',
      basic_option: 'Basic Option (Mandatory)',
      basic_value: 'Default Value: ',
      set_value_init: 'Initialize to Default Value',
      no_basic_option: 'No basic options available.',
      high_option: 'Advanced Option (Optional)',
      no_high_option: 'No advanced options available.',
      set_done: 'Setting Complete',
      set_select_model_option: 'Select a model and set options',
      page_out_info: 'Leaving the page will not save your content.',
      exit_info: 'Do you want to exit?',
      no: 'No',
      yes: 'Yes, I will exit',
      original: 'Original',
      apply_preprocess: 'Apply Preprocessing',
      save_resultcsv: 'Save Prediction',
      test_file: 'Test with Another File',
      performance: 'Performance',
      no_performance: 'No performance information available.',
      inference: 'Perform Inference',
      error_for_infer: 'There was a problem during inference.',
      retry_info: 'Please try again with another file after a while.',
      retry_info2: 'Please try again with another image after a while.',
      drag_file: 'Drag and drop the file here!',
      can_use_file_info: 'Acceptable file: csv (up to 1)',
      can_use_file_info2: 'Acceptable files: png, jpg (up to 10)',
      contain_all_input_var:
        'Contains all input variables used in model training.',
      same_info:
        'The name and format of input variables are the same as in the source data.',
      no_valid_column: 'No valid columns.',
      short_valid_column: 'Insufficient valid columns.',
      infer_value: 'Predicted Value',
      no_server_response: 'No response from the server.',
      cannot_request: 'Cannot send request to the server.',
      basic_info: 'Basic Information',
      test_p: 'Test Set Performance',
      delete: 'Delete',
      add: 'Add',
      deployed_model: 'This training is already deployed.',
      test_image: 'Test with Another Image',
      infer_result: 'Inference Result',
      no_detection: 'No Objects Detected',
      total: 'Total',
      detail_result: 'Detailed Result',
      error: 'Error',
      detected_object: 'Detected Object',
      data: 'Data',
      row: 'Data (Row)',
      column: 'Variable (Column)',
      class: 'Class',
      size: 'Size',
      used_var: 'Variables Used in Training',
      input_var: 'Input Variable',
      result_var: 'Output Variable',
      indentifier_var: 'Identifier Variable',
      only_one_var_allowed: 'Only one is allowed.',
      time_var: 'Date Variable',
      data_option3: 'Data Options',
      flip: 'Flip Horizontally, Flip Vertically',
      rotate: 'Rotate 90 Degrees Left',
      not_find_data: 'Cannot find the dataset.',
      library: 'Library',
      method: 'Analysis Method',
      bench_mark: 'Benchmark Performance',
      tag: 'Tag',
      basic_option2: 'Basic Option',
      no_basic_option2: 'No basic options available.',
      high_option2: 'Advanced Option',
      no_high_option2: 'No advanced options available.',
      no_model: 'Cannot find the model.',
      view_data2: 'View Data',
      done2: 'Exit',
      data_model_option: 'Dataset, Model, Option Information',
      back: 'Go Back',
      no_table_header: 'Table header is empty.',
      table_empty: 'Table is empty.',
      error_alarm: 'Incorrect alert.',
      no_alarm: 'No alert content.',
      inform: 'Alert',
      all_delete: 'Delete All',
      no_message: 'No alert messages.',
      when_no_input: 'If not entered',
      use_default_value: 'Use Default Value',
      use_recommended_value: 'Auto Set',
      confirm_yes: 'Yes',
      confirm_no: 'No',
    },

    js: {
      fail_auto_anno: 'Failed auto annotation.',
      store_info: 'Saved successfully.',
      fail_store: 'Failed to save.',
      no_email_form: 'This is not a valid email format.',
      pw_rule:
        'Password must be at least 6 characters long, including letters and numbers.',
      pw_incorrect: 'Incorrect password.',
      not_our_email: 'This email address is not registered with us.',
      fail_email_send: 'Failed to send email.',
      reenter_code: 'Invalid code. Please request a new one.',
      need_email_verification: 'Email verification is required.',
      need_code_verfication: 'Code verification is required.',
      need_agreement: 'Agreement to terms is required.',
      reenter_code2: 'Invalid code. Please check your verification code.',
      expire_code: 'Verification time has expired. Please request a new code.',
      same_email: 'This email already exists.',
      raw_data: 'Raw Data',
      learning_data: 'Training Data',
      dataset: 'Dataset',
      type: 'Type',
      count: 'Count',
      modify_time: 'Last Modified',
      same_name: 'This name already exists.',
      upload_error: 'Error occurred during upload.',
      network_error: 'Network error occurred.',
      kind: 'Kind',
      size: 'Size',
      model_name: 'Model Name',
      learning_dataset: 'Training Dataset',
      learning_model: 'Training Model',
      deploy_time: 'Deployment Date',
      state: 'Status',
      image_classification: 'Image Classification',
      detection: 'Object Detection',
      segmentation: 'Object Segmentation',
      no_info: 'No Information',
      not_load_data: 'Cannot load dataset.',
      sample_count: 'Sample Count',
      model: 'Model',
      model_type: 'Model Type',
      library: 'Library',
      method: 'Analysis Method',
      detail: 'Detail',
      param_number: 'Number of Parameters',
      publish: 'Published',
      regression: 'Regression Analysis',
      cluster: 'Clustering Analysis',
      decision: 'Classification Analysis',
      timeanalysis: 'Time Series Analysis',
      anomaly: 'Anomaly Detection',
      select_data: 'Please select the data for training.',
      select_model: 'Please select the model for training.',
      set_option: 'Name the training and set options.',
      same_deploy_model: 'This model has already been deployed.',
      check_ing: 'Checking',
      recent: 'Recent',
      waiting: 'Waiting',
      ing: 'In Progress',
      done: 'Completed',
      learning_name: 'Training Name',
      performance: 'Performance',
      learn_request: 'Training Request',
      learn_start: 'Start Training',
      learn_ing_info: 'Training Progress',
      deploy: 'Deploy',
      owner: 'Owner',
      total: 'Total',
      image: 'Image',
      csv: 'Data Analysis',
      lang: 'Natural Language',
      sound: 'Voice',
      detection2: 'Object Detection',
      segmentation2: 'Object Segmentation',
      image_create: 'Image Generation',
      learning_waiting: 'Waiting for Training',
      learn_done: 'Training Completed',
      learn_fail: 'Training Failed',
      raw_dataset_init: 'Uploading raw dataset is ready',
      raw_dataset_doing: 'Uploading raw dataset on progress',
      raw_dataset_done: 'Uploading raw dataset completed',
      raw_dataset_fail: 'Uploading raw dataset failed',
      learning_dataset_init: 'Uploading learning dataset is ready',
      learning_dataset_doing: 'Uploading learning dataset on progress',
      learning_dataset_sync: 'Syncing learning dataset',
      learning_dataset_done: 'Uploading learning dataset completed',
      learning_dataset_fail: 'Uploading learning dataset failed',
      cannot_deploy: 'This training cannot be deployed.',
      already_deploy: 'This training is already being deployed.',
      general: 'General',
      association: 'Association Analysis',
    },

    helper: {
      total: 'Total',
      ing: 'In Progress',
      review_ing: 'Under Review',
      done: 'Completed',
      no_name: 'No Name',
      bounding_box: 'Bounding Box',
      polygon: 'Polygon',
      no_find_anno: 'Annotation Not Found',
      done2: 'Finished',
      job_name: 'Job Name',
      type: 'Type',
      dataset: 'Dataset',
      job_ing: 'Job Progress',
      job_create_time: 'Job Creation Date',
      data_name: 'Dataset Name',
      data_count: 'Data Count',
      size: 'Size',
      upload_date: 'Upload Date',
      modify_time: 'Last Modified',
      image_classification: 'Image Classification',
      detection: 'Object Detection',
      bool: 'Boolean',
      int: 'Integer',
      float: 'Float',
      object: 'String',
      date: 'Date',
      modify_data_info: 'Dataset information modified.',
      fail_modify_data_info: 'Failed to modify dataset information.',
      yes: 'Yes, delete it',
      explain: 'Description',
      no_explain: 'No Description',
      data_row: 'Data (Row)',
      column: 'Variable (Column)',
      preprocess: 'Preprocessing',
      view_detail: 'View Details',
      decide: 'Decide',
      remove: 'Remove',
      mean: 'Mean',
      median: 'Median',
      mode: 'Mode',
      min: 'Minimum',
      max: 'Maximum',
      direct_input: 'Direct Input',
      filtering: 'Filtering',
      replace_value: 'Replace Value',
      no_data: 'No Data Available.',
      sample_count: 'Sample Count',
      not_load_data: 'Unable to load data.',
      learning: 'Training',
      raw_data: 'Raw',
      delete_info: 'Deleted successfully.',
      delete_fail: 'Failed to delete.',
      not_know_error: 'An unknown error occurred.',
      type_error: 'The types of the two columns do not match.',
      server_error: 'The data processing server is not responding.',
      select_data: 'Please select a dataset',
      enter_name: 'Enter a name',
      request_upload: 'Dataset upload requested.',
      data_error: 'Unable to process data.',
      learning_name: 'Training Name',
      model_type: 'Model Type',
      data: 'Dataset',
      model: 'Model',
      learning_start: 'Training Start',
      learning_done: 'Training End',
      learning_time: 'Training Time',
      estimated_end_time: 'Estimated End',
      csv_classification: 'Classification Analysis',
      detection2: 'Object Detection',
      segmentation: 'Object Segmentation',
      regression: 'Regression Analysis',
      cluster: 'Clustering Analysis',
      anomaly: 'Anomaly Detection',
      time: 'Time Series Analysis',
      stat: 'Statistical Analysis',
      ML: 'Machine Learning',
      DL: 'Deep Learning',
      no_info: 'Unknown',
      manager: 'Manager',
      user: 'General User',
      owner: 'Owner',
      sort_type1: 'By Authority',
      sort_type2: 'By Addition',
      sort_type3: 'Alphabetically',
      load_ing: 'Loading',
      no_workspace: 'No Workspace Available',
      cover_change_ok: 'Cover Change Successful',
      cover_change_fail: 'Cover Change Failed',
      cover_delete_ok: 'Cover Deletion Successful',
      cover_delete_fail: 'Cover Deletion Failed',
      authority_change_ok: 'Authority Modified Successfully',
      authority_change_fail: 'Failed to Modify Authority',
      error: 'An Error Occurred',
      user_delete_ok: 'User Deletion Successful',
      user_delete_fail: 'User Deletion Failed',
      user_not_exist: 'User Does Not Exist',
      need_time: 'It Takes Time to Apply',
      invite_info: ' users invited',
      invite_fail: 'Failed to Invite User',
      workspace_modify_fail: 'Workspace Modification Failed',
      workspace_not_load: 'Cannot Load Workspace',
      upload_exit_if_pageout: 'Upload Will Terminate if You Leave the Page',
      existing_user: 'Already a Member of the Workspace',
      existing_in_list: 'Already in the Invite List',
      domain_error: 'Not a Valid Email Address for Registration',
      load_packages: 'Loading Required Packages',
      preproc_stage_1: 'Click the Add Stage Button',
      preproc_stage_2: 'Select Preprocessing Type',
      preproc_stage_3: 'Select Variables',
      preproc_stage_4: 'Select Method and Press Preview or Complete',
      preproc_stage_5: 'Modify Variables or Methods and Press Complete',
      eda_stage_1: 'Select Dataset',
      eda_stage_2_1: 'Select Variables for Filtering',
      eda_stage_2_2: 'Complete the Condition and Click Finish',
      eda_stage_3: 'Select Variables to View Data in Various Graphs',
      for_learning: 'For Training',
      cannot_create_summary: 'Cannot Create Summary',
      cannot_export: 'Export Error Occurred',
      day: 'Day',
      hour: 'Hour',
      minute: 'Minute',
      second: 'Second',
      fail_get_workspace_info: 'Failed to Retrieve Workspace Information',
      success_edit_email_domain: 'Successful Email Domain List Modification',
      fail_edit_email_domain: 'Failed Email Domain List Modification',
      success_change_pw: 'Password Changed Successfully',
      fail_change_pw: 'Failed to Change Password',
      success_change_name: 'Name Changed Successfully',
      fail_change_name: 'Failed to Change Name',
      somebody_workspace: 'Workspace',
      allowed_people_exceed: 'Exceeded Allowed Number of Invitees',
      still_in_ready: 'Still Preparing',
      file_move_success: 'File Move Request Successful',
      file_move_fail: 'File Move Request Failed',
      some_file_upload_start: ' Files Upload Start',
      some_file_upload_done: ' Files Upload Complete',
      some_file_upload_fail: ' Files Upload Failed',
      pw_rule_length: 'Password Must Be at Least 6 Characters',
      pw_rule_alphabet: 'Password Must Include Alphabetical Characters',
      pw_rule_number: 'Password Must Include Numbers',
      pw_not_match: 'Passwords Do Not Match',
      wordcloud: 'Word Cloud',
      tagger: 'Morphological Analysis',
      word2vec: 'Word2Vec',
      sentiment: 'Sentiment Analysis',
      association: 'Association Analysis',
    },

    preproc: {
      scaler: {
        string: 'Scaling is only possible for integer or float variables.',
      },
      categorical: {
        none: 'There are missing values in the variable.',
      },
      missigValue: {
        mean:
          'Mean cannot be used for string variables. Please enter directly.',
        median:
          'Median cannot be used for string variables. Please enter directly.',
        mode:
          'Mode cannot be used for string variables. Please enter directly.',
        minimum:
          'Minimum value cannot be used for string variables. Please enter directly.',
        maximum:
          'Maximum value cannot be used for string variables. Please enter directly.',
      },
      replace: {
        stringRange: 'Range cannot be entered for string type variables',
      },
      filtering: {
        none: 'No values filtered.',
        number: 'Please enter an integer or float for the condition.',
        stringRange: 'Range cannot be specified for string type variables.',
      },
      outlier: {
        string:
          'Outlier processing is only possible for integer or float variables.',
      },
    },
  },
}
