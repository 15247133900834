import { render, staticRenderFns } from "./default.vue?vue&type=template&id=70b869fe&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=70b869fe&lang=scss&scoped=true&"
import style1 from "./default.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70b869fe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NsIcon: require('/usr/app/src/components/ns/NsIcon.vue').default,NsNotificationBox: require('/usr/app/src/components/ns/NsNotificationBox.vue').default,NsButton: require('/usr/app/src/components/ns/NsButton.vue').default,NsLoading: require('/usr/app/src/components/ns/NsLoading.vue').default,NsToastBox: require('/usr/app/src/components/ns/NsToastBox.vue').default})
