//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api'
export default {
  props: {
    small: Boolean,
    checked: Boolean,
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const isChecked = ref(props.checked)
    const changeCheck = () => emit('change', isChecked.value)

    return {
      props,
      isChecked,
      changeCheck,
    }
  },
}
