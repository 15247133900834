import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AnnotationCanvas2d: () => import('../../src/components/annotation/Canvas2d.vue' /* webpackChunkName: "components/annotation-canvas2d" */).then(c => wrapFunctional(c.default || c)),
  AnnotationClassBox: () => import('../../src/components/annotation/ClassBox.vue' /* webpackChunkName: "components/annotation-class-box" */).then(c => wrapFunctional(c.default || c)),
  AnnotationGuideBox: () => import('../../src/components/annotation/GuideBox.vue' /* webpackChunkName: "components/annotation-guide-box" */).then(c => wrapFunctional(c.default || c)),
  AnnotationInfoBox: () => import('../../src/components/annotation/InfoBox.vue' /* webpackChunkName: "components/annotation-info-box" */).then(c => wrapFunctional(c.default || c)),
  AnnotationInfoTable: () => import('../../src/components/annotation/InfoTable.vue' /* webpackChunkName: "components/annotation-info-table" */).then(c => wrapFunctional(c.default || c)),
  AnnotationObjectBox: () => import('../../src/components/annotation/ObjectBox.vue' /* webpackChunkName: "components/annotation-object-box" */).then(c => wrapFunctional(c.default || c)),
  AnnotationProgressBox: () => import('../../src/components/annotation/ProgressBox.vue' /* webpackChunkName: "components/annotation-progress-box" */).then(c => wrapFunctional(c.default || c)),
  CommonApexChart: () => import('../../src/components/common/ApexChart.js' /* webpackChunkName: "components/common-apex-chart" */).then(c => wrapFunctional(c.default || c)),
  DatasetsConvertModal: () => import('../../src/components/datasets/ConvertModal.vue' /* webpackChunkName: "components/datasets-convert-modal" */).then(c => wrapFunctional(c.default || c)),
  DatasetsCorrelationChart: () => import('../../src/components/datasets/CorrelationChart.vue' /* webpackChunkName: "components/datasets-correlation-chart" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsAnnotationSelect: () => import('../../src/components/datasets/DsAnnotationSelect.vue' /* webpackChunkName: "components/datasets-ds-annotation-select" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsAnnotationStart: () => import('../../src/components/datasets/DsAnnotationStart.vue' /* webpackChunkName: "components/datasets-ds-annotation-start" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsConvertFailed: () => import('../../src/components/datasets/DsConvertFailed.vue' /* webpackChunkName: "components/datasets-ds-convert-failed" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsModifyInfo: () => import('../../src/components/datasets/DsModifyInfo.vue' /* webpackChunkName: "components/datasets-ds-modify-info" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsObjDetect: () => import('../../src/components/datasets/DsObjDetect.vue' /* webpackChunkName: "components/datasets-ds-obj-detect" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsUpload: () => import('../../src/components/datasets/DsUpload.vue' /* webpackChunkName: "components/datasets-ds-upload" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsUploadStart: () => import('../../src/components/datasets/DsUploadStart.vue' /* webpackChunkName: "components/datasets-ds-upload-start" */).then(c => wrapFunctional(c.default || c)),
  DatasetsDsWarning: () => import('../../src/components/datasets/DsWarning.vue' /* webpackChunkName: "components/datasets-ds-warning" */).then(c => wrapFunctional(c.default || c)),
  DeployDistribute: () => import('../../src/components/deploy/DeployDistribute.vue' /* webpackChunkName: "components/deploy-distribute" */).then(c => wrapFunctional(c.default || c)),
  DeployDistributeCancel: () => import('../../src/components/deploy/DeployDistributeCancel.vue' /* webpackChunkName: "components/deploy-distribute-cancel" */).then(c => wrapFunctional(c.default || c)),
  DeployDistributeResult: () => import('../../src/components/deploy/DeployDistributeResult.vue' /* webpackChunkName: "components/deploy-distribute-result" */).then(c => wrapFunctional(c.default || c)),
  FilestoreFileExplorer: () => import('../../src/components/filestore/FileExplorer.vue' /* webpackChunkName: "components/filestore-file-explorer" */).then(c => wrapFunctional(c.default || c)),
  FilestoreFileItem: () => import('../../src/components/filestore/FileItem.vue' /* webpackChunkName: "components/filestore-file-item" */).then(c => wrapFunctional(c.default || c)),
  FilestoreFileTree: () => import('../../src/components/filestore/FileTree.vue' /* webpackChunkName: "components/filestore-file-tree" */).then(c => wrapFunctional(c.default || c)),
  InstructionDataAnalysisVariable: () => import('../../src/components/instruction/DataAnalysisVariable.vue' /* webpackChunkName: "components/instruction-data-analysis-variable" */).then(c => wrapFunctional(c.default || c)),
  InstructionDynamicVariable: () => import('../../src/components/instruction/DynamicVariable.vue' /* webpackChunkName: "components/instruction-dynamic-variable" */).then(c => wrapFunctional(c.default || c)),
  InstructionCard: () => import('../../src/components/instruction/InstructionCard.vue' /* webpackChunkName: "components/instruction-card" */).then(c => wrapFunctional(c.default || c)),
  InstructionLeaveInstruction: () => import('../../src/components/instruction/LeaveInstruction.vue' /* webpackChunkName: "components/instruction-leave-instruction" */).then(c => wrapFunctional(c.default || c)),
  JobChart: () => import('../../src/components/job/JobChart.vue' /* webpackChunkName: "components/job-chart" */).then(c => wrapFunctional(c.default || c)),
  JobConsole: () => import('../../src/components/job/JobConsole.vue' /* webpackChunkName: "components/job-console" */).then(c => wrapFunctional(c.default || c)),
  JobDataInference: () => import('../../src/components/job/JobDataInference.vue' /* webpackChunkName: "components/job-data-inference" */).then(c => wrapFunctional(c.default || c)),
  JobDone: () => import('../../src/components/job/JobDone.vue' /* webpackChunkName: "components/job-done" */).then(c => wrapFunctional(c.default || c)),
  JobInference: () => import('../../src/components/job/JobInference.vue' /* webpackChunkName: "components/job-inference" */).then(c => wrapFunctional(c.default || c)),
  JobInferenceResult: () => import('../../src/components/job/JobInferenceResult.vue' /* webpackChunkName: "components/job-inference-result" */).then(c => wrapFunctional(c.default || c)),
  JobInfo: () => import('../../src/components/job/JobInfo.vue' /* webpackChunkName: "components/job-info" */).then(c => wrapFunctional(c.default || c)),
  JobPercent: () => import('../../src/components/job/JobPercent.vue' /* webpackChunkName: "components/job-percent" */).then(c => wrapFunctional(c.default || c)),
  JobRunning: () => import('../../src/components/job/JobRunning.vue' /* webpackChunkName: "components/job-running" */).then(c => wrapFunctional(c.default || c)),
  JobWaiting: () => import('../../src/components/job/JobWaiting.vue' /* webpackChunkName: "components/job-waiting" */).then(c => wrapFunctional(c.default || c)),
  JobWebcam: () => import('../../src/components/job/JobWebcam.vue' /* webpackChunkName: "components/job-webcam" */).then(c => wrapFunctional(c.default || c)),
  MembershipPlanValue: () => import('../../src/components/membership/PlanValue.vue' /* webpackChunkName: "components/membership-plan-value" */).then(c => wrapFunctional(c.default || c)),
  NsAccordion: () => import('../../src/components/ns/NsAccordion.vue' /* webpackChunkName: "components/ns-accordion" */).then(c => wrapFunctional(c.default || c)),
  NsAccordionPanel: () => import('../../src/components/ns/NsAccordionPanel.vue' /* webpackChunkName: "components/ns-accordion-panel" */).then(c => wrapFunctional(c.default || c)),
  NsAuthFooter: () => import('../../src/components/ns/NsAuthFooter.vue' /* webpackChunkName: "components/ns-auth-footer" */).then(c => wrapFunctional(c.default || c)),
  NsAuthHeader: () => import('../../src/components/ns/NsAuthHeader.vue' /* webpackChunkName: "components/ns-auth-header" */).then(c => wrapFunctional(c.default || c)),
  NsButton: () => import('../../src/components/ns/NsButton.vue' /* webpackChunkName: "components/ns-button" */).then(c => wrapFunctional(c.default || c)),
  NsCheckbox: () => import('../../src/components/ns/NsCheckbox.vue' /* webpackChunkName: "components/ns-checkbox" */).then(c => wrapFunctional(c.default || c)),
  NsConfirm: () => import('../../src/components/ns/NsConfirm.vue' /* webpackChunkName: "components/ns-confirm" */).then(c => wrapFunctional(c.default || c)),
  NsContainer: () => import('../../src/components/ns/NsContainer.vue' /* webpackChunkName: "components/ns-container" */).then(c => wrapFunctional(c.default || c)),
  NsDataTable: () => import('../../src/components/ns/NsDataTable.vue' /* webpackChunkName: "components/ns-data-table" */).then(c => wrapFunctional(c.default || c)),
  NsFilter: () => import('../../src/components/ns/NsFilter.vue' /* webpackChunkName: "components/ns-filter" */).then(c => wrapFunctional(c.default || c)),
  NsGraph: () => import('../../src/components/ns/NsGraph.vue' /* webpackChunkName: "components/ns-graph" */).then(c => wrapFunctional(c.default || c)),
  NsIcon: () => import('../../src/components/ns/NsIcon.vue' /* webpackChunkName: "components/ns-icon" */).then(c => wrapFunctional(c.default || c)),
  NsImage: () => import('../../src/components/ns/NsImage.vue' /* webpackChunkName: "components/ns-image" */).then(c => wrapFunctional(c.default || c)),
  NsImageView: () => import('../../src/components/ns/NsImageView.vue' /* webpackChunkName: "components/ns-image-view" */).then(c => wrapFunctional(c.default || c)),
  NsInput: () => import('../../src/components/ns/NsInput.vue' /* webpackChunkName: "components/ns-input" */).then(c => wrapFunctional(c.default || c)),
  NsLoading: () => import('../../src/components/ns/NsLoading.vue' /* webpackChunkName: "components/ns-loading" */).then(c => wrapFunctional(c.default || c)),
  NsModal: () => import('../../src/components/ns/NsModal.vue' /* webpackChunkName: "components/ns-modal" */).then(c => wrapFunctional(c.default || c)),
  NsNotification: () => import('../../src/components/ns/NsNotification.vue' /* webpackChunkName: "components/ns-notification" */).then(c => wrapFunctional(c.default || c)),
  NsNotificationBox: () => import('../../src/components/ns/NsNotificationBox.vue' /* webpackChunkName: "components/ns-notification-box" */).then(c => wrapFunctional(c.default || c)),
  NsPagination: () => import('../../src/components/ns/NsPagination.vue' /* webpackChunkName: "components/ns-pagination" */).then(c => wrapFunctional(c.default || c)),
  NsPayment: () => import('../../src/components/ns/NsPayment.vue' /* webpackChunkName: "components/ns-payment" */).then(c => wrapFunctional(c.default || c)),
  NsPlot: () => import('../../src/components/ns/NsPlot.vue' /* webpackChunkName: "components/ns-plot" */).then(c => wrapFunctional(c.default || c)),
  NsProgress: () => import('../../src/components/ns/NsProgress.vue' /* webpackChunkName: "components/ns-progress" */).then(c => wrapFunctional(c.default || c)),
  NsRadio: () => import('../../src/components/ns/NsRadio.vue' /* webpackChunkName: "components/ns-radio" */).then(c => wrapFunctional(c.default || c)),
  NsSearch: () => import('../../src/components/ns/NsSearch.vue' /* webpackChunkName: "components/ns-search" */).then(c => wrapFunctional(c.default || c)),
  NsSelect: () => import('../../src/components/ns/NsSelect.vue' /* webpackChunkName: "components/ns-select" */).then(c => wrapFunctional(c.default || c)),
  NsTab: () => import('../../src/components/ns/NsTab.vue' /* webpackChunkName: "components/ns-tab" */).then(c => wrapFunctional(c.default || c)),
  NsTable: () => import('../../src/components/ns/NsTable.vue' /* webpackChunkName: "components/ns-table" */).then(c => wrapFunctional(c.default || c)),
  NsTableCol: () => import('../../src/components/ns/NsTableCol.vue' /* webpackChunkName: "components/ns-table-col" */).then(c => wrapFunctional(c.default || c)),
  NsTableRow: () => import('../../src/components/ns/NsTableRow.vue' /* webpackChunkName: "components/ns-table-row" */).then(c => wrapFunctional(c.default || c)),
  NsTag: () => import('../../src/components/ns/NsTag.vue' /* webpackChunkName: "components/ns-tag" */).then(c => wrapFunctional(c.default || c)),
  NsTip: () => import('../../src/components/ns/NsTip.vue' /* webpackChunkName: "components/ns-tip" */).then(c => wrapFunctional(c.default || c)),
  NsToast: () => import('../../src/components/ns/NsToast.vue' /* webpackChunkName: "components/ns-toast" */).then(c => wrapFunctional(c.default || c)),
  NsToastBox: () => import('../../src/components/ns/NsToastBox.vue' /* webpackChunkName: "components/ns-toast-box" */).then(c => wrapFunctional(c.default || c)),
  NsToolTipBox: () => import('../../src/components/ns/NsToolTipBox.vue' /* webpackChunkName: "components/ns-tool-tip-box" */).then(c => wrapFunctional(c.default || c)),
  NsTooltip: () => import('../../src/components/ns/NsTooltip.vue' /* webpackChunkName: "components/ns-tooltip" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
