// import * as CryptoJS from 'crypto-js'

const CRYPTO_KEY = 'neuralworksstudiofe'

function encrpyt(data, key = CRYPTO_KEY) {
  const string = JSON.stringify(data)
  // return CryptoJS.AES.encrypt(string, CRYPTO_KEY)
  return string
}

function decrypt(data, key = CRYPTO_KEY) {
  try {
    // const dec = CryptoJS.AES.decrypt(data, CRYPTO_KEY)
    // const enc = CryptoJS.enc.Utf8
    // return JSON.parse(dec.toString(enc))
    return JSON.parse(data)
  } catch (e) {
    return null
  }
}

export default {
  encrpyt,
  decrypt,
}
