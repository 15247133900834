const pre = "u-";

export const UPLOT          =       "uplot";
export const ORI_HZ         = pre + "hz";
export const ORI_VT         = pre + "vt";
export const TITLE          = pre + "title";
export const WRAP           = pre + "wrap";
export const UNDER          = pre + "under";
export const OVER           = pre + "over";
export const OFF            = pre + "off";
export const SELECT         = pre + "select";
export const CURSOR_X       = pre + "cursor-x";
export const CURSOR_Y       = pre + "cursor-y";
export const CURSOR_PT      = pre + "cursor-pt";
export const LEGEND         = pre + "legend"
export const LEGEND_LIVE    = pre + "live";
export const LEGEND_INLINE  = pre + "inline";
export const LEGEND_THEAD   = pre + "thead";
export const LEGEND_SERIES  = pre + "series";
export const LEGEND_MARKER  = pre + "marker";
export const LEGEND_LABEL   = pre + "label";
export const LEGEND_VALUE   = pre + "value";