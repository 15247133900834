import { reactive, toRefs } from '@nuxtjs/composition-api'

const state = reactive({
  tooltips: [],
  tooltipIdIndex: 1,
  tooltipHandlers: {},
  eventListeners: [],
})

function addTooltip(el, text, removeCallback) {
  const { x, width, y, height } = el.getBoundingClientRect()
  const { scrollTop } = document.scrollingElement
  const left = x + width + 6
  let top = y + height / 2 - 16
  if (scrollTop) {
    top += scrollTop
  }
  const tooltip = {
    id: state.tooltipIdIndex++,
    left,
    top,
    text,
    hide: false,
    removeCallback,
  }
  state.tooltips.push(tooltip)
  return tooltip
}

function updateTooltip(id) {
  if (state.tooltipHandlers[id]) {
    clearTimeout(state.tooltipHandlers[id])
    const index = state.tooltips.findIndex(
      ({ id: tooltipId }) => tooltipId === id
    )
    state.tooltips[index].hide = false
    state.tooltipHandlers[id] = undefined
  }
}

function removeTooltip(id) {
  if (!state.tooltipHandlers[id]) {
    const index = state.tooltips.findIndex(
      ({ id: tooltipId }) => tooltipId === id
    )
    if (index > -1) {
      state.tooltips[index].hide = true
      state.tooltipHandlers[id] = setTimeout(() => {
        const index = state.tooltips.findIndex(
          ({ id: tooltipId }) => tooltipId === id
        )
        if (index > -1) {
          state.tooltips[index].removeCallback()
          state.tooltips.splice(index, 1)
          state.tooltipHandlers[id] = null
        }
      }, 200)
    }
  }
}

export default function useTooltipState() {
  return {
    ...toRefs(state),
    addTooltip,
    updateTooltip,
    removeTooltip,
  }
}
