//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { useRouter } from '@nuxtjs/composition-api'
export default {
  props: {
    title: {
      type: String,
      default: '페이지 타이틀',
    },
    description: {
      type: String,
      default: '지시/안내문 1~2줄',
    },
    full: Boolean,
    back: {
      type: [String, Boolean],
      default: null,
    },
  },
  setup(props) {
    const router = useRouter()
    function moveBack() {
      if (props.back) {
        router.push(props.back)
      } else {
        router.back()
      }
    }
    return {
      props,
      moveBack,
    }
  },
}
