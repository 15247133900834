/* eslint-disable eqeqeq */
import { roundDec, PI } from '../utils'
import {
  orient,
  moveToH,
  moveToV,
  rectH,
  arcH,
  arcV,
  BAND_CLIP_FILL,
  BAND_CLIP_STROKE,
} from './utils'
const pxRatio = 1

// TODO: drawWrap(seriesIdx, drawPoints) (save, restore, translate, clip)
export function points(opts) {
  return (u, seriesIdx, idx0, idx1, filtIdxs) => {
    //	log("drawPoints()", arguments);

    return orient(
      u,
      seriesIdx,
      (
        series,
        dataX,
        dataY,
        scaleX,
        scaleY,
        valToPosX,
        valToPosY,
        xOff,
        yOff,
        xDim,
        yDim
      ) => {
        const { pxRound, points } = series

        let moveTo, arc

        if (scaleX.ori == 0) {
          moveTo = moveToH
          arc = arcH
        } else {
          moveTo = moveToV
          arc = arcV
        }

        const width = roundDec(points.width * pxRatio, 3)

        const rad = ((points.size - points.width) / 2) * pxRatio
        const dia = roundDec(rad * 2, 3)

        const fill = new Path2D()
        const clip = new Path2D()

        const { left: lft, top, width: wid, height: hgt } = u.bbox

        rectH(clip, lft - dia, top - dia, wid + dia * 2, hgt + dia * 2)

        const drawPoint = (pi) => {
          if (dataY[pi] != null) {
            const x = pxRound(valToPosX(dataX[pi], scaleX, xDim, xOff))
            const y = pxRound(valToPosY(dataY[pi], scaleY, yDim, yOff))

            moveTo(fill, x + rad, y)
            arc(fill, x, y, rad, 0, PI * 2)
          }
        }

        if (filtIdxs) filtIdxs.forEach(drawPoint)
        else {
          for (let pi = idx0; pi <= idx1; pi++) drawPoint(pi)
        }

        return {
          stroke: width > 0 ? fill : null,
          fill,
          clip,
          flags: BAND_CLIP_FILL | BAND_CLIP_STROKE,
        }
      }
    )
  }
}
