import { reactive, toRefs } from '@nuxtjs/composition-api'
import useI18N from '@/translations/i18n'
import useTooltipState from '../../state/ns/tooltipState'
import useToastState from '../../state/ns/toastState'
import api from '../../util/api'
import storage from '../../util/storage'
const $t = useI18N()

const tooltipState = useTooltipState()
const toastState = useToastState()

const state = reactive({
  workspaces: [],
  workspaceId: '',
  popups: {},
  isWorkspaceLoading: false,
  isOrganization: false,
  organizationId: '',
  organization: null,
})

function fetchWorkspaceToken(id) {
  return api.put(`/users/workspace/${id}`).then((response) => {
    const { status, data } = response
    if (status === 200) {
      const { accessToken } = data
      storage.setToken(accessToken)
      return Promise.resolve(true)
    } else {
      return Promise.resolve(false)
    }
  })
}

function calculateCurrentWsMemberCount(workspaces) {
  const userIds = new Set()
  for (const ws of workspaces) {
    if (ws.type === 'TEAM' && ws.role === 'OWNER' && ws.members && ws.active) {
      for (const m of ws.members) {
        if (m.role !== 'OWNER') {
          userIds.add(m._id)
        }
      }
    }
  }
  return userIds.size
}

function setWorkspaceId(id) {
  state.workspaceId = id
}

async function handleWorkspaceChange(index, moveTo) {
  const { id, name } = state.workspaces[index]
  state.isWorkspaceLoading = true
  const result = await fetchWorkspaceToken(id)
  if (result) {
    if (moveTo) {
      moveTo('/instruction')
    }
    state.workspaceId = id
    toastState.addToast({
      message: `${name}  ${$t('mesg.helper.somebody_workspace')}`,
    })
  } else {
    toastState.addToast({
      message: $t('mesg.helper.workspace_modify_fail'),
      status: 'error',
    })
  }
  state.isWorkspaceLoading = false
}

function handleWorkspaceMouseEnter(event, index) {
  if (!state.popups[index]) {
    const tooltip = tooltipState.addTooltip(
      event.target,
      state.workspaces[index].name,
      () => (state.popups[index] = undefined)
    )
    state.popups[index] = tooltip.id
  } else {
    tooltipState.updateTooltip(state.popups[index])
  }
}

function handleWorkspaceMouseLeave(event, index) {
  if (state.popups[index]) {
    tooltipState.removeTooltip(state.popups[index])
  }
}

async function fetchWorkspace() {
  try {
    const response = await api.takeAll('/workspace')
    if (response.status === 200) {
      state.workspaces = response.data.data.map((workspace) => {
        if (workspace.name[0].match(/[a-zA-Z]/)) {
          workspace.char = workspace.name[0].toUpperCase()
        } else {
          workspace.char = workspace.name[0].slice(0, 2).toUpperCase()
        }
        return workspace
      })
    } else {
      toastState.addToast({
        message: $t('mesg.helper.workspace_not_load'),
        status: 'error',
      })
    }
  } catch (e) {
    console.error(e)
    if (e.response && e.response.status !== 401) {
      toastState.addToast({
        message: $t('mesg.helper.workspace_not_load'),
        status: 'error',
      })
    }
  }
}

async function fetchOrganization() {
  try {
    state.organization = null
    state.isOrganization = false
    state.organizationId = null
    const response = await api.get('/auth/me')
    if (response.status === 200) {
      if (
        response.data.organizations &&
        response.data.organizations.length > 0
      ) {
        const organizationId = response.data.organizations[0].id

        state.organization = response.data.organizations[0]
        state.isOrganization = true
        state.organizationId = organizationId
      }
    }
  } catch (e) {
    window.reportError(e)
  }
}

export default function useWorkspaceHelper() {
  return {
    ...toRefs(state),
    fetchWorkspace,
    fetchOrganization,
    handleWorkspaceChange,
    handleWorkspaceMouseEnter,
    handleWorkspaceMouseLeave,
    setWorkspaceId,
    calculateCurrentWsMemberCount,
  }
}
