var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ns-image-view-wrap"},[_c('div',{ref:"viewportRef",staticClass:"ns-image-viewport"},[_c('img',{ref:"imageRef",class:{
        dragging: _vm.mouseDown,
      },style:({
        width: ((_vm.size.width) + "px"),
        height: ((_vm.size.height) + "px"),
        marginLeft: ((_vm.margin.x) + "px"),
        marginTop: ((_vm.margin.y) + "px"),
      }),attrs:{"src":_vm.src,"draggable":"false"}}),_vm._v(" "),_c('div',{staticClass:"ns-viewport-toolbar",staticStyle:{"--deg":"180deg"}},[_c('ns-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.handleZoomClick(-0.5)}}},[_vm._v("remove")]),_vm._v(" "),_c('ns-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.handleZoomClick(0.5)}}},[_vm._v("add")]),_vm._v(" "),_c('span',[_vm._v("x "+_vm._s(_vm.castFixed(_vm.scale, 1)))])],1),_vm._v(" "),_c('div',{staticClass:"ns-viewport-toolbar top",staticStyle:{"--deg":"360deg"}},[_c('ns-icon',{attrs:{"color":"white"},on:{"click":function($event){return _vm.handleCloseClick()}}},[_vm._v("close")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }