import { computed, reactive, toRefs } from '@nuxtjs/composition-api'
import dayjs from 'dayjs'
import useI18N from '@/translations/i18n'
import api from '../../util/api'
const $t = useI18N()

const state = reactive({
  lastUpdate: 0,
  isLoading: false,
  _jobs: [],
  _wait: [],
  _progress: [],
  _finish: [],
  tabs: [
    {
      text: $t('mesg.js.recent'),
      count: 0,
      color: 'black',
    },
    {
      text: $t('mesg.js.waiting'),
      count: 0,
      color: 'gray',
      filter: ['WAITING', 'QUEUEING'],
    },
    {
      text: $t('mesg.js.ing'),
      count: 0,
      color: 'orange',
      filter: ['RUNNING'],
    },
    {
      text: $t('mesg.js.done'),
      count: 0,
      filter: ['DONE', 'ERROR'],
    },
  ],
  _tab: 0,
  get tab() {
    return this._tab
  },
  set tab(tab) {
    this._tab = tab
    this.pagination.index = 1
  },
  _query: '',
  get query() {
    return this._query
  },
  set query(query) {
    this._query = query
  },
  pagination: {
    max: 1,
    index: 1,
    count: 10,
  },
  isOwnerView: false,
  lastDate: [],
})

function reset() {
  state._jobs = []
  state._wait = []
  state._progress = []
  state._finish = []
  state._tab = 0
  state._query = ''
}

async function fetchJobs(currentDate = [], socket = false) {
  // api cooldown 10s
  // if (new Date().getTime() - state.lastUpdate < 10000) return
  reset()
  if (!socket) {
    state.isLoading = true
  } else {
    currentDate = state.lastDate
  }
  let [startTime, endTime] = currentDate.map((date) => dayjs(date))
  if (!startTime) {
    // startTime = dayjs().subtract(1, 'month')
    // endTime = dayjs()
    return
  }
  state.lastDate = [...currentDate]
  startTime = startTime.format().split('+')[0]
  endTime = endTime.add(1, 'day').format().split('+')[0]

  // const { data, status } = await api.takeAll(
  //   '/job/',
  //   `&from=${startTime}&to=${endTime}`
  // )

  const { data, status } = await api.get(
    `/job/?from=${startTime}&to=${endTime}&takeAll=1`
  )
  const groups = data.data
  if (status === 200) {
    if (Array.isArray(groups)) {
      const STATUS_GROUP = ['ERROR', 'WAITING', 'RUNNING', 'DONE']
      state._jobs = groups
        .map((group) => {
          if (!group.jobs) return null
          let status = STATUS_GROUP.length
          const models = new Set()
          group.jobs.forEach((job) => {
            if ((job.startTime || '').includes('T')) {
              // job.startTime = job.startTime.split('T').join('\n').split('.')[0]
              job.startTime = dayjs(job.startTime).format('YY/MM/DD HH:mm:ss')
            }
            job.timestamp = job.startTime || ''
            const index = STATUS_GROUP.indexOf(job.status)
            if (index < status) {
              status = index
            }
            models.add(job.modelName)
          })
          Object.keys(group.jobs[0]).forEach((key) => {
            group[key] = group.jobs[0][key]
          })
          Object.keys(group).forEach((e) => {
            if (e.startsWith('first')) {
              const split = e.substr(5).split('')
              split[0] = split[0].toLowerCase()
              const key = split.join('')
              let value = group[e]
              delete group[e]
              if (key === 'startTime' && (value || '').includes('T')) {
                // value = value.split('T').join('\n').split('.')[0]
                value = dayjs(value).format('YY/MM/DD HH:mm:ss')
              }
              group[key] = value
            }
          })
          group.expandable = group.jobs.length > 1
          if (group.expandable) {
            group.expand = false
          }
          return group
        })
        .filter((group) => group)
        .sort((_a, _b) =>
          _b.startTime !== '' && _a.startTime !== ''
            ? new Date(_b.startTime) - new Date(_a.startTime)
            : 0
        )
    }
  }
  state.isLoading = false
}

const counts = computed(() => {
  const _counts = {
    WAITING: 0,
    RUNNING: 0,
    DONE: 0,
    ERROR: 0,
  }
  const owned = state._jobs.filter(({ isOwner }) =>
    state.isOwnerView ? isOwner : true
  )
  owned.forEach((job) => {
    _counts[job.status] += 1
  })
  state.tabs[1].count = _counts.WAITING
  state.tabs[2].count = _counts.RUNNING
  state.tabs[3].count = _counts.DONE + _counts.ERROR
  return _counts
})

const jobs = computed(() => {
  if (state._tab === 0) {
    const owned = state._jobs.filter(({ isOwner }) =>
      state.isOwnerView ? isOwner : true
    )
    // 최근
    const search = owned.filter((row) => {
      const totalStr = [
        row.jobName,
        row.jobDescription,
        row.datasetName,
        row.modelName,
      ]
        .join(' ')
        .toLowerCase()
      return totalStr.includes(state.query.toLowerCase())
    })
    const recents = search.filter((_, i) => i < 10)
    state.tabs[0].count = recents.length
    state.pagination.max = 0
    return recents
  }

  const owned = state._jobs.filter(({ isOwner }) =>
    state.isOwnerView ? isOwner : true
  )
  const _jobs = owned.filter((row) =>
    state.tabs[state._tab].filter.includes(row.status)
  )
  if (_jobs) {
    const _sub = _jobs.filter((row) => {
      const totalStr = [
        row.jobName,
        row.jobDescription,
        row.datasetName,
        row.modelName,
      ]
        .join(' ')
        .toLowerCase()
      return totalStr.includes(state.query.toLowerCase())
    })

    Object.assign(state.tabs[state._tab], { count: _sub.length })

    state.pagination.max = parseInt(
      (_sub.length - 1) / state.pagination.count + 1,
      10
    )
    const from = state.pagination.count * (state.pagination.index - 1)
    const to = Math.min(
      state.pagination.count,
      state.pagination.max * state.pagination.count - from
    )
    return _sub.slice(from, from + to)
  }
  return []
})

const head = computed(() => {
  const _head = [
    {
      text: $t('mesg.js.owner'),
      size: 130,
      align: 'center',
    },
    {
      text: $t('mesg.js.learning_name'),
      flex: 1,
    },
    {
      text: $t('mesg.js.model_type'),
      size: 110,
    },
    {
      text: $t('mesg.js.dataset'),
      size: 180,
    },
    {
      text: $t('mesg.js.model'),
      size: 150,
    },
  ]
  if (state._tab === 3) {
    _head.push({
      text: $t('mesg.js.performance'),
      size: 120,
    })
  }
  if (state._tab < 2) {
    _head.push({
      text: $t('mesg.js.learn_request'),
      align: 'center',
      size: 130,
    })
  } else {
    _head.push({
      text: $t('mesg.js.learn_start'),
      size: 130,
    })
  }
  if (state._tab === 0) {
    _head.push({
      text: $t('mesg.js.state'),
      size: 110,
    })
  }
  if (state._tab === 2) {
    _head.push({
      text: $t('mesg.js.learn_ing_info'),
      size: 180,
      align: 'center',
    })
  }
  return _head
})

export default function useJobState() {
  return {
    ...toRefs(state),
    fetchJobs,
    jobs,
    head,
    counts,
    reset,
  }
}
