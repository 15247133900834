//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, watch } from '@nuxtjs/composition-api'
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    initIndex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    dense: Boolean,
  },
  setup(props, { emit }) {
    const colors = ['black', '#252525', 'gray', '#959191', 'orange', '#ff9900']
    const tabIndex = ref(props.initIndex || props.index)
    const changeTab = (index, { disabled }) => {
      if (disabled) return
      const nextIndex = index
      if (nextIndex !== tabIndex.value) {
        tabIndex.value = nextIndex
        emit('change', tabIndex.value)
      }
    }
    watch(
      () => props.index,
      () => {
        tabIndex.value = props.index
      }
    )
    const getClass = (index, tab) => {
      const { color, count } = tab
      const classes = []
      if (colors.includes(color)) {
        classes.push('c-' + colors[colors.indexOf(color) + 1].substr(1))
      }
      if (index === tabIndex.value) {
        classes.push('selected')
      }
      if (typeof count !== 'number') {
        classes.push('textonly')
      }
      if (tab.disabled) {
        classes.push('disabled')
      }
      if (props.dense) {
        classes.push('dense')
      }
      return classes
    }
    return { props, changeTab, tabIndex, colors, getClass }
  },
}
