import { computed, onMounted, ref, useRoute } from '@nuxtjs/composition-api'
import api from '../util/api'

export default function useGuideHelper() {
  const guideMap = ref(null)
  const route = useRoute()
  const isGuideReady = computed(() => guideMap.value !== null)

  function handleGuideClick() {
    const { path } = route.value
    const paths = Object.keys(guideMap.value).sort().reverse()
    for (let pathIndex = 0; pathIndex < paths.length; pathIndex++) {
      if (path.includes(paths[pathIndex])) {
        window.open(guideMap.value[paths[pathIndex]])
        return
      }
    }
    window.open(
      'https://neuralworks.notion.site/2022-07-9bc29fa65d2b4b479175aacff73c96dd'
    )
  }

  function handleContactUsClick() {
    window.open('https://bit.ly/neural-idea')
  }

  onMounted(async () => {
    const result = await api.get('/guide')
    if (result.status === 200) {
      guideMap.value = result.data.links
    }
  })

  return {
    isGuideReady,
    handleGuideClick,
    handleContactUsClick,
  }
}
