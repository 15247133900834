//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, onUnmounted, ref, watch } from '@nuxtjs/composition-api'
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: [Object, Array],
      default: () => [],
    },
  },
  setup(props) {
    const chartOptions = ref({
      width: 600,
      height: 360,
      ...props.options,
    })
    const chartData = ref(props.data)
    const chart = ref(null)
    const wrapRef = ref(null)
    const chartRef = ref(null)
    const lastSize = ref([0, 0])
    const resizeHandler = ref(null)
    function checkAndResize() {
      if (chartRef.value) {
        if (wrapRef.value) {
          if (getComputedStyle(wrapRef.value).display === 'none') return
        }
        let { width, height } = getComputedStyle(chartRef.value)
        width = parseInt(width) || chartOptions.value.width
        height = parseInt((width / 5) * 3)
        if (width !== lastSize.value[0]) {
          lastSize.value = [width, height]
          chartOptions.value = {
            width,
            height,
            ...props.options,
          }
        }
      }
    }
    function bindResizeHandler() {
      resizeHandler.value = setInterval(checkAndResize, 500)
    }
    function unbindResizeHandler() {
      if (resizeHandler.value) {
        clearInterval(resizeHandler.value)
        resizeHandler.value = null
      }
    }
    onMounted(bindResizeHandler)
    onUnmounted(unbindResizeHandler)

    watch(
      () => props.options,
      () => {
        chartOptions.value = {
          width: lastSize[0],
          height: lastSize[1],
          ...props.options,
        }
      }
    )

    watch(
      () => props.data,
      () => {
        if (chart.value) {
          chart.value.setData(props.data)
          chart.value.redraw()
        }
      }
    )

    function chartCreated(created) {
      chart.value = created
    }

    function chartDeleted() {
      chart.value = null
    }
    return {
      chart,
      wrapRef,
      chartRef,
      chartOptions,
      chartData,
      chartCreated,
      chartDeleted,
    }
  },
}
