//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted } from '@nuxtjs/composition-api'
import useNotificationState from '../../state/ns/notifyState'
export default {
  setup() {
    const notificationState = useNotificationState()
    onMounted(() => {
      window.noti = notificationState
    })
    return {
      ...notificationState,
    }
  },
}
