import axios from 'axios'
import useToastState from '../state/ns/toastState'
import useGlobal from './global'
import storage from './storage'

const global = useGlobal()
const toast = useToastState()

const api = (() => {
  let _api = null
  const cancelTokenSource = axios.CancelToken.source()
  const catchCode = [400, 404, 409, 413, 500, 504, 520]

  const _external = axios.create()
  _external.interceptors.response.use(
    (config) => config,
    (error) => {
      return error.response
    }
  )

  function cancelRequests() {
    cancelTokenSource.cancel('cancel requested')
  }

  function initApi(base) {
    _api = axios.create({
      baseURL: base ?? global.state.env.EXTERNAL_BE_NEURAL_STUDIO,
      headers: {
        testkey: global.state.env.X_API_KEY,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragme: 'no-cache',
        Expires: '0',
      },
      cancelToken: cancelTokenSource.token,
    })
    _api.interceptors.request.use(
      (config) => {
        const token = storage.getToken()
        if (token) {
          config.headers.Authorization = 'Bearer ' + token
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    _api.interceptors.response.use(
      (config) => config,
      (error) => {
        if (error.message === 'Network Error') {
          return Promise.reject(error)
        }
        if (!error.response) return Promise.reject(error)
        if (
          catchCode.includes(error.response.status) ||
          (error.config.url.startsWith('/auth') &&
            !error.config.url.startsWith('/auth/me'))
        ) {
          return error.response
        } else {
          if (
            error.config.url.startsWith('/auth/me') &&
            (error.response.status === 401 || error.response.status === 403)
          ) {
            // token expred or invalid
            const global = useGlobal()
            const { fullPath } = global.state.route.value
            const encoded = encodeURIComponent(fullPath)
            global.state.router.push(`/auth/logout?ref=${encoded}`)
            return error.response
          }
          if (error.response.status === 429) {
            toast.addToast({
              message: '요청이 너무 많습니다.',
              description: '잠시 후 다시 시도해주세요',
              status: 'error',
            })
          }
          return Promise.reject(error)
        }
      }
    )
  }

  function checkApi() {
    if (!_api) {
      initApi()
    }
  }

  function checkAndCall(method, ...args) {
    checkApi()
    return _api[method](...args)
  }

  function isDedicated() {
    return (global.state.env.DEDICATED ?? false).toString() === 'true'
  }

  // 데이터셋의 타입(s3/local)에 따라 다른 API 경로를 지정한 후 파일을 조회/다운로드 한다.
  function presigned(path, download = false) {
    checkApi()
    const encoded = path
    const presignedPath = isDedicated() ? '/file-manager/file' : '/s3/presigned'
    if (isDedicated()) {
      return api
        .get(`${presignedPath}?path=${encoded}&download=${download}`)
        .then(({ status }) => ({
          status,
          data: {
            accessPoint: `${api.baseUrl()}${presignedPath}?path=${encoded}&download=${download}`,
          },
        }))
    }
    return api.get(`${presignedPath}?path=${encoded}&download=${download}`)
  }

  // 데이터셋의 타입(s3/local)에 따라 다른 API 경로를 지정하여 데이터셋 내의 파일들의 정보를 조회한다.
  function bucketfuls(path) {
    checkApi()
    const encoded = path
    const presignedPath = isDedicated()
      ? '/file-manager/meta'
      : '/s3/bucketfuls'
    return api.get(`${presignedPath}/?path=${encoded}`)
  }

  function setBase(url) {
    _api.defaults.baseURL = url
  }

  async function takeAll(url, query) {
    let dataList = []
    let page = 1
    let pageCount = 1
    do {
      const { status, data } = await checkAndCall(
        'get',
        url + `?take=50&page=${page}${query || ''}`
      )
      if (status === 200) {
        dataList = [...dataList, ...data.data]
      } else {
        return Promise.resolve({ status, data: {} })
      }
      page = data.meta.page + 1
      pageCount = data.meta.pageCount
    } while (page <= pageCount)
    return Promise.resolve({ status: 200, data: { data: dataList } })
  }

  function refineBucket(data) {
    if (!data) return null
    let refined = data
    if ('storageMember' in refined) {
      refined = refined.storageMember
    }
    if ('s3BucketList' in refined) {
      refined = refined.s3BucketList
    }

    return refined
  }

  function csv(path) {
    checkApi()
    const targetPath = isDedicated() ? '/file-manager' : '/filestore/s3'
    if (!path.startsWith('/')) {
      path = `/${path}`
    }
    return api.get(`${targetPath}/csv${path}`)
  }

  function resource(path) {
    if (api.isDedicated()) {
      return api.get(path)
    }

    return api.ext.get(path)
  }

  return {
    post: (...args) => checkAndCall('post', ...args),
    get: (...args) => checkAndCall('get', ...args),
    put: (...args) => checkAndCall('put', ...args),
    patch: (...args) => checkAndCall('patch', ...args),
    delete: (...args) => checkAndCall('delete', ...args),
    options: (...args) => checkAndCall('options', ...args),
    presigned,
    bucketfuls,
    refineBucket,
    csv,
    resource,
    ext: _external,
    baseUrl: () => global.state.env.EXTERNAL_BE_NEURAL_STUDIO,
    takeAll,
    cancelRequests,
    isDedicated,
    UPLOAD_TYPE: () => (isDedicated() ? 'LOCAL' : 'S3'),
    setBase,
    initApi,
  }
})()

export default api
