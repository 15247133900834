const fields = {
  SYSVARS: {
    inquiryEmail: 'dev-lab@sol.re.kr',
    productName: 'NeuralWorks',
    studioName: 'SOL Studio',
  },

  image: {
    logo_login: '/studio/logo/sol_logo.png',
    logo_image: '/studio/logo/sol_icon.png',
    logo_text: '/studio/logo/sol_logo.png',
    logo_annotation: '/studio/logo/sol_logo.png',
  },

  mesg: {
    signin: {
      service_guide: 'SOL Studio 입니다.',
      request_to_use:
        '가입을 원하시면 dev-lab@sol.re.kr 로 연락주시기 바랍니다.',
    },
  },
}

export default {
  overrideLanguage: 'ko',
  fields,
}
