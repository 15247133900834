//
//
//
//
//
//
//

import { useContext, useRoute, useRouter } from '@nuxtjs/composition-api'
import useI18nHelper from '@/helpers/i18nHelper'
import useGlobal from '../util/global'
import useSigninState from '../state/auth/signinState'
export default {
  name: 'Paper',

  setup(_, context) {
    const global = useGlobal()
    global.setContext(useContext(), useRoute(), useRouter(), useSigninState())
    useI18nHelper(context.root)
  },
}
