var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"selectRef",staticClass:"ns-select",class:{
    'ns-select-focused': _vm.isOpen,
    'ns-select-disabled': _vm.props.disabled,
    'ns-select-no-border': _vm.props.noBorder,
    small: _vm.small,
  },on:{"click":_vm.toggleOpen,"blur":_vm.toggleOpen}},[(!_vm.props.hideLabel)?_c('p',{staticClass:"ns-select-label",class:{ 'ns-required': _vm.props.required, 'ns-error': _vm.props.error }},[_vm._v("\n    "+_vm._s(_vm.props.label)+"\n  ")]):_vm._e(),_vm._v(" "),_c('select',{style:({ display: 'none' })},_vm._l((_vm.options),function(option,index){return _c('option',{key:(option + "-" + index),domProps:{"value":option}})}),0),_vm._v(" "),_c('div',{staticClass:"ns-select-text",attrs:{"name":_vm.props.inputName}},[(_vm.search && _vm.isOpen)?_c('input',{ref:"searchRef",staticClass:"ns-select-input",attrs:{"placeholder":_vm.options[_vm.value] || _vm.placeholder},domProps:{"value":_vm.searchText},on:{"input":function (ref) {
    var value = ref.target.value;

    return (_vm.searchText = value);
}}}):_c('div',{staticClass:"ns-select-select",class:{ disabled: !_vm.options[_vm.value] }},[(_vm.options[_vm.value] && _vm.options[_vm.value].includes('{'))?_c('span',[_c('katex-element',{attrs:{"expression":_vm.options[_vm.value]}})],1):_c('span',[_vm._v(" "+_vm._s(_vm.options[_vm.value] || _vm.placeholder))])]),_vm._v(" "),_c('div',{staticClass:"ns-select-panel"},_vm._l((_vm.options),function(option,index){return _c('div',{key:(option + "-" + index),class:{
          selected: index === _vm.value,
          visible: _vm.checkSearch(option),
        },on:{"click":function($event){return _vm.optionClick(index, option)}}},[(option.includes('{'))?_c('span',[_c('katex-element',{attrs:{"expression":option}})],1):_c('span',[_vm._v(" "+_vm._s(option))])])}),0)]),_vm._v(" "),(!_vm.props.hideBottom)?_c('div',{staticClass:"ns-input-bottom"},[(!_vm.props.hideTip)?_c('p',{staticClass:"ns-input-tip"},[_vm._v(_vm._s(_vm.props.tip))]):_vm._e()]):_vm._e(),_vm._v(" "),_c('ns-icon',{staticClass:"ns-select-icon",class:{ 'hide-label': _vm.props.hideLabel },attrs:{"ripple":false,"clickable":"false"}},[_vm._v("\n    arrow_drop_down\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }