export default function throttle(fn, delay) {
  let timer = null
  let latestTimer = null
  return function _(...args) {
    if (!timer) {
      fn.apply(this, args)
      timer = setTimeout(() => (timer = null), delay)
    } else {
      if (latestTimer) clearTimeout(latestTimer)
      latestTimer = setTimeout(() => fn.apply(this, args), delay)
    }
  }
}
