import { reactive, toRefs } from '@nuxtjs/composition-api'
import useI18N from '@/translations/i18n'
const $t = useI18N()
const state = reactive({
  _tab: 0,
  get tab() {
    return this._tab
  },
  set tab(val) {
    this._tab = val
  },

  tabs: [
    {
      text: $t('mesg.js.raw_data'),
      count: 0,
    },
    {
      text: $t('mesg.js.learning_data'),
      count: 0,
    },
  ],
})

export default function useDatasetState() {
  return {
    ...toRefs(state),
  }
}
