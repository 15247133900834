// eslint-disable-next-line no-unused-vars
import Vue from 'vue'
import { defineComponent, createVNode } from '@nuxtjs/composition-api'

import { optionsUpdateState, dataMatch } from './uplot-vue-common'
import UPlot from './uplot/uPlot'

export default (defineComponent || ((v) => v))({
  name: 'UplotVue',
  props: {
    options: { type: Object, required: true },
    data: { type: Array, required: true },
    target: {
      validator(target) {
        return (
          target == null ||
          target instanceof HTMLElement ||
          typeof target === 'function'
        )
      },
      default: undefined,
      required: false,
    },
  },
  data() {
    // eslint-disable-next-line
        return {_chart: null};
  },
  watch: {
    options(options, prevOptions) {
      const optionsState = optionsUpdateState(prevOptions, options)
      if (!this._chart || optionsState === 'create') {
        this._destroy()
        this._create()
      } else if (optionsState === 'update') {
        this._chart.setSize({ width: options.width, height: options.height })
      }
    },
    target() {
      this._destroy()
      this._create()
    },
    data(data, prevData) {
      if (!this._chart) {
        this._create()
      } else if (!dataMatch(prevData, data)) {
        this._chart.setData(data)
      }
    },
  },
  mounted() {
    this._create()
  },
  beforeUnmount() {
    this._destroy()
  },
  beforeDestroy() {
    this._destroy()
  },
  methods: {
    _destroy() {
      if (this._chart) {
        this.$emit('delete', this._chart)
        this._chart.destroy()
        this._chart = null
      }
    },
    _create() {
      this._chart = new UPlot(
        this.$props.options,
        this.$props.data,
        this.$props.target || this.$refs.targetRef
      )
      this.$emit('create', this._chart)
    },
  },
  render(h) {
    return this.$props.target
      ? null
      : (createVNode || h)('div', {
          ref: 'targetRef',
        })
  },
})
