import { reactive, toRefs } from '@nuxtjs/composition-api'

const state = reactive({
  csvFilter: false,
  imageFilter: false,
  textFilter: false,
  generalFilter: false,
  isOwnerView: false,
})

function handleCsvFilterToggle() {
  state.csvFilter = !state.csvFilter
  if (state.csvFilter) {
    state.imageFilter = false
    state.textFilter = false
    state.generalFilter = false
  }
}

function handleImageFilterToggle() {
  state.imageFilter = !state.imageFilter
  if (state.imageFilter) {
    state.csvFilter = false
    state.textFilter = false
    state.generalFilter = false
  }
}

function handleTextFilterToggle() {
  state.textFilter = !state.textFilter
  if (state.textFilter) {
    state.csvFilter = false
    state.imageFilter = false
    state.generalFilter = false
  }
}

function handleGeneralFilterToggle() {
  state.generalFilter = !state.generalFilter
  if (state.generalFilter) {
    state.csvFilter = false
    state.imageFilter = false
    state.textFilter = false
  }
}

function handleIsOwnerViewChange(value) {
  state.isOwnerView = value
}

function datasetFilterCallback({ datasetType, isOwner }) {
  if (state.isOwnerView && !isOwner) {
    return false
  }
  if (state.imageFilter) {
    return datasetType === 'IMAGE'
  }
  if (state.csvFilter) {
    return datasetType === 'TABLE'
  }
  if (state.textFilter) {
    return datasetType === 'TEXT'
  }
  if (state.generalFilter) {
    return datasetType === 'GENERAL'
  }
  return true
}

export default function useDatasetFilterHelper() {
  return {
    ...toRefs(state),
    handleCsvFilterToggle,
    handleImageFilterToggle,
    handleTextFilterToggle,
    handleIsOwnerViewChange,
    handleGeneralFilterToggle,
    datasetFilterCallback,
  }
}
