//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api'
export default {
  props: {
    color: {
      type: String,
      default: '#959191',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const percent = computed(() => {
      const val = props.value < 0 ? 0 : props.value > 100 ? 100 : props.value
      return val + '%'
    })
    return {
      percent,
    }
  },
}
