import { computed } from '@nuxtjs/composition-api'
import io from 'socket.io-client'
import useGlobal from './global'
import storage from './storage'

// const STAGING = 'https://gpu-1.neuralworks.io'
let _socket = null
const _listeners = {}
const _logs = []

const events = ['NOTIFICATION', 'JOB', 'RAWDATA', 'ANNOTATION', 'DATASET', '']

function connect(token) {
  const global = useGlobal()
  let URL = global.state.env.EXTERNAL_BE_NEURAL_STUDIO
  if (URL.includes('localhost')) {
    return
  }
  if (URL.indexOf('/studio/api')) {
    URL = URL.substr(0, URL.length - 11)
  }
  _socket = io(URL, {
    path: '/studio/api/ws/socket.io',
    transports: ['websocket'],
    upgrade: false,
    query: { token },
  })
  _socket.on('connect', _onConnected)
  _socket.on('connect_error', _onException)
  _socket.on('disconnect', _onDisonnected)
  _socket.on('exception', _onException)
  events.forEach((event) => {
    _socket.on(`EVENT:${event}`, _boardcastEvent(event))
  })
}

function _checkConnect() {
  if (!isConnected()) {
    const token = storage.getToken()
    if (token) {
      connect(token)
    }
  }
}

function disconnect() {
  if (_socket) {
    _socket.disconnect()
  }
}

function isConnected() {
  return _socket && _socket.connected
}

function logging(log) {
  _logs.push(log)
  if (_logs.length > 100) {
    _logs.shift()
  }
}

function _onConnected() {
  logging('socket connected')
}

function _onDisonnected(reason) {
  logging('socket disconnected : ' + reason)
}

function _onException(reason) {
  logging('socket exception occured : ' + reason)
}

function _getListeners(key) {
  if (_listeners[key]) {
    return _listeners[key]
  }
  return []
}

function _boardcastEvent(name) {
  return function (data) {
    const wildcard = _getListeners('*')
    wildcard.forEach((listener) => listener(data, name))
    const target = _getListeners(name)
    target.forEach((listener) => listener(data))
  }
}

function addEventListener(event, callback) {
  _checkConnect()
  if (Object.hasOwnProperty.call(_listeners, event)) {
    _listeners[event].push(callback)
  } else {
    _listeners[event] = [callback]
  }
}
function removeEventListener(event, callback) {
  if (Object.hasOwnProperty.call(_listeners, event)) {
    if (_listeners[event].includes(callback)) {
      _listeners[event].splice(_listeners[event].indexOf(callback), 1)
    }
  }
}

export default function useSocket() {
  _checkConnect()
  return {
    addEventListener,
    removeEventListener,
    logs: computed(() => _logs),
    disconnect,
    connect,
    isConnected,
  }
}
