import { computed, reactive, toRefs } from '@nuxtjs/composition-api'
import JSZip from 'jszip'
import { formatBytes } from '@/util/fileUtil'
import useDatasetFilterHelper from '@/helpers/dataset/datasetFilterHelper'
import useI18N from '@/translations/i18n'
import api from '../../util/api'
import useUploadHelper from '../../helpers/useUploadHelper'
import useToastState from '../ns/toastState'
import useDatasetState from './datasetState'
const $t = useI18N()

const toastState = useToastState()

const dataset = useDatasetState()
const uploadHelper = useUploadHelper()
const { datasetFilterCallback } = useDatasetFilterHelper()

const SELECT_TYPE_MAP = {
  IMAGE: 1,
  TABLE: 2,
  TEXT: 3,
  GENERAL: 4,
  AUDIO: 5,
  1: 'IMAGE',
  2: 'TABLE',
  3: 'TEXT',
  4: 'GENERAL',
  5: 'AUDIO',
}
const EXTENSIONS_BY_TYPE = {
  [SELECT_TYPE_MAP.IMAGE]: ['jpg', 'jpeg', 'png', 'gif', 'txt', 'json', 'xml'],
  [SELECT_TYPE_MAP.TABLE]: 'csv',
  [SELECT_TYPE_MAP.TEXT]: 'txt',
}
const ERROR_MAP = {
  NONE: 0,
  NOT_ZIP: 1,
  NOT_EXTENSIONS: 2,
}
const TYPE_LIST = [
  {
    type: SELECT_TYPE_MAP.IMAGE,
    text: $t('mesg.components.image'),
  },
  {
    type: SELECT_TYPE_MAP.TABLE,
    text: 'CSV',
  },
  {
    type: SELECT_TYPE_MAP.TEXT,
    text: $t('mesg.datasets.text'),
  },
  {
    type: SELECT_TYPE_MAP.GENERAL,
    text: $t('mesg.components.general'),
  },
  {
    type: SELECT_TYPE_MAP.AUDIO,
    text: $t('mesg.components.voice'),
    disabled: true,
  },
]

const state = reactive({
  dragHover: false,

  files: [],
  selected: [],

  error: ERROR_MAP.NONE,

  isServerUploading: false,

  _dsUploadStatus: 1,
  get dsUploadStatus() {
    return this._dsUploadStatus
  },
  set dsUploadStatus(val) {
    this._dsUploadStatus = val
  },

  _createDsName: '',
  get createDsName() {
    return this._createDsName
  },
  set createDsName(val) {
    this._createDsName = val
  },

  _createDsIsPublic: false,
  get createDsIsPublic() {
    return this._createDsIsPublic
  },
  set createDsIsPublic(val) {
    this._createDsIsPublic = val
  },

  _createDsDesc: '',
  get createDsDesc() {
    return this._createDsDesc
  },
  set createDsDesc(val) {
    this._createDsDesc = val
  },

  _createDsPath: '',
  get createDsPath() {
    return this._createDsPath
  },
  set createDsPath(val) {
    this._createDsPath = val
  },

  _selectType: SELECT_TYPE_MAP.IMAGE,
  get selectType() {
    return this._selectType
  },
  set selectType(val) {
    this.error = ERROR_MAP.NONE
    this._selectType = val
  },

  selectedItem: -1,
  modalOpen: false,
  serverModalOpen: false,

  learnHeader: [
    { text: $t('mesg.js.owner'), size: 120 },
    { text: $t('mesg.js.dataset'), flex: 1 },
    { text: $t('mesg.js.type'), size: 120, align: 'center' },
    { text: $t('mesg.js.count'), size: 90, align: 'center' },
    { text: $t('mesg.js.modify_time'), size: 130, align: 'center' },
    { text: '', size: 140 },
  ],

  _body: [],

  createError: '',

  _learnQuery: '',
  get learnQuery() {
    return this._learnQuery
  },
  set learnQuery(query) {
    this._learnQuery = query
    this.learnPagination.index = 1
  },

  learnPagination: {
    count: 10,
    max: 1,
    index: 1,
  },

  imageInfoOpen: false,

  isLearnLoading: false,
})

function openImageInfo() {
  state.imageInfoOpen = true
}

function closeImageInfo() {
  state.imageInfoOpen = false
}

const learnBody = computed(() => {
  const filtered = state._body.filter(datasetFilterCallback)
  const _sub = filtered.filter((row) => {
    const totalStr = (row.name + ' ' + row.description).toLowerCase()
    return totalStr.includes(state.learnQuery.toLowerCase())
  })

  state.learnPagination.max = parseInt(
    (_sub.length - 1) / state.learnPagination.count + 1,
    10
  )

  const from = state.learnPagination.count * (state.learnPagination.index - 1)
  const to = Math.min(
    state.learnPagination.count,
    state.learnPagination.max * state.learnPagination.count - from
  )

  _sub.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  dataset.tabs.value[1].count = _sub.length

  return _sub.slice(from, from + to)
})

const changeLearnPagination = (index) => {
  Object.assign(state.learnPagination, {
    index,
  })
}

function fetchLearnDataset(id) {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        await _fetchLearnDataset(id)
        resolve()
      } catch (error) {
        reject(error)
      }
    })()
  })
}

const _fetchLearnDataset = async (id) => {
  state.isRawLoading = true
  const response = await api.get(`/dataset/${id}`)
  if (response.status === 200) {
    const currentDatasets = [...state._body]
    const index = currentDatasets.findIndex(
      (item) => item.id === response.data.id
    )
    if (index !== -1) {
      currentDatasets[index] = response.data
    } else {
      currentDatasets.push(response.data)
    }
    currentDatasets.sort((a, b) => a.created_at - b.created_at)
    state._body = [...currentDatasets]
  }
  state.isRawLoading = false
}

const fetchLearnDatasets = async () => {
  state.isLearnLoading = true
  const { data, status } = await api.get('/dataset?takeAll=1')
  const dsLists = data.data
  if (status === 200) {
    if (Array.isArray(dsLists)) {
      state._body = dsLists.map((dataset) => {
        return dataset
      })
      dataset.tabs.value[1].count = state._body.length
    }
  }
  state.isLearnLoading = false
}

const uploadDatasets = () => {
  state.modalOpen = true

  /* initialize variable */
  state.dsUploadStatus = 2
  state.selectType = SELECT_TYPE_MAP.IMAGE
  state.files = []
  state.createDsName = ''
  state.createDsDesc = ''
  state.error = ERROR_MAP.NONE
}

const setSelectType = (type) => {
  state.selectType = type
}

const uploadModalClose = () => {
  state.modalOpen = false
}

const closeServerModal = () => {
  state.serverModalOpen = false
}

const openServerModal = () => {
  state.serverModalOpen = true
  state.dsUploadStatus = 2
  state.selectType = SELECT_TYPE_MAP.IMAGE
  state.files = []
  state.createDsName = ''
  state.createDsDesc = ''
  state.createDsPath = ''
  state.error = ERROR_MAP.NONE
}

const changeDsUpload = (status) => {
  state.dsUploadStatus = status
}

const dragOver = (e) => {
  e.preventDefault()
  e.stopPropagation()
  state.dragHover = true
}

const dragLeave = () => {
  state.dragHover = false
}

const drop = (e) => {
  e.preventDefault()
  state.dragHover = false
  let files = []
  if (e.dataTransfer.items.length > 0) {
    if (e.dataTransfer.items[0].kind === 'file') {
      files = e.dataTransfer.files
    }
  } else if (e.dataTransfer.files.length > 0) {
    files = e.dataTransfer.files
  }
  if (files.length > 0) {
    validateFile(files)
  }
}

const postDatasetsZip = (uploadEndPoint) => {
  changeDsUpload(3)
  const data = new FormData()
  if (
    [SELECT_TYPE_MAP.TABLE, SELECT_TYPE_MAP.TEXT].includes(state.selectType)
  ) {
    const zip = new JSZip()
    zip.file(state.files[0].name, state.files[0])
    zip
      .generateAsync({ type: 'blob', compression: 'DEFLATE' })
      .then((content) => {
        data.append('files', content)
        uploadHelper.upload(uploadEndPoint, data)
      })
  } else {
    data.append('files', state.files[0])
    uploadHelper.upload(uploadEndPoint, data)
  }
}

const getFileSize = (size) => {
  return formatBytes(size / 1024)
}

const activeButton = () => {
  if (state.files.length === 0 || state.createDsName.length === 0) {
    return true
  }
  return false
}

const isServerButtonDisabled = () => {
  if (state.createDsName.length < 2) {
    return true
  }
  return false
}

const createDatasets = async () => {
  state.createError = ''
  try {
    const url = 'file-manager'
    const { data: crDs, status } = await api.post(
      url,
      JSON.stringify({
        type: api.UPLOAD_TYPE(),
        name: state.createDsName,
        description: state.createDsDesc,
        datasetType: SELECT_TYPE_MAP[state.selectType],
        isPublic: state.createDsIsPublic,
      })
    )

    if (status === 200) {
      postDatasetsZip(crDs.uploadEndpoint)
    } else if (status === 409) {
      state.createError = $t('mesg.js.same_name')
    } else {
      state.createError = $t('mesg.js.upload_error')
    }
  } catch (e) {
    state.createError = $t('mesg.js.network_error')
  }
}

const removeFiles = () => {
  state.files = []
}

/**
 * 폴더나 압축하면서 생성되는 파일은 무시하기 위해 체크한다.
 */
const _needToCheckFileExtension = (fileName) => {
  if (fileName.includes('__MACOSX/')) {
    return false
  }
  if (fileName.endsWith('/')) {
    return false
  }
  if (fileName.endsWith('.DS_Store')) {
    return false
  }
  return true
}

const validateFileByType = async (file) => {
  if (
    [SELECT_TYPE_MAP.TABLE, SELECT_TYPE_MAP.TEXT].includes(state.selectType)
  ) {
    if (!file.name.endsWith(EXTENSIONS_BY_TYPE[state.selectType])) {
      return ERROR_MAP.NOT_EXTENSIONS
    }
  } else {
    if (!file.name.endsWith('.zip')) {
      return ERROR_MAP.NOT_ZIP
    }
    if (SELECT_TYPE_MAP.IMAGE === state.selectType) {
      return await validateFilesInImageDatasetZip(file)
    }
  }
  return ERROR_MAP.NONE
}

/**
 * 이미지 데이터셋 압축파일 안의 파일들을 검증한다.
 * 이미지 타입에 허용되는 확장자 파일이 아닌 경우보다 많아야 한다.
 */
const validateFilesInImageDatasetZip = async (file) => {
  const fileNames = []
  const zip = await JSZip.loadAsync(file)
  zip.forEach((relativePath, _) => {
    fileNames.push(relativePath)
  })
  const extensionCount = {
    selectTypeExtension: 0,
    nonSelectTypeExtension: 0,
  }
  for (const fileName of fileNames) {
    if (!_needToCheckFileExtension(fileName)) {
      continue
    }
    if (
      EXTENSIONS_BY_TYPE[state.selectType].some((_ext) =>
        fileName.endsWith(_ext)
      )
    ) {
      extensionCount.selectTypeExtension++
    } else {
      extensionCount.nonSelectTypeExtension++
    }
  }
  if (
    extensionCount.selectTypeExtension > extensionCount.nonSelectTypeExtension
  ) {
    return ERROR_MAP.NONE
  }
  return ERROR_MAP.NOT_EXTENSIONS
}

const validateFile = (files) => {
  if (files.length > 0) {
    state.files = []
    validateFileByType(files[0])
      .then((e) => {
        state.error = e
        if (state.error === ERROR_MAP.NONE) {
          state.files = files
          state._createDsName = files[0].name
        }
      })
      .catch()
  }
}

const moveDatasets = async () => {
  state.createError = ''
  try {
    const isImage = state.selectType === SELECT_TYPE_MAP.IMAGE
    const isGeneral = state.selectType === SELECT_TYPE_MAP.GENERAL

    let url = 'dataset/local/'
    if (isImage) {
      url = 'filestore/data/raw/local/image'
    }
    if (isGeneral) {
      url = 'dataset/local/general'
    }

    setTimeout(() => {
      state.isServerUploading = true
    })

    const { status } = await api.post(
      url,
      JSON.stringify({
        path: state.createDsPath,
        name: state.createDsName,
        description: state.createDsDesc,
      })
    )

    if (status === 200 || status === 201) {
      closeServerModal()
    } else {
      toastState.addToast({
        status: 'error',
        message: `${$t('mesg.helper.file_move_fail')}`,
      })
    }
    state.isServerUploading = false
  } catch (e) {
    state.createError = $t('mesg.js.network_error')
  }
}

export default function useLearnDatasetState() {
  return {
    ...toRefs(state),
    learnBody,
    activeButton,
    fetchLearnDataset,
    fetchLearnDatasets,
    changeLearnPagination,
    dragOver,
    dragLeave,
    drop,
    validateFile,
    getFileSize,
    setSelectType,
    uploadDatasets,
    changeDsUpload,
    createDatasets,
    postDatasetsZip,
    removeFiles,
    uploadModalClose,
    openImageInfo,
    closeImageInfo,
    closeServerModal,
    openServerModal,
    isServerButtonDisabled,
    moveDatasets,
    SELECT_TYPE_MAP,
    ERROR_MAP,
    TYPE_LIST,
  }
}
