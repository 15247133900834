//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    textonly: Boolean,
  },
  setup(props, { emit }) {
    const tagClick = () => emit('click')
    const exitClick = () => emit('remove')
    return {
      props,
      tagClick,
      exitClick,
    }
  },
}
