import { computed, reactive, toRefs } from '@nuxtjs/composition-api'
import axios from 'axios'
import api from '@/util/api'
import useDatasetFilterHelper from '@/helpers/dataset/datasetFilterHelper'
import useI18N from '@/translations/i18n'
import useDatasetState from './datasetState'
const $t = useI18N()

const dataset = useDatasetState()
const { datasetFilterCallback } = useDatasetFilterHelper()

const state = reactive({
  files: [],
  selected: [],

  asStatus: 1,
  dragHover: false,

  learnValue: 0,
  formatValue: 0,

  rawDatasetId: '',
  annotationModal: false,

  _annotationName: '',
  get annotationName() {
    return this._annotationName
  },
  set annotationName(val) {
    this.annotationName = val
  },

  _annotationDesc: '',
  get annotationDesc() {
    return this._annotationDesc
  },
  set annotationDesc(val) {
    this._annotationDesc = val
  },

  _body: [],

  _query: '',
  get query() {
    return this._query
  },
  set query(query) {
    this._query = query
    this.rawPagination.index = 1
  },

  rawHeader: [
    { text: $t('mesg.js.owner'), size: 120 },
    { text: $t('mesg.js.dataset'), flex: 1 },
    { text: $t('mesg.js.type'), size: 130, align: 'left' },
    { text: $t('mesg.js.count'), size: 90, align: 'right' },
    { text: $t('mesg.js.modify_time'), size: 110, align: 'center' },
    { text: $t('mesg.js.size'), size: 110, align: 'right' },
  ],

  rawPagination: {
    count: 10,
    max: 1,
    index: 1,
  },

  isRawLoading: false,
})

const openAnnotationModal = (rawId) => {
  state.annotationModal = true

  /* Initialize Modal */
  changeAnnotation(1)
  state.rawDatasetId = rawId
}

const closeAnnotationModal = () => {
  clearAnnotationState()
  state.annotationModal = false
}

const clearAnnotationState = () => {
  state.learnValue = 0
  state.formatValue = 0

  state.annotationName = ''
  state.annotationDesc = ''
}

const rawBody = computed(() => {
  const filtered = state._body.filter(datasetFilterCallback)
  const _sub = filtered.filter((row) => {
    const totalStr = (row.name + ' ' + row.description).toLowerCase()
    return totalStr.includes(state.query.toLowerCase())
  })

  state.rawPagination.max = parseInt(
    (_sub.length - 1) / state.rawPagination.count + 1,
    10
  )

  const from = state.rawPagination.count * (state.rawPagination.index - 1)
  const to = Math.min(
    state.rawPagination.count,
    state.rawPagination.max * state.rawPagination.count - from
  )

  _sub.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  dataset.tabs.value[0].count = _sub.length

  return _sub.slice(from, from + to)
})

const changeAnnotation = (index) => {
  state.asStatus = index
}

const dragOver = (e) => {
  e.preventDefault()
  e.stopPropagation()
  state.dragHover = true
}

const dragLeave = () => {
  state.dragHover = false
}

const drop = (e) => {
  e.preventDefault()
  state.dragHover = false
  let Files = []
  if (e.dataTransfer.items.length > 0) {
    if (e.dataTransfer.items[0].kind === 'file') {
      Files = e.dataTransfer.files
    }
  } else if (e.dataTransfer.files.length > 0) {
    Files = e.dataTransfer.files
  }
  processFile(Files)
}

const returnLearnType = () => {
  switch (state.learnValue) {
    case 0:
      return 'IMAGE_CLASSIFICATION'
    case 1:
      return 'IMAGE_DETECTION'
    case 2:
      return 'IMAGE_SEGMENTATION'
  }
  return null
}

const changeRawPagination = (index) => {
  Object.assign(state.rawPagination, {
    index,
  })
}

const postAnnotationZip = async (uploadEndPoint) => {
  const data = new FormData()
  state.files.forEach((zipFile) => data.append('', zipFile))

  const response = await axios.put(uploadEndPoint, data)

  if (response.status === 200) {
    changeAnnotation(3)
  }
}

const createAnnotation = async () => {
  const { data: crAt, status } = await api.post(
    '/annotation',
    JSON.stringify({
      rawDatasetId: state.rawDatasetId,
      name: state.annotationName,
      description: state.annotationDesc,
      type: returnLearnType(),
    })
  )

  if (status === 200) {
    await postAnnotationZip(crAt.uploadEndPoint)
  } else {
    changeAnnotation(4)
  }
}

function fetchRawdataset(id) {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        await _fetchRawdataset(id)
        resolve()
      } catch (error) {
        reject(error)
      }
    })()
  })
}

const _fetchRawdataset = async (id) => {
  state.isRawLoading = true
  const response = await api.get(`/filestore/data/raw/${id}`)
  if (response.status === 200) {
    const currentRawDatasets = [...state._body]
    const index = currentRawDatasets.findIndex(
      (item) => item.id === response.data.id
    )
    if (index !== -1) {
      currentRawDatasets[index] = response.data
    } else {
      currentRawDatasets.push(response.data)
    }
    currentRawDatasets.sort((a, b) => a.created_at - b.created_at)
    state._body = [...currentRawDatasets]
  }
  state.isRawLoading = false
}

const fetchRawDatasets = async () => {
  state.isRawLoading = true
  const { data, status } = await api.get('/filestore/data/raw?&takeAll=1')
  const dsLists = data.data
  if (status === 200) {
    if (Array.isArray(dsLists)) {
      state._body = dsLists.map((dataset) => {
        return dataset
      })
      dataset.tabs.value[0].count = state._body.length
    }
  }
  state.isRawLoading = false
}

const removeFiles = () => {
  state.files = {}
}

const processFile = (file) => {
  state.files = file
}

export default function useRawDatasetSate() {
  return {
    ...toRefs(state),
    rawBody,
    dragOver,
    dragLeave,
    drop,
    openAnnotationModal,
    closeAnnotationModal,
    clearAnnotationState,
    changeAnnotation,
    changeRawPagination,
    postAnnotationZip,
    createAnnotation,
    fetchRawDatasets,
    fetchRawdataset,
    returnLearnType,
    removeFiles,
    processFile,
  }
}
