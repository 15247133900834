import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
} from '@nuxtjs/composition-api'
import dayjs from 'dayjs'

// Notification Object
// {
//   numId: 0                    // auto filled
//   id: 'some-key',
//   icon: 'cloud_upload',
//   title: 'Some Title',
//   error: '',
//   status: 0,                  // 0 = RUNNING, 1 = FINISH, 2 = FAILED
//   content: 'Some Content',
//   date: '5월 2일',
// }

const state = reactive({
  notifications: [],
  notificationIdIndex: 0,
  isClosed: true,
  notiRef: null,
})

const hasNotification = computed(() => {
  return state.notifications.length > 0
})

function addNotification(notification) {
  const temporal = {
    icon: 'help_outline',
    title: '',
    error: '',
    status: 0,
    content: '',
  }
  const _notification = Object.assign(temporal, notification)
  if (_notification.title === '') return
  _notification.numId = state.notificationIdIndex++
  if (!_notification.id) {
    _notification.id = 'notify-temporal-' + _notification.numId
  }
  if (!_notification.date) {
    _notification.date = dayjs()
  }
  state.notifications = [_notification, ...state.notifications]
  return _notification.numId
}

function findNotification(key) {
  return state.notifications.find(
    ({ numId, id }) => numId === key || id === key
  )
}

function removeNotification(id) {
  const _notification = findNotification(id)
  if (_notification) {
    state.notifications.splice(state.notifications.indexOf(_notification), 1)
    return true
  }
  return false
}

function updateNotification(key, props) {
  const _notification = findNotification(key)
  if (_notification) {
    Object.keys(props).forEach((_key) => {
      _notification[_key] = props[_key]
    })
  }
}

function clearNotification() {
  state.notifications = []
}

function toggleClosed(value) {
  state.isClosed = value !== undefined ? !!value : !state.isClosed
}

function handleDocClickForNoti(e) {
  if (e.target && e.target.classList.contains('ns-notification-exit')) {
    // 삭제 아이콘 클릭시 의도하지 않는 동작이 있어 임시 추가
    return
  }
  if (!state.isClosed && state.notiRef && !state.notiRef.contains(e.target)) {
    state.isClosed = true
  }
}

export default function useNotificationState(notiRef) {
  if (notiRef) {
    state.notiRef = notiRef
    // 해당 모달(모달을 보여주기 위해 선택하는 태그를) 이외의 부분을 클릭한 경우 모달을 닫기 위함
    onMounted(() => {
      document.addEventListener('click', handleDocClickForNoti)
    })

    onUnmounted(() => {
      document.removeEventListener('click', handleDocClickForNoti)
    })
  }

  return {
    ...toRefs(state),
    addNotification,
    removeNotification,
    updateNotification,
    findNotification,
    hasNotification,
    toggleClosed,
    clearNotification,
  }
}
