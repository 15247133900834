import { reactive, toRefs } from '@nuxtjs/composition-api'

const DEFAULT_SECS_TO_LIVE = 3 * 1000 // 3초
const MAX_TOASTS = 6 // 동시에 최대 토스트 개수
const state = reactive({
  toasts: [],
  toastIdIndex: 0,
  toastHandlers: {},
  eventListeners: [],
})

const addToast = (toast) => {
  // @daehee-neuralworks 2024/03/03
  // 한 화면에 토스트가 여러개 될 때는 화면에 표시가 되지 않는 현상 있음.
  // 최대 개수가 차면 다시 0부터 시작하기 구현 시도.
  if (state.toasts.length >= MAX_TOASTS) {
    // 가장 오래된 토스트의 id를 찾아 제거
    // const oldestToastId = state.toasts[0].id
    // @daehee-neuralworks 2024/03/05 removeToastRequest() 로 인해 오류 발생이 의심됨.
    // removeToastRequest(oldestToastId)

    // nextTick(() => {
      // DOM 업데이트가 완료된 후 새 토스트 추가
      // addToast(toast)  // addToast() 에서 오류 발생
    // })
    return
  }

  toast.id = state.toastIdIndex++ 
  // @daehee-neuralworks 아래와 같이 array.length로 변경
  // toast.id = state.toasts.length
  // state.toastIdIndex = state.toasts.length + 1
  toast.duration = parseInt(toast.duration) || DEFAULT_SECS_TO_LIVE
  if (toast.status === 'error') {
    toast.color = '#F56161'
    toast.icon = 'error_outline'
  }

  state.toasts.push(toast)
  state.toastHandlers[toast.id] = setTimeout(
    () => removeToastRequest(toast.id),
    toast.duration + 300
  )
  return toast.id
}

const pauseToast = (toastId) => {
  // @daehee-neuralworks 사용자가 토스트 내용을 보려고 마우스 오버 했을 때
  // 토스트 사라지지 않게 하는 기능
  const targetToast = state.toasts.find(({ id }) => toastId === id)
  if (targetToast) {
    clearTimeout(state.toastHandlers[toastId])
  }
}

const resumeToast = (toastId) => {
  // @daehee-neuralworks 사용자가 토스트 내용을 다 읽고 떠나는 경우
  // 그냥 0.5 초 이후에 사라지게 하자.

  const targetToast = state.toasts.find(({ id }) => toastId === id)
  if (targetToast) {
    clearTimeout(state.toastHandlers[toastId])
    state.toastHandlers[toastId] = setTimeout(
      () => removeToastRequest(toastId),
      500 // @daehee-neuralworks 0.5초
      // targetToast.duration // 기존 코드
    )
  }
}

const removeToastRequest = (toastId) => {
  const targetToast = state.toasts.find(({ id }) => toastId === id)
  if (targetToast) {
    if (targetToast.elem) {
      targetToast.elem.closeThisToast()
      // @daehee-neuralworks: removeToast(toastId) 추가
      removeToast(toastId)
    } else {
      removeToast(toastId)
    }
  }
}

const removeToast = (toastId) => {
  const targetToast = state.toasts.find(({ id }) => toastId === id)
  if (targetToast) {
    state.toasts.splice(state.toasts.indexOf(targetToast), 1)
  }
}

const bindToastElement = (toastId, toastElement) => {
  const targetToast = state.toasts.find(({ id }) => toastId === id)
  if (targetToast) {
    targetToast.elem = toastElement
  }
}

const dispatchEvent = (toastId) => {
  const removeQueue = []
  state.eventListeners.forEach(({ callback, id, onlyOnce }, i) => {
    if (id === toastId || id === -1) {
      callback(toastId)
      if (onlyOnce) {
        removeQueue.push(i)
      }
    }
  })
}

const addEventListener = (callback, id = -1, onlyOnce = false) => {
  state.eventListeners.push({
    callback,
    id,
    onlyOnce,
  })
}

const removeEventListener = (callback, id = -1) => {
  for (let i = 0; i < state.eventListeners.length; i++) {
    if (
      state.eventListeners[i].callback === callback &&
      state.eventListeners[i].id === id
    ) {
      state.eventListeners.splice(i, 1)
      break
    }
  }
}

export default function useToastState() {
  return {
    ...toRefs(state),
    addToast,
    removeToast,
    pauseToast,
    resumeToast,
    bindToastElement,
    addEventListener,
    dispatchEvent,
    removeEventListener,
  }
}
