//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs'
import useNotificationState from '../../state/ns/notifyState'
export default {
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { isClosed, removeNotification } = useNotificationState()
    return {
      dayjs,
      isClosed,
      removeNotification,
    }
  },
}
