//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  computed,
  getCurrentInstance,
  onMounted,
  onUpdated,
  ref,
} from '@nuxtjs/composition-api'
export default {
  props: {
    initIndex: {
      type: Number,
      default: -1,
    },
    head: {
      type: Array,
      default: () => [],
    },
    body: {
      type: Array,
      default: () => null,
    },
    auto: Boolean,
  },
  setup(props, { slots, emit, ...e }) {
    const bodyText = (text) => {
      if (typeof text === 'string') {
        return { text }
      }
      return text
    }
    const headText = (text, index) => {
      sizing.value[index] = { 'flex-shrink': 0 }
      if (text.flex) {
        Object.assign(sizing.value[index], {
          flex: text.flex + ' 1 0',
          'min-width': 0,
        })
      } else if (text.size) {
        Object.assign(sizing.value[index], { width: text.size + 'px' })
      } else if (text.percent) {
        Object.assign(sizing.value[index], { width: text.percent + '%' })
      }
      if (text.align) {
        Object.assign(sizing.value[index], { 'text-align': text.align })
      }
      return bodyText(text)
    }
    const rowIndex = ref(props.initIndex)
    const sizing = ref([])
    const headers = computed(() => props.head.map(headText))
    const bodies = props.body
      ? computed(() => (props.body || []).map((e) => e.map(bodyText)))
      : null
    const styleRef = ref(null)
    const uid = ref('')
    const changeRow = (e) => {
      const index = parseInt(e.currentTarget.getAttribute('index'), 10)
      if (index > -1) {
        rowIndex.value = rowIndex.value === index ? -1 : index
        emit('change', index)
      }
    }

    const updateSize = () => {
      uid.value = 'table-body-' + getCurrentInstance().uid
      let style = `/* ${props.head.length} */`
      sizing.value.forEach((size, i) => {
        style += `.ns-table-body.table-body-${
          getCurrentInstance().uid
        } > .ns-table-row > div:nth-child(${i + 1}){`
        Object.entries(size).map(
          ([key, value]) => (style += `${key}: ${value};`)
        )
        style += '}'
      })
      styleRef.value.textContent = style
    }

    onMounted(updateSize)
    onUpdated(updateSize)

    return {
      rowIndex,
      headers,
      bodies,
      sizing,
      changeRow,
      slots,
      styleRef,
      uid,
    }
  },
}
