import { render, staticRenderFns } from "./NsToastBox.vue?vue&type=template&id=1f1e888e&scoped=true&"
import script from "./NsToastBox.vue?vue&type=script&lang=js&"
export * from "./NsToastBox.vue?vue&type=script&lang=js&"
import style0 from "./NsToastBox.vue?vue&type=style&index=0&id=1f1e888e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f1e888e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NsToast: require('/usr/app/src/components/ns/NsToast.vue').default})
