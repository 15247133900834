import translations from '@/translations'
import overrides from '@/translations/overrides'

function assignNested(target, source) {
  Object.keys(source).forEach((sourcekey) => {
    const finded = Object.keys(source).find(
      (targetkey) => targetkey === sourcekey
    )
    if (finded !== undefined && typeof source[sourcekey] === 'object') {
      target[sourcekey] = assignNested(target[sourcekey], source[sourcekey])
    } else {
      target[sourcekey] = source[sourcekey]
    }
  })
  return target
}

export default function useI18nHelper(context) {
  const { TRANSLATE_OVERRIDE } = context.$config
  if (TRANSLATE_OVERRIDE && overrides[TRANSLATE_OVERRIDE]) {
    const { overrideLanguage, fields } = overrides[TRANSLATE_OVERRIDE]
    if (overrideLanguage && fields) {
      assignNested(translations[overrideLanguage], fields)
      // direct override context._i18n.setLocaleMessage
    }
  }
}
