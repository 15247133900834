//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api'
export default {
  props: {
    large: Boolean,
    medium: Boolean,
    small: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    rounded: Boolean,
    inverted: Boolean,
    plus: Boolean,
    left: Boolean,
    full: Boolean,
    loading: Boolean,
    secondary: Boolean,
    tab: Boolean,
    selected: Boolean,
    badge: Boolean,
    color: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const customClass = computed(() => {
      let _class = ''
      _class = props.large
        ? 'large'
        : props.medium
        ? 'medium'
        : props.small
        ? 'small'
        : ''
      if (_class !== 'small') {
        _class += props.outlined ? ' outlined' : ' normal'
      }
      if (props.full) {
        _class += ' fulled'
      }
      if (props.plus || props.icon || props.left) {
        _class += ' icon'
      }
      _class += props.disabled || props.loading ? ' disabled' : ''
      _class += props.rounded ? ' rounded' : ''
      _class += props.secondary ? ' secondary' : ''
      _class += props.tab ? ' tab' : ''
      _class += props.selected ? ' selected' : ''
      _class += props.inverted ? ' inverted' : ''
      return _class
    })

    const nsClick = (e) =>
      !(props.disabled || props.loading) && emit('click', e)
    return { props, customClass, nsClick }
  },
}
