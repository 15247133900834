import useGlobal from '@/util/global'
import storage from '@/util/storage'

function checkAuth(context) {
  if (!context.route.path.includes('auth')) {
    const token = storage.getToken()
    if (token === undefined) {
      return context.redirect('/auth/signin')
    }
  }
}
export default function (context) {
  if (!process.server) {
    checkAuth(context)
  }
  useGlobal().setEnv(context.$config)
}
