//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, onUpdated, ref } from '@nuxtjs/composition-api'
import { castFixed } from '../../util/numeric'
export default {
  props: {
    head: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => [],
    },
    noNumber: Boolean,
    noLine: Boolean,
    accents: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => null,
    },
    values: {
      type: Array,
      default: () => [],
    },
    editable: Boolean,
    white: Boolean,
    summary: Boolean,
    json: Boolean,
    border: Boolean,
    wide: Boolean,
  },
  setup(props, { emit }) {
    const targetCell = ref(null)
    const theadRef = ref(null)
    const tbodyRef = ref(null)
    const sizes = ref(null)

    function optionChange(index, option) {
      emit('selectChange', index, option)
    }
    function contentEditCurrying(target) {
      return function (e) {
        if (e.keyCode === 13 || e.type === 'blur') {
          e.preventDefault()
          if (targetCell.value) {
            target.removeEventListener('keydown', targetCell.value.func)
            target.removeEventListener('blur', targetCell.value.func)
            target.contentEditable = false
            targetCell.value = null
          }
        }
      }
    }
    function onDbClick(e) {
      if (!props.editable || !e.target.tagName === 'TD') return
      const target = e.target
      if (targetCell.value && targetCell.value !== target) {
        const { elem, func: _func } = targetCell.value
        elem.removeEventListener('keydown', _func)
        target.removeEventListener('blur', _func)
        elem.contentEditable = false
        const func = contentEditCurrying(target)
        const obj = { elem: target, func }
        targetCell.value = obj
        e.target.contentEditable = true
        e.target.focus()
        e.target.addEventListener('keydown', func)
        e.target.addEventListener('blur', func)
      } else {
        const func = contentEditCurrying(target)
        const obj = { elem: target, func }
        targetCell.value = obj
        e.target.contentEditable = true
        e.target.focus()
        e.target.addEventListener('keydown', func)
        e.target.addEventListener('blur', func)
      }
    }
    function formatValue(value) {
      const floatRegex = /^([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[eE]([+-]?\d+))?$/
      if (floatRegex.test(value)) {
        if (Number.isInteger(value)) {
          return parseInt(value)
        }
        value = parseFloat(value)
        if (!isNaN(value) && !Number.isInteger(value)) {
          return castFixed(value, 3)
        }
      }
      return value
    }
    function parseJson(text) {
      try {
        if (text.name) {
          text = text.value
        }
        if (text.startsWith('[')) {
          const json = JSON.parse(text.trim())
          if (Array.isArray(json)) {
            if (Array.isArray(json[0])) {
              let arr = json.map((_json) =>
                _json.map((__json) => formatValue(__json)).join(', ')
              )
              const ov = arr.length > 3
              arr = arr.slice(0, Math.min(3, arr.length))
              if (ov) arr.push('...')
              return arr
            }
            let arr = json.map((_json) => formatValue(_json))
            const ov = arr.length > 3
            arr = arr.slice(0, Math.min(3, arr.length))
            if (ov) arr.push('...')
            return arr
          }
        }
        while (text.includes("'")) {
          text = text.replace("'", '"')
        }
      } catch (e) {
        // console.error(e)
      }
      return [formatValue(text)]
    }
    function calcTable() {
      if (theadRef.value) {
        const _sizes = [...theadRef.value.children].map((th) => {
          const thw = parseFloat(getComputedStyle(th).width)
          return thw
        })

        sizes.value = _sizes
      }
    }
    function getType(name) {
      if (props.types) {
        const type = props.types[name || '']
        if (type) {
          return type
            .toLowerCase()
            .replace(/[0-9]/g, '')
            .replace('object', 'str')
            .replace('string', 'str')
            .replace('float', 'db')
            .replace('integer', 'int')
        }
      }
      return false
    }
    function isAccented(index) {
      return props.accents.includes(index)
    }
    function tableClick(event) {
      if (event.path) {
        const tbody = event.path.find((elem) => elem.tagName === 'TBODY')
        const tr = event.path.find((elem) => elem.tagName === 'TR')
        const td = event.path.find((elem) => elem.tagName === 'TD')
        if (tbody && tr && td) {
          const row = [...tbody.children].indexOf(tr)
          const col = [...tr.children].indexOf(td) - (props.noNumber ? 0 : 1)
          if (col !== -1) {
            const head = props.head[col]
            let data = props.data[row][col] || props.data[row][head]
            try {
              data = JSON.parse(data)
            } catch (e) {}
            emit(
              'click',
              row,
              col,
              data,
              head,
              tbody.children.length,
              tr.children.length - (props.noNumber ? 0 : 1)
            )
          }
        }
      }
    }
    onUpdated(() => {
      // calcTable()
    })
    onMounted(() => {
      calcTable()
    })
    return {
      onDbClick,
      targetCell,
      parseJson,
      theadRef,
      tbodyRef,
      isAccented,
      sizes,
      getType,
      tableClick,
      optionChange,
      castFixed,
    }
  },
}
