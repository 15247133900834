//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useTooltipState from '../../state/ns/tooltipState'

export default {
  setup() {
    const tooltipState = useTooltipState()
    return {
      ...tooltipState,
    }
  },
}
