import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31362c42 = () => interopDefault(import('../src/pages/annotation/index.vue' /* webpackChunkName: "pages/annotation/index" */))
const _0dd0dedf = () => interopDefault(import('../src/pages/comp.vue' /* webpackChunkName: "pages/comp" */))
const _a71e8438 = () => interopDefault(import('../src/pages/comp3.vue' /* webpackChunkName: "pages/comp3" */))
const _579a3c08 = () => interopDefault(import('../src/pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _22a19b0e = () => interopDefault(import('../src/pages/datasets/index.vue' /* webpackChunkName: "pages/datasets/index" */))
const _3fbe07fa = () => interopDefault(import('../src/pages/deploy/index.vue' /* webpackChunkName: "pages/deploy/index" */))
const _59cdc69c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5d298478 = () => interopDefault(import('../src/pages/handling/index.vue' /* webpackChunkName: "pages/handling/index" */))
const _f6366f1e = () => interopDefault(import('../src/pages/instruction/index.vue' /* webpackChunkName: "pages/instruction/index" */))
const _56dbf8c8 = () => interopDefault(import('../src/pages/invite/index.vue' /* webpackChunkName: "pages/invite/index" */))
const _245b5e40 = () => interopDefault(import('../src/pages/job/index.vue' /* webpackChunkName: "pages/job/index" */))
const _03966771 = () => interopDefault(import('../src/pages/katex.vue' /* webpackChunkName: "pages/katex" */))
const _27d81e2c = () => interopDefault(import('../src/pages/machine/index.vue' /* webpackChunkName: "pages/machine/index" */))
const _403d37ae = () => interopDefault(import('../src/pages/membership/index.vue' /* webpackChunkName: "pages/membership/index" */))
const _956e3168 = () => interopDefault(import('../src/pages/model/index.vue' /* webpackChunkName: "pages/model/index" */))
const _7bd488f0 = () => interopDefault(import('../src/pages/pay.vue' /* webpackChunkName: "pages/pay" */))
const _3beb63cc = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _78ddaf75 = () => interopDefault(import('../src/pages/view.vue' /* webpackChunkName: "pages/view" */))
const _5e18c490 = () => interopDefault(import('../src/pages/workspace/index.vue' /* webpackChunkName: "pages/workspace/index" */))
const _164f303f = () => interopDefault(import('../src/pages/annotation/create/index.vue' /* webpackChunkName: "pages/annotation/create/index" */))
const _1ea7f628 = () => interopDefault(import('../src/pages/auth/kakao.vue' /* webpackChunkName: "pages/auth/kakao" */))
const _f0351b9e = () => interopDefault(import('../src/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _72980049 = () => interopDefault(import('../src/pages/auth/signin.vue' /* webpackChunkName: "pages/auth/signin" */))
const _587126c8 = () => interopDefault(import('../src/pages/handling/eda.vue' /* webpackChunkName: "pages/handling/eda" */))
const _23744644 = () => interopDefault(import('../src/pages/handling/merge.vue' /* webpackChunkName: "pages/handling/merge" */))
const _70f4fb2b = () => interopDefault(import('../src/pages/membership/history.vue' /* webpackChunkName: "pages/membership/history" */))
const _4b7088bc = () => interopDefault(import('../src/pages/annotation/detail/_id.vue' /* webpackChunkName: "pages/annotation/detail/_id" */))
const _16ab68c7 = () => interopDefault(import('../src/pages/annotation/tool/_job/index.js' /* webpackChunkName: "pages/annotation/tool/_job/index" */))
const _4540946e = () => interopDefault(import('../src/pages/auth/signup/_type.vue' /* webpackChunkName: "pages/auth/signup/_type" */))
const _d3d30d20 = () => interopDefault(import('../src/pages/datasets/detail/_id.vue' /* webpackChunkName: "pages/datasets/detail/_id" */))
const _5d3e40d0 = () => interopDefault(import('../src/pages/instruction/create/_id.vue' /* webpackChunkName: "pages/instruction/create/_id" */))
const _76986faa = () => interopDefault(import('../src/pages/auth/c/_id/signin.vue' /* webpackChunkName: "pages/auth/c/_id/signin" */))
const _e9a273c0 = () => interopDefault(import('../src/pages/auth/c/_id/signup.vue' /* webpackChunkName: "pages/auth/c/_id/signup" */))
const _4852eb4c = () => interopDefault(import('../src/pages/annotation/tool/_job/_task.vue' /* webpackChunkName: "pages/annotation/tool/_job/_task" */))
const _8cae0b2a = () => interopDefault(import('../src/pages/datasets/jspre/_type/_id.vue' /* webpackChunkName: "pages/datasets/jspre/_type/_id" */))
const _22268594 = () => interopDefault(import('../src/pages/datasets/pre/_type/_id.vue' /* webpackChunkName: "pages/datasets/pre/_type/_id" */))
const _18f0eb0d = () => interopDefault(import('../src/pages/membership/purchase/_plan/_month.vue' /* webpackChunkName: "pages/membership/purchase/_plan/_month" */))
const _35c187e0 = () => interopDefault(import('../src/pages/datasets/preproc/_proc/_type/_id.vue' /* webpackChunkName: "pages/datasets/preproc/_proc/_type/_id" */))
const _4986572a = () => interopDefault(import('../src/pages/annotation/_id.vue' /* webpackChunkName: "pages/annotation/_id" */))
const _a751ee14 = () => interopDefault(import('../src/pages/datasets/_id.vue' /* webpackChunkName: "pages/datasets/_id" */))
const _5941c0e2 = () => interopDefault(import('../src/pages/deploy/_id.vue' /* webpackChunkName: "pages/deploy/_id" */))
const _217f0b48 = () => interopDefault(import('../src/pages/job/_id.vue' /* webpackChunkName: "pages/job/_id" */))
const _d77f6698 = () => interopDefault(import('../src/pages/model/_id.vue' /* webpackChunkName: "pages/model/_id" */))
const _19bdae20 = () => interopDefault(import('../src/pages/workspace/_id.vue' /* webpackChunkName: "pages/workspace/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/studio/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/annotation",
    component: _31362c42,
    name: "annotation___ko"
  }, {
    path: "/comp",
    component: _0dd0dedf,
    name: "comp___ko"
  }, {
    path: "/comp3",
    component: _a71e8438,
    name: "comp3___ko"
  }, {
    path: "/compare",
    component: _579a3c08,
    name: "compare___ko"
  }, {
    path: "/datasets",
    component: _22a19b0e,
    name: "datasets___ko"
  }, {
    path: "/deploy",
    component: _3fbe07fa,
    name: "deploy___ko"
  }, {
    path: "/en",
    component: _59cdc69c,
    name: "index___en"
  }, {
    path: "/handling",
    component: _5d298478,
    name: "handling___ko"
  }, {
    path: "/instruction",
    component: _f6366f1e,
    name: "instruction___ko"
  }, {
    path: "/invite",
    component: _56dbf8c8,
    name: "invite___ko"
  }, {
    path: "/ja",
    component: _59cdc69c,
    name: "index___ja"
  }, {
    path: "/job",
    component: _245b5e40,
    name: "job___ko"
  }, {
    path: "/katex",
    component: _03966771,
    name: "katex___ko"
  }, {
    path: "/machine",
    component: _27d81e2c,
    name: "machine___ko"
  }, {
    path: "/membership",
    component: _403d37ae,
    name: "membership___ko"
  }, {
    path: "/model",
    component: _956e3168,
    name: "model___ko"
  }, {
    path: "/pay",
    component: _7bd488f0,
    name: "pay___ko"
  }, {
    path: "/profile",
    component: _3beb63cc,
    name: "profile___ko"
  }, {
    path: "/view",
    component: _78ddaf75,
    name: "view___ko"
  }, {
    path: "/workspace",
    component: _5e18c490,
    name: "workspace___ko"
  }, {
    path: "/annotation/create",
    component: _164f303f,
    name: "annotation-create___ko"
  }, {
    path: "/auth/kakao",
    component: _1ea7f628,
    name: "auth-kakao___ko"
  }, {
    path: "/auth/logout",
    component: _f0351b9e,
    name: "auth-logout___ko"
  }, {
    path: "/auth/signin",
    component: _72980049,
    name: "auth-signin___ko"
  }, {
    path: "/en/annotation",
    component: _31362c42,
    name: "annotation___en"
  }, {
    path: "/en/comp",
    component: _0dd0dedf,
    name: "comp___en"
  }, {
    path: "/en/comp3",
    component: _a71e8438,
    name: "comp3___en"
  }, {
    path: "/en/compare",
    component: _579a3c08,
    name: "compare___en"
  }, {
    path: "/en/datasets",
    component: _22a19b0e,
    name: "datasets___en"
  }, {
    path: "/en/deploy",
    component: _3fbe07fa,
    name: "deploy___en"
  }, {
    path: "/en/handling",
    component: _5d298478,
    name: "handling___en"
  }, {
    path: "/en/instruction",
    component: _f6366f1e,
    name: "instruction___en"
  }, {
    path: "/en/invite",
    component: _56dbf8c8,
    name: "invite___en"
  }, {
    path: "/en/job",
    component: _245b5e40,
    name: "job___en"
  }, {
    path: "/en/katex",
    component: _03966771,
    name: "katex___en"
  }, {
    path: "/en/machine",
    component: _27d81e2c,
    name: "machine___en"
  }, {
    path: "/en/membership",
    component: _403d37ae,
    name: "membership___en"
  }, {
    path: "/en/model",
    component: _956e3168,
    name: "model___en"
  }, {
    path: "/en/pay",
    component: _7bd488f0,
    name: "pay___en"
  }, {
    path: "/en/profile",
    component: _3beb63cc,
    name: "profile___en"
  }, {
    path: "/en/view",
    component: _78ddaf75,
    name: "view___en"
  }, {
    path: "/en/workspace",
    component: _5e18c490,
    name: "workspace___en"
  }, {
    path: "/handling/eda",
    component: _587126c8,
    name: "handling-eda___ko"
  }, {
    path: "/handling/merge",
    component: _23744644,
    name: "handling-merge___ko"
  }, {
    path: "/ja/annotation",
    component: _31362c42,
    name: "annotation___ja"
  }, {
    path: "/ja/comp",
    component: _0dd0dedf,
    name: "comp___ja"
  }, {
    path: "/ja/comp3",
    component: _a71e8438,
    name: "comp3___ja"
  }, {
    path: "/ja/compare",
    component: _579a3c08,
    name: "compare___ja"
  }, {
    path: "/ja/datasets",
    component: _22a19b0e,
    name: "datasets___ja"
  }, {
    path: "/ja/deploy",
    component: _3fbe07fa,
    name: "deploy___ja"
  }, {
    path: "/ja/handling",
    component: _5d298478,
    name: "handling___ja"
  }, {
    path: "/ja/instruction",
    component: _f6366f1e,
    name: "instruction___ja"
  }, {
    path: "/ja/invite",
    component: _56dbf8c8,
    name: "invite___ja"
  }, {
    path: "/ja/job",
    component: _245b5e40,
    name: "job___ja"
  }, {
    path: "/ja/katex",
    component: _03966771,
    name: "katex___ja"
  }, {
    path: "/ja/machine",
    component: _27d81e2c,
    name: "machine___ja"
  }, {
    path: "/ja/membership",
    component: _403d37ae,
    name: "membership___ja"
  }, {
    path: "/ja/model",
    component: _956e3168,
    name: "model___ja"
  }, {
    path: "/ja/pay",
    component: _7bd488f0,
    name: "pay___ja"
  }, {
    path: "/ja/profile",
    component: _3beb63cc,
    name: "profile___ja"
  }, {
    path: "/ja/view",
    component: _78ddaf75,
    name: "view___ja"
  }, {
    path: "/ja/workspace",
    component: _5e18c490,
    name: "workspace___ja"
  }, {
    path: "/membership/history",
    component: _70f4fb2b,
    name: "membership-history___ko"
  }, {
    path: "/en/annotation/create",
    component: _164f303f,
    name: "annotation-create___en"
  }, {
    path: "/en/auth/kakao",
    component: _1ea7f628,
    name: "auth-kakao___en"
  }, {
    path: "/en/auth/logout",
    component: _f0351b9e,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/signin",
    component: _72980049,
    name: "auth-signin___en"
  }, {
    path: "/en/handling/eda",
    component: _587126c8,
    name: "handling-eda___en"
  }, {
    path: "/en/handling/merge",
    component: _23744644,
    name: "handling-merge___en"
  }, {
    path: "/en/membership/history",
    component: _70f4fb2b,
    name: "membership-history___en"
  }, {
    path: "/ja/annotation/create",
    component: _164f303f,
    name: "annotation-create___ja"
  }, {
    path: "/ja/auth/kakao",
    component: _1ea7f628,
    name: "auth-kakao___ja"
  }, {
    path: "/ja/auth/logout",
    component: _f0351b9e,
    name: "auth-logout___ja"
  }, {
    path: "/ja/auth/signin",
    component: _72980049,
    name: "auth-signin___ja"
  }, {
    path: "/ja/handling/eda",
    component: _587126c8,
    name: "handling-eda___ja"
  }, {
    path: "/ja/handling/merge",
    component: _23744644,
    name: "handling-merge___ja"
  }, {
    path: "/ja/membership/history",
    component: _70f4fb2b,
    name: "membership-history___ja"
  }, {
    path: "/en/annotation/detail/:id?",
    component: _4b7088bc,
    name: "annotation-detail-id___en"
  }, {
    path: "/en/annotation/tool/:job",
    component: _16ab68c7,
    name: "annotation-tool-job___en"
  }, {
    path: "/en/auth/signup/:type?",
    component: _4540946e,
    name: "auth-signup-type___en"
  }, {
    path: "/en/datasets/detail/:id?",
    component: _d3d30d20,
    name: "datasets-detail-id___en"
  }, {
    path: "/en/instruction/create/:id?",
    component: _5d3e40d0,
    name: "instruction-create-id___en"
  }, {
    path: "/ja/annotation/detail/:id?",
    component: _4b7088bc,
    name: "annotation-detail-id___ja"
  }, {
    path: "/ja/annotation/tool/:job",
    component: _16ab68c7,
    name: "annotation-tool-job___ja"
  }, {
    path: "/ja/auth/signup/:type?",
    component: _4540946e,
    name: "auth-signup-type___ja"
  }, {
    path: "/ja/datasets/detail/:id?",
    component: _d3d30d20,
    name: "datasets-detail-id___ja"
  }, {
    path: "/ja/instruction/create/:id?",
    component: _5d3e40d0,
    name: "instruction-create-id___ja"
  }, {
    path: "/en/auth/c/:id?/signin",
    component: _76986faa,
    name: "auth-c-id-signin___en"
  }, {
    path: "/en/auth/c/:id?/signup",
    component: _e9a273c0,
    name: "auth-c-id-signup___en"
  }, {
    path: "/ja/auth/c/:id?/signin",
    component: _76986faa,
    name: "auth-c-id-signin___ja"
  }, {
    path: "/ja/auth/c/:id?/signup",
    component: _e9a273c0,
    name: "auth-c-id-signup___ja"
  }, {
    path: "/en/annotation/tool/:job?/:task",
    component: _4852eb4c,
    name: "annotation-tool-job-task___en"
  }, {
    path: "/en/datasets/jspre/:type?/:id?",
    component: _8cae0b2a,
    name: "datasets-jspre-type-id___en"
  }, {
    path: "/en/datasets/pre/:type?/:id?",
    component: _22268594,
    name: "datasets-pre-type-id___en"
  }, {
    path: "/en/membership/purchase/:plan?/:month?",
    component: _18f0eb0d,
    name: "membership-purchase-plan-month___en"
  }, {
    path: "/ja/annotation/tool/:job?/:task",
    component: _4852eb4c,
    name: "annotation-tool-job-task___ja"
  }, {
    path: "/ja/datasets/jspre/:type?/:id?",
    component: _8cae0b2a,
    name: "datasets-jspre-type-id___ja"
  }, {
    path: "/ja/datasets/pre/:type?/:id?",
    component: _22268594,
    name: "datasets-pre-type-id___ja"
  }, {
    path: "/ja/membership/purchase/:plan?/:month?",
    component: _18f0eb0d,
    name: "membership-purchase-plan-month___ja"
  }, {
    path: "/en/datasets/preproc/:proc?/:type?/:id?",
    component: _35c187e0,
    name: "datasets-preproc-proc-type-id___en"
  }, {
    path: "/ja/datasets/preproc/:proc?/:type?/:id?",
    component: _35c187e0,
    name: "datasets-preproc-proc-type-id___ja"
  }, {
    path: "/annotation/detail/:id?",
    component: _4b7088bc,
    name: "annotation-detail-id___ko"
  }, {
    path: "/annotation/tool/:job",
    component: _16ab68c7,
    name: "annotation-tool-job___ko"
  }, {
    path: "/auth/signup/:type?",
    component: _4540946e,
    name: "auth-signup-type___ko"
  }, {
    path: "/datasets/detail/:id?",
    component: _d3d30d20,
    name: "datasets-detail-id___ko"
  }, {
    path: "/en/annotation/:id",
    component: _4986572a,
    name: "annotation-id___en"
  }, {
    path: "/en/datasets/:id",
    component: _a751ee14,
    name: "datasets-id___en"
  }, {
    path: "/en/deploy/:id",
    component: _5941c0e2,
    name: "deploy-id___en"
  }, {
    path: "/en/job/:id",
    component: _217f0b48,
    name: "job-id___en"
  }, {
    path: "/en/model/:id",
    component: _d77f6698,
    name: "model-id___en"
  }, {
    path: "/en/workspace/:id",
    component: _19bdae20,
    name: "workspace-id___en"
  }, {
    path: "/instruction/create/:id?",
    component: _5d3e40d0,
    name: "instruction-create-id___ko"
  }, {
    path: "/ja/annotation/:id",
    component: _4986572a,
    name: "annotation-id___ja"
  }, {
    path: "/ja/datasets/:id",
    component: _a751ee14,
    name: "datasets-id___ja"
  }, {
    path: "/ja/deploy/:id",
    component: _5941c0e2,
    name: "deploy-id___ja"
  }, {
    path: "/ja/job/:id",
    component: _217f0b48,
    name: "job-id___ja"
  }, {
    path: "/ja/model/:id",
    component: _d77f6698,
    name: "model-id___ja"
  }, {
    path: "/ja/workspace/:id",
    component: _19bdae20,
    name: "workspace-id___ja"
  }, {
    path: "/auth/c/:id?/signin",
    component: _76986faa,
    name: "auth-c-id-signin___ko"
  }, {
    path: "/auth/c/:id?/signup",
    component: _e9a273c0,
    name: "auth-c-id-signup___ko"
  }, {
    path: "/annotation/tool/:job?/:task",
    component: _4852eb4c,
    name: "annotation-tool-job-task___ko"
  }, {
    path: "/datasets/jspre/:type?/:id?",
    component: _8cae0b2a,
    name: "datasets-jspre-type-id___ko"
  }, {
    path: "/datasets/pre/:type?/:id?",
    component: _22268594,
    name: "datasets-pre-type-id___ko"
  }, {
    path: "/membership/purchase/:plan?/:month?",
    component: _18f0eb0d,
    name: "membership-purchase-plan-month___ko"
  }, {
    path: "/datasets/preproc/:proc?/:type?/:id?",
    component: _35c187e0,
    name: "datasets-preproc-proc-type-id___ko"
  }, {
    path: "/annotation/:id",
    component: _4986572a,
    name: "annotation-id___ko"
  }, {
    path: "/datasets/:id",
    component: _a751ee14,
    name: "datasets-id___ko"
  }, {
    path: "/deploy/:id",
    component: _5941c0e2,
    name: "deploy-id___ko"
  }, {
    path: "/job/:id",
    component: _217f0b48,
    name: "job-id___ko"
  }, {
    path: "/model/:id",
    component: _d77f6698,
    name: "model-id___ko"
  }, {
    path: "/workspace/:id",
    component: _19bdae20,
    name: "workspace-id___ko"
  }, {
    path: "/",
    component: _59cdc69c,
    name: "index___ko"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
