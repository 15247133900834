//
//
//
//

export default {
  layout: 'simple',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  methods: {
    routeLastPage() {
      this.$router.back()
    },
  },
}
