//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'
export default {
  props: {
    label: {
      type: String,
      default: '레이블',
    },
    inputName: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    search: Boolean,
    hideBottom: Boolean,
    hideTip: Boolean,
    error: Boolean,
    disabled: Boolean,
    required: Boolean,
    small: Boolean,
    hideLabel: Boolean,
    noBorder: Boolean,
  },
  setup(props, { emit, slots }) {
    const isOpen = ref(false)
    const searchRef = ref(null)
    const searchText = ref('')
    const selectRef = ref(null)

    const toggleOpen = (value) => {
      if (!props.disabled) {
        if (typeof value === 'boolean') {
          isOpen.value = value
          searchText.value = ''
        } else if (value.target.tagName === 'INPUT') {
          //
        } else {
          isOpen.value = !isOpen.value
          searchText.value = ''
        }
        if (isOpen.value && props.search) {
          setTimeout(() => {
            if (searchRef.value) {
              searchRef.value.focus()
            }
          })
        }
      }
    }

    function checkSearch(option) {
      return option
        .toString()
        .toLowerCase()
        .includes(searchText.value.toLowerCase())
    }

    const optionClick = (index, option) => emit('change', index, option)

    function globalClickListener(e) {
      const { target, currentTarget } = e
      if (isOpen.value && !target.className.includes('ns-select')) {
        if (
          target === window.document.documentElement ||
          currentTarget === null ||
          target !== selectRef.value
        ) {
          isOpen.value = false
          searchText.value = ''
        }
      }
    }

    onMounted(() => {
      window.addEventListener('click', globalClickListener)
    })

    onUnmounted(() => {
      window.removeEventListener('click', globalClickListener)
    })

    return {
      props,
      optionClick,
      slots,
      isOpen,
      toggleOpen,
      searchRef,
      searchText,
      selectRef,
      checkSearch,
    }
  },
}
