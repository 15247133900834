import {
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
} from '@nuxtjs/composition-api'
import useLearnDatasetState from '@/state/datasets/learnDatasetState'
import useRawDatasetSate from '@/state/datasets/rawDatasetState'
import useI18N from '@/translations/i18n'
import useJobState from '../job/jobState'
import useNotificationState from './notifyState'
const $t = useI18N()
// import useToastState from './toastState'

const rawState = useRawDatasetSate()
const learnState = useLearnDatasetState()
const jobState = useJobState()
const notifyState = useNotificationState()
// const toastState = useToastState()

const state = reactive({
  isHoverable: false,
  isNavClosed: false,
  isNavHover: false,
  isLogoutShown: false,
  isLangSelectShown: false,
  popupRef: null,
  langRef: null,
})

function toggleNavClosed(value) {
  if (typeof value !== 'boolean') value = null
  state.isNavClosed = value || !state.isNavClosed
  if (state.isNavClosed) {
    state.isHoverable = false
    setTimeout(() => (state.isHoverable = true), 500)
  }
}

function toggleNavHover(value) {
  if (typeof value !== 'boolean') value = null
  state.isNavHover = value || !state.isNavHover
}

function toggleLogout() {
  state.isLogoutShown = !state.isLogoutShown
}

function toggleLangSelect() {
  state.isLangSelectShown = !state.isLangSelectShown
}

function notifyJobEvent(data) {
  const JOB_STRUCT = {
    content: data.datasetName,
    icon: 'lightbulb',
  }
  const JOB_STATUS = {
    INIT: {
      status: 0,
      title: $t('mesg.js.learning_waiting'),
    },
    RUNNING: {
      status: 0,
      title: $t('mesg.js.learn_start'),
    },
    DONE: {
      status: 1,
      title: $t('mesg.js.learn_done'),
    },
    ERROR: {
      status: 2,
      title: $t('mesg.js.learn_fail'),
    },
  }
  const _notification = notifyState.findNotification(
    data.jobId + '-' + data.status
  )
  if (!_notification) {
    const notification = Object.assign(JOB_STRUCT, JOB_STATUS[data.status])
    notification.id = data.jobId + '-' + data.status
    notifyState.addNotification(notification)
    // if (Object.keys(JOB_STATUS).includes(data.status)) {
    //   toastState.addToast({
    //     message: JOB_STATUS[data.status].title,
    //     description: JOB_STRUCT.content,
    //     icon: 'lightbulb',
    //     color: JOB_STATUS[data.status].status === 2 ? '#f56161' : '#58acc3',
    //   })
    // }
  }
}
function notifyDatasetEvent(data, isRaw = true) {
  const DATASET_STRUCT = {
    content: isRaw ? data.name : data.datasetName,
    icon: 'lightbulb',
  }
  const mesgTile = isRaw ? 'raw' : 'learning'
  const DATASET_STATUS = {
    NOT_STARTED: {
      status: 0,
      title: $t(`mesg.js.${mesgTile}_dataset_init`),
    },
    DOING: {
      status: 0,
      title: $t(`mesg.js.${mesgTile}_dataset_doing`),
    },
    SYNC: {
      status: 0,
      title: $t(`mesg.js.${mesgTile}_dataset_sync`),
    },
    DONE: {
      status: 1,
      title: $t(`mesg.js.${mesgTile}_dataset_done`),
    },
    FAIL: {
      status: 2,
      title: $t(`mesg.js.${mesgTile}_dataset_fail`),
    },
  }
  const _notification = notifyState.findNotification(
    data.id + '-' + data.status
  )
  if (!_notification) {
    const notification = Object.assign(
      DATASET_STRUCT,
      DATASET_STATUS[data.status]
    )
    notification.id = data.id + '-' + data.status
    notifyState.addNotification(notification)
  }
}

function notifySocketEvent(data, name) {
  switch (name) {
    case 'JOB':
      notifyJobEvent(data)
      jobState.fetchJobs([], true)
      break
    case 'RAWDATA':
      notifyDatasetEvent(data)
      rawState.fetchRawdataset(data.id).then().catch()
      break
    case 'DATASET':
      notifyDatasetEvent(data, false)
      learnState.fetchLearnDataset(data.id).then().catch()
      break
  }
}

function handleDocClickForLang(e) {
  if (
    state.isLangSelectShown &&
    state.langRef &&
    !state.langRef.contains(e.target)
  ) {
    state.isLangSelectShown = false
  }
}

function handleDocClickForProfile(e) {
  if (
    state.isLogoutShown &&
    state.popupRef &&
    !state.popupRef.contains(e.target)
  ) {
    state.isLogoutShown = false
  }
}

export default function useLayoutState(popupRef, langRef) {
  state.popupRef = popupRef
  state.langRef = langRef

  // 해당 모달(모달을 보여주기 위해 선택하는 태그를) 이외의 부분을 클릭한 경우 모달을 닫기 위함
  onMounted(() => {
    document.addEventListener('click', handleDocClickForProfile)
    document.addEventListener('click', handleDocClickForLang)
  })

  onUnmounted(() => {
    document.removeEventListener('click', handleDocClickForProfile)
    document.removeEventListener('click', handleDocClickForLang)
  })
  return {
    ...toRefs(state),
    toggleNavClosed,
    toggleNavHover,
    toggleLogout,
    toggleLangSelect,
    notifySocketEvent,
  }
}
