//
//
//
//
//
//

/* eslint-disable no-undef */
import { onMounted, onUnmounted, ref, watch } from '@nuxtjs/composition-api'
export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
    layout: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const plotRef = ref(null)
    const wrapRef = ref(null)
    const resizeHandler = ref(null)

    function initResizeHandler() {
      if (resizeHandler.value) {
        clearInterval(resizeHandler.value)
      }
      resizeHandler.value = setInterval(() => {
        Plotly.Plots.resize(plotRef.value)
      }, 500)
    }

    function _renderGraph(resolve) {
      setTimeout(() => {
        Plotly.newPlot(plotRef.value, props.data, props.layout, {
          displayModeBar: false,
          responsive: false,
          autosize: true,
          width: '100%',
        })
      })
      resolve()
      initResizeHandler()
    }

    function renderGraph() {
      return new Promise(_renderGraph)
    }

    onMounted(async () => {
      await renderGraph()
    })

    onUnmounted(() => {
      if (resizeHandler.value) {
        clearInterval(resizeHandler.value)
      }
    })

    watch(
      () => props.data,
      () => renderGraph()
    )

    return {
      plotRef,
      wrapRef,
    }
  },
}
