//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onUnmounted, toRefs, watch } from '@nuxtjs/composition-api'
import useModalHelper from '../../helpers/useModalHelper'
export default {
  props: {
    show: Boolean,
    large: Boolean,
    wrap: Boolean,
    size: {
      type: [Number, String],
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const modalHelper = useModalHelper()
    const { show: flag } = toRefs(props)

    const closeFlag = () => emit('close')

    watch(flag, (show) => {
      if (show) {
        modalHelper.addModal()
      } else {
        modalHelper.removeModal()
      }
    })

    onUnmounted(() => {
      if (flag.value) {
        modalHelper.removeModal()
      }
    })

    return {
      flag,
      closeFlag,
    }
  },
}
