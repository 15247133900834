/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
// binary search for index of closest value
export function closestIdx(num, arr, lo, hi) {
  let mid
  lo = lo || 0
  hi = hi || arr.length - 1
  const bitwise = hi <= 2147483647

  while (hi - lo > 1) {
    mid = bitwise ? (lo + hi) >> 1 : floor((lo + hi) / 2)

    if (arr[mid] < num) lo = mid
    else hi = mid
  }

  if (num - arr[lo] <= arr[hi] - num) return lo

  return hi
}

export function nonNullIdx(data, _i0, _i1, dir) {
  for (let i = dir == 1 ? _i0 : _i1; i >= _i0 && i <= _i1; i += dir) {
    if (data[i] != null) return i
  }

  return -1
}

export function getMinMax(data, _i0, _i1, sorted) {
  //	console.log("getMinMax()");

  let _min = inf
  let _max = -inf

  if (sorted == 1) {
    _min = data[_i0]
    _max = data[_i1]
  } else if (sorted == -1) {
    _min = data[_i1]
    _max = data[_i0]
  } else {
    for (let i = _i0; i <= _i1; i++) {
      if (data[i] != null) {
        _min = min(_min, data[i])
        _max = max(_max, data[i])
      }
    }
  }

  return [_min, _max]
}

export function getMinMaxLog(data, _i0, _i1) {
  //	console.log("getMinMax()");

  let _min = inf
  let _max = -inf

  for (let i = _i0; i <= _i1; i++) {
    if (data[i] > 0) {
      _min = min(_min, data[i])
      _max = max(_max, data[i])
    }
  }

  return [_min == inf ? 1 : _min, _max == -inf ? 10 : _max]
}

const _fixedTuple = [0, 0]

function fixIncr(minIncr, maxIncr, minExp, maxExp) {
  _fixedTuple[0] = minExp < 0 ? roundDec(minIncr, -minExp) : minIncr
  _fixedTuple[1] = maxExp < 0 ? roundDec(maxIncr, -maxExp) : maxIncr
  return _fixedTuple
}

export function rangeLog(min, max, base, fullMags) {
  const minSign = sign(min)
  const maxSign = sign(max)

  const logFn = base == 10 ? log10 : log2

  if (min == max) {
    if (minSign == -1) {
      min *= base
      max /= base
    } else {
      min /= base
      max *= base
    }
  }

  let minExp, maxExp, minMaxIncrs

  if (fullMags) {
    minExp = floor(logFn(min))
    maxExp = ceil(logFn(max))

    minMaxIncrs = fixIncr(pow(base, minExp), pow(base, maxExp), minExp, maxExp)

    min = minMaxIncrs[0]
    max = minMaxIncrs[1]
  } else {
    minExp = floor(logFn(abs(min)))
    maxExp = floor(logFn(abs(max)))

    minMaxIncrs = fixIncr(pow(base, minExp), pow(base, maxExp), minExp, maxExp)

    min = incrRoundDn(min, minMaxIncrs[0])
    max = incrRoundUp(max, minMaxIncrs[1])
  }

  return [min, max]
}

export function rangeAsinh(min, max, base, fullMags) {
  const minMax = rangeLog(min, max, base, fullMags)

  if (min == 0) minMax[0] = 0

  if (max == 0) minMax[1] = 0

  return minMax
}

export const rangePad = 0.1

export const autoRangePart = {
  mode: 3,
  pad: rangePad,
}

const _eqRangePart = {
  mode: 0,
  pad: 0,
  soft: null,
}

const _eqRange = {
  min: _eqRangePart,
  max: _eqRangePart,
}

// this ensures that non-temporal/numeric y-axes get multiple-snapped padding added above/below
// TODO: also account for incrs when snapping to ensure top of axis gets a tick & value
export function rangeNum(_min, _max, mult, extra) {
  if (isObj(mult)) return _rangeNum(_min, _max, mult)

  _eqRangePart.pad = mult
  _eqRangePart.soft = extra ? 0 : null
  _eqRangePart.mode = extra ? 3 : 0

  return _rangeNum(_min, _max, _eqRange)
}

// nullish coalesce
export function ifNull(lh, rh) {
  return lh == null ? rh : lh
}

// checks if given index range in an array contains a non-null value
// aka a range-bounded Array.some()
export function hasData(data, idx0, idx1) {
  idx0 = ifNull(idx0, 0)
  idx1 = ifNull(idx1, data.length - 1)

  while (idx0 <= idx1) {
    if (data[idx0] != null) return true
    idx0++
  }

  return false
}

function _rangeNum(_min, _max, cfg) {
  const cmin = cfg.min
  const cmax = cfg.max

  const incr = cfg.incr

  let padMin = ifNull(cmin.pad, 0)
  let padMax = ifNull(cmax.pad, 0)

  const hardMin = ifNull(cmin.hard, -inf)
  const hardMax = ifNull(cmax.hard, inf)

  const softMin = ifNull(cmin.soft, inf)
  const softMax = ifNull(cmax.soft, -inf)

  const softMinMode = ifNull(cmin.mode, 0)
  const softMaxMode = ifNull(cmax.mode, 0)

  let delta = _max - _min

  // this handles situations like 89.7, 89.69999999999999
  // by assuming 0.001x deltas are precision errors
  //	if (delta > 0 && delta < abs(_max) / 1e3)
  //		delta = 0;

  // treat data as flat if delta is less than 1 billionth
  if (delta < 1e-9) {
    delta = 0

    // if soft mode is 2 and all vals are flat at 0, avoid the 0.1 * 1e3 fallback
    // this prevents 0,0,0 from ranging to -100,100 when softMin/softMax are -1,1
    if (_min == 0 || _max == 0) {
      delta = 1e-9

      if (softMinMode == 2 && softMin != inf) padMin = 0

      if (softMaxMode == 2 && softMax != -inf) padMax = 0
    }
  }

  const nonZeroDelta = delta || abs(_max) || 1e3
  const mag = log10(nonZeroDelta)
  const base = pow(10, floor(mag))

  const _padMin = nonZeroDelta * (delta == 0 ? (_min == 0 ? 0.1 : 1) : padMin)
  const _newMin = roundDec(
    incrRoundDn(_min - _padMin, ifNull(incr, base / 10)),
    9
  )
  const _softMin =
    _min >= softMin &&
    (softMinMode == 1 ||
      (softMinMode == 3 && _newMin <= softMin) ||
      (softMinMode == 2 && _newMin >= softMin))
      ? softMin
      : inf
  const minLim = max(
    hardMin,
    _newMin < _softMin && _min >= _softMin ? _softMin : min(_softMin, _newMin)
  )

  const _padMax = nonZeroDelta * (delta == 0 ? (_max == 0 ? 0.1 : 1) : padMax)
  const _newMax = roundDec(
    incrRoundUp(_max + _padMax, ifNull(incr, base / 10)),
    9
  )
  const _softMax =
    _max <= softMax &&
    (softMaxMode == 1 ||
      (softMaxMode == 3 && _newMax >= softMax) ||
      (softMaxMode == 2 && _newMax <= softMax))
      ? softMax
      : -inf
  let maxLim = min(
    hardMax,
    _newMax > _softMax && _max <= _softMax ? _softMax : max(_softMax, _newMax)
  )

  if (minLim == maxLim && minLim == 0) maxLim = 100

  return [minLim, maxLim]
}

// alternative: https://stackoverflow.com/a/2254896
// export const fmtNum = new Intl.NumberFormat(navigator.language).format
export const fmtNum = function (e) {
  return e.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const M = Math

export const PI = M.PI
export const abs = M.abs
export const floor = M.floor
export const round = M.round
export const ceil = M.ceil
export const min = M.min
export const max = M.max
export const pow = M.pow
export const sqrt = M.sqrt
export const sign = M.sign
export const log10 = M.log10
export const log2 = M.log2
// TODO: seems like this needs to match asinh impl if the passed v is tweaked?
export const sinh = (v, linthresh = 1) => M.sinh(v) * linthresh
export const asinh = (v, linthresh = 1) => M.asinh(v / linthresh)

export const inf = Infinity

export function incrRound(num, incr) {
  return round(num / incr) * incr
}

export function clamp(num, _min, _max) {
  return min(max(num, _min), _max)
}

export function fnOrSelf(v) {
  return typeof v === 'function' ? v : () => v
}

export const retArg0 = (_0) => _0

export const retArg1 = (_0, _1) => _1

export const retNull = (_) => null

export const retTrue = (_) => true

export const retEq = (a, b) => a == b

export function incrRoundUp(num, incr) {
  return ceil(num / incr) * incr
}

export function incrRoundDn(num, incr) {
  return floor(num / incr) * incr
}

export function roundDec(val, dec) {
  return round(val * (dec = 10 ** dec)) / dec
}

export const fixedDec = new Map()

export function guessDec(num) {
  return (('' + num).split('.')[1] || '').length
}

export function genIncrs(base, minExp, maxExp, mults) {
  const incrs = []

  const multDec = mults.map(guessDec)

  for (let exp = minExp; exp < maxExp; exp++) {
    const expa = abs(exp)
    const mag = roundDec(pow(base, exp), expa)

    for (let i = 0; i < mults.length; i++) {
      const _incr = mults[i] * mag
      const dec =
        (_incr >= 0 && exp >= 0 ? 0 : expa) +
        (exp >= multDec[i] ? 0 : multDec[i])
      const incr = roundDec(_incr, dec)
      incrs.push(incr)
      fixedDec.set(incr, dec)
    }
  }

  return incrs
}

// export const assign = Object.assign;

export const EMPTY_OBJ = {}
export const EMPTY_ARR = []

export const nullNullTuple = [null, null]

export const isArr = Array.isArray

export function isStr(v) {
  return typeof v === 'string'
}

export function isObj(v) {
  let is = false

  if (v != null) {
    const c = v.constructor
    is = c == null || c == Object
  }

  return is
}

export function fastIsObj(v) {
  return v != null && typeof v === 'object'
}

export function copy(o, _isObj = isObj) {
  let out

  if (isArr(o)) {
    const val = o.find((v) => v != null)

    if (isArr(val) || _isObj(val)) {
      out = Array(o.length)
      for (let i = 0; i < o.length; i++) out[i] = copy(o[i], _isObj)
    } else out = o.slice()
  } else if (_isObj(o)) {
    out = {}
    for (const k in o) out[k] = copy(o[k], _isObj)
  } else out = o

  return out
}

export function assign(targ) {
  const args = arguments

  for (let i = 1; i < args.length; i++) {
    const src = args[i]

    for (const key in src) {
      if (isObj(targ[key])) assign(targ[key], copy(src[key]))
      else targ[key] = copy(src[key])
    }
  }

  return targ
}

// nullModes
const NULL_REMOVE = 0 // nulls are converted to undefined (e.g. for spanGaps: true)
const NULL_RETAIN = 1 // nulls are retained, with alignment artifacts set to undefined (default)
const NULL_EXPAND = 2 // nulls are expanded to include any adjacent alignment artifacts

// sets undefined values to nulls when adjacent to existing nulls (minesweeper)
function nullExpand(yVals, nullIdxs, alignedLen) {
  for (let i = 0, xi, lastNullIdx = -1; i < nullIdxs.length; i++) {
    const nullIdx = nullIdxs[i]

    if (nullIdx > lastNullIdx) {
      xi = nullIdx - 1
      while (xi >= 0 && yVals[xi] == null) yVals[xi--] = null

      xi = nullIdx + 1
      while (xi < alignedLen && yVals[xi] == null)
        yVals[(lastNullIdx = xi++)] = null
    }
  }
}

// nullModes is a tables-matched array indicating how to treat nulls in each series
// output is sorted ASC on the joined field (table[0]) and duplicate join values are collapsed
export function join(tables, nullModes) {
  const xVals = new Set()

  for (let ti = 0; ti < tables.length; ti++) {
    const t = tables[ti]
    const xs = t[0]
    const len = xs.length

    for (let i = 0; i < len; i++) xVals.add(xs[i])
  }

  const data = [Array.from(xVals).sort((a, b) => a - b)]

  const alignedLen = data[0].length

  const xIdxs = new Map()

  for (let i = 0; i < alignedLen; i++) xIdxs.set(data[0][i], i)

  for (let ti = 0; ti < tables.length; ti++) {
    const t = tables[ti]
    const xs = t[0]

    for (let si = 1; si < t.length; si++) {
      const ys = t[si]

      const yVals = Array(alignedLen).fill(undefined)

      const nullMode = nullModes ? nullModes[ti][si] : NULL_RETAIN

      const nullIdxs = []

      for (let i = 0; i < ys.length; i++) {
        const yVal = ys[i]
        const alignedIdx = xIdxs.get(xs[i])

        if (yVal === null) {
          if (nullMode != NULL_REMOVE) {
            yVals[alignedIdx] = yVal

            if (nullMode == NULL_EXPAND) nullIdxs.push(alignedIdx)
          }
        } else yVals[alignedIdx] = yVal
      }

      nullExpand(yVals, nullIdxs, alignedLen)

      data.push(yVals)
    }
  }

  return data
}

export const microTask =
  typeof queueMicrotask === 'undefined'
    ? (fn) => Promise.resolve().then(fn)
    : queueMicrotask
