//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
} from '@nuxtjs/composition-api'
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { parent }) {
    const bodyRef = ref(null)
    const bodyInnerRef = ref(null)
    const state = reactive({
      number: 0,
      realHeight: 0,
      measure: true,
    })
    const isOpen = computed(() => parent.index === state.number)
    const height = computed(() => {
      if (state.measure) return 'auto'
      return isOpen.value ? _h.value + 'px' : '0'
    })
    const intervalHandler = ref(null)
    const _h = ref(0)
    parent.bindAccordion(state)
    function toggleOpen(disabled) {
      if (!disabled) {
        parent.toggleOpen(state.number)
      }
    }
    function checkSize() {
      if (bodyInnerRef.value) {
        const size = bodyInnerRef.value.scrollHeight
        _h.value = size + 2
      }
    }
    onMounted(() => {
      const height = getComputedStyle(bodyRef.value).height
      _h.value = Number(height.substr(0, height.length - 2)) + 2
      state.measure = false
      intervalHandler.value = setInterval(checkSize, 200)
    })
    onUnmounted(() => {
      clearInterval(intervalHandler.value)
      intervalHandler.value = null
    })
    return {
      bodyRef,
      bodyInnerRef,
      isOpen,
      ...toRefs(state),
      toggleOpen,
      height,
      _h,
    }
  },
}
