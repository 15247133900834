//
//
//
//
//
//
//
//
//
//
//
//

import useTooltipState from '../../state/ns/tooltipState'
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
    top: {
      type: Number,
      default: 0,
    },
    hide: {
      type: Boolean,
    },
  },
  setup() {
    return {
      ...useTooltipState(),
    }
  },
}
