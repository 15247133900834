const fields = {
  SYSVARS: {
    inquiryEmail: 'manager@martinie.ai',
    productName: 'Martinie',
    studioName: 'Martinie Studio',
  },

  mesg: {
    signin: {
      service_guide: 'Martinie Studio 입니다.',
      request_to_use:
        '가입을 원하시면 manager@martinie.ai 로 연락주시기 바랍니다.',
    },
  },
}

export default {
  overrideLanguage: 'ko',
  fields,
}
