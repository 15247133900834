export default {
  test: 'テスト',
  nest: {
    test: 'テスト',
  },

  SYSVARS: {
    inquiryEmail: 'manager@neuralworks.io',
    productName: 'NeuralWorks',
    studioName: 'Neural Studio',
  },

  menu: {
    dataset: 'データセット',
    handling: 'データハンドリング',
    EDA: 'EDA',
    model: 'モデル',
    training: 'モデル学習',
    result: '結果',
    comparison: '結果比較',
    deployment: 'モデル配布',
    annotation: 'アノテーション',
    GPU: 'GPUサーバー',
  },

  common: {
    email: 'Eメール',
    password: 'パスワード',
    login: 'ログイン',
    signup: '会員登録',
    logout: 'ログアウト',
    register: '会員登録',
    search: '検索',
    search_placeholder: '検索ワードを入力してください',
    search_result: '検索結果',
    search_result_none: '検索結果がありません',
  },

  page: {
    dataset_list: 'データセットリスト',
    dataset_detail: 'データセット詳細',
    raw_data: '源泉データ',
    training_data: '学習データ',
    EDA: '探索的データ分析（EDA）',

    model_list: 'モデルリスト',
    model_detail: 'モデルの詳細',
    model_category: 'モデルカテゴリ',
    model_name: 'モデル名',
    model_type: 'モデルタイプ',
    library: 'ライブラリ',
    detail: '詳細',
    selection: '選択',

    image: 'イメージ',
    image_classification: 'イメージ分類',
    object_detection: 'オブジェクト検出',
    object_segmentation: 'オブジェクトセグメンテーション',
    semantic_segmentation: 'セマンティックセグメンテーション',

    data_analisys: 'データ分析',
    clustering: 'クラスタリング',
    data_analys: 'データ分析',
    training_detail: 'トレーニングの詳細',
    regression: '回帰分析',
    classificatoin: '分類',
    クラスタリング: 'クラスタリング',
    timeseries_analysis: '時系列分析',
    anomaly_detection: '異常検出',

    result: '結果',
    waiting: '待機中',
    on_training: 'トレーニング中',
    done: '完了',

    recent: '最新',

    training_name: 'トレーニング名',
    status: 'ステータス',
    training_time: 'トレーニング時間',
    compare_results_of_training: 'トレーニングの結果の比較',

    model_deployment: 'モデル展開',
    annotation_list: 'アノテーションリスト',
  },

  mesg: {
    // 각 페이지별로
    // 데이터셋 페이지
    annotation_list: {
      title_description1: '合計',
      title_description2:
        '複数のアノテーションがあります。詳細情報を表示するには,アノテーションの名前をクリックしてください。',
      ing: '処理中',
      finished: '完了',
      new_anno: '新規アノテーション',
      search_msg: 'タスク名,説明の入力',
      no_name: '名前がありません',
      no_data: 'データセットがありません',
      no_search: '検索データがありません',
      no_job: 'タスクがありません',
      add_anno: 'アノテーション追加',
      select_data: 'データ選択',
      detail_option: '詳細オプション',
      label_set: 'ラベル割り当て',
      no_image_data: 'イメージデータセットがありません',
      name: '名前',
      anno_placeholder: 'アノテーションの名前の入力',
      description: '説明',
      explication_placeholder: 'アノテーションの説明を入力',
      label_list: 'トレーニング済みラベルリスト',
      label_name: 'ラベル名',
      label_placeholder: 'ex)犬,猫',
      label_tip: '注釈ラベル名の入力',
      add: '追加',
      color_short_key: 'カラー/ショートカット',
      label_name2: 'ラベル名',
      anno_new_mesg: '作成済みアノテーション',
      anno_new_fail_mesg: 'アノテーションの作成に失敗しました',
      loading: 'ロード中',
      anno_desc:
        'データセットを確認し,アノテーションボタンをクリックしてアノテーティングを開始します。',
      label: 'ラベル',
      label_add: 'ラベルの追加',
      worker_manage: 'ワーカー管理',
      kind: 'タイプ',
      general_worker: '一般的なワーカー',
      number: '数',
      number_unit: 'ユニットの数',
      work_num_placeholder: 'タスクの数を入力します',
      work_num: 'タスク番号',
      worker: 'ワーカー',
      prover: '検証者',
      work_ratio: 'タスクの進行状況',
      modify_time: '最終変更日',
      annotation: 'アノテーション',
      no_work: 'タスクなし',
      auto_anno: '自動アノテーション',
      input_name: '入力名',
      confirm: '確認',
      kind_anno: 'アノテーションカテゴリ',
      work_load_unit: 'アノテーションタスクロードユニット',
      num_image: 'イメージの数',
      auto_anno_model: '自動アノテーションモデル',
      auto_anno_check: '自動アノテーションを使用する',
      model_list: 'モデルリスト',
      input_label: 'ラベルの入力',
      new_label: '新しいラベル',
      user_set: 'ユーザー割り当て',
      original_label: '元のラベル',
      auto_anno_name: '自動アノテーション',
      datatest: 'データセット',
      size: 'サイズ',
      learning_model: 'トレーニングモデル',
      learning_data: 'トレーニングデータ',
      number2: 'カウント',
      category: 'カテゴリ',
      bounding_box: 'バウンディングボックス',
      polygon: 'ポリゴン',
      label_error_msg: '既存のラベル',
      number3: 'カウント',
      data_ing: 'アノテーション中のデータセット',
      store: '保存',
      submit: '送信',
    },

    signin: {
      title: 'ログイン',
      page_guide: 'ようこそ!',
      service_guide: 'ニューラルワークススタジオベータサービス',
      start_button: '開始',
      new_service_guide: 'No-code AI, Neural Studio !',
      request_to_use: '登録のお問い合わせ manager@neuralworks.io',
      wrong_email: 'Eメールアドレスが間違っています',
      wrong_password: 'パスワードが間違っています',
      send_code: '認証コードを要求します',
      enter_code_sent: '認証コードの入力',
      verify_code: '認証コードを確認します',
      forgot_password: 'パスワードを検索します',
      reset_password: 'パスワードをリセットします',
      reset_password_success: 'パスワードのリセットが完了しました',
      reset_password_fail: 'パスワードのリセットに失敗しました',
      reset_password_email_sent: 'パスワードリセットメールが送信されました',
      forgot_password2: 'パスワードをお忘れですか?',
      ask_info:
        '参加をご希望の場合は,manager@neuralworks.ioまでお問い合わせください。',
      authcheck_finished: '認証済み',
      recv_code: '認証コードを受信します',
      code: '認証コード',
      ask_code: '電子メールに送信されたコードを入力してください。',
      auth_check: '証明書',
      pw_info: '小文字,数字の組み合わせ',
      pw_info2: '6文字以上',
      agree_info:
        '個人情報の収集と使用,およびNeuralWorksの使用に関する契約条件に同意します。',
      view_agree_info: '契約条件を表示',
      start_info: 'ニューラルワークススタジオの起動',
      new_password: '新しいパスワード',
      change_pw: 'パスワードの変更',
      login_fail: 'ログインに失敗しました。',
      login_session_expire: 'ログインセッションの期限が切れました。',
    },

    dataset_list: {
      page_guide: 'データセットをアップロードして機械学習を準備します',
    },
    dataset_detail: {
      page_guide: 'データセットの詳細情報参照します',
    },
    datasets: {
      title: 'データセットリスト',
      page_info: 'データセットをアップロードして処理して学習を開始します',
      upload_data: 'データセットのアップロード',
      search_placeholder: 'データセット名,説明を入力',
      view_only_mydata: 'データセットのみを表示',
      no_name: '名前は存在しません',
      no_info: '情報なし',
      do_learning: '学習の実施',
      image_classification: 'イメージ分類',
      detection: 'オブジェクト検出',
      segmentation: 'オブジェクトのセグメンテーション',
      image: 'イメージ',
      data_analysis: 'データ分析',
      title2: 'ファイルストア',
      raw_data: 'ソースデータ',
      data_summary: '概要',
      データセット: 'データセット',
      folder: 'フォルダ',
      file: 'ファイル',
      unit: 'ユニット',
      is_loading: 'ロード中...',
      no_file_loading: 'ファイルをロードできません',
      is_loading2: 'ロード中',
      no_data_name: 'データセット名が存在しません',
      not_find_data: 'データセットが見つかりません',
      page_info2: 'データのサマリー情報を確認してください。',
      view_file: 'ファイルの表示',
      convert: '変換',
      annotation: 'アノテーション',
      view_preprocess: '可視化/前処理',
      data: 'データ',
      no_data: 'データは存在しません。',
      basic_stat: 'デフォルト統計情報',
      relation_var: '相関係数',
      remove_data: 'データの削除',
      modify_data: 'データセット情報の変更',
      dataset_name: 'データセット名',
      explain: '説明',
      explain_placeholder:
        'このデータセットについて簡単に説明するメモを残してください。 入力せずにエクスポートできます。',
      change: '変更',
      preprocess_info: '前処理履歴',
      no_preprocess_info: '前処理履歴がありません',
      no_data_name2: 'データ名が存在しません',
      page_info3: 'データを前処理して学習データにします',
      send_data: '学習データにエクスポートします',
      pre_process: '前処理',
      summary: '概要',
      add: '追加',
      cancel: 'キャンセル',
      modify: '変更',
      delete: '削除',
      done: '完了',
      add_btn_info: '追加ボタンをクリックします',
      select_preprocess: '前処理の選択',
      select_var: '変数リストから変数の選択',
      filter_condition_set: 'フィルタ条件の設定',
      select_method: '処理方法の選択',
      select_result: '3. 処理結果選択',
      condition: '条件',
      condition_info: '条件式はありません。 以下の条件を設定します。',
      select_left_list: '左のリストから変数を選択します',
      operator_placeholder: 'オペレータ',
      value_placeholder: '値',
      pre_value: '既存の値',
      next_value: '代替値',
      no_input_placeholder: '未入力の場合は,欠落したものに置き換えます',
      missing_value: 'パラメータ値',
      preview: 'プレビュー',
      no_relation_var: '相関係数データなし',
      select_preprocess2: '前処理リストの選択',
      set_data_name: '学習データの名前を設定してください',
      send: 'エクスポート',
      category_var_convert: 'カテゴリ型変数の変換',
      scaling: 'スケーリング',
      missing_value_process: '欠落の処理',
      missing_value_delete: '欠落している(行を削除)を削除します',
      missing_value_set: '値の割り当てがありません',
      set_value: '値の割り当て',
      mean: '平均',
      median: '中央',
      mode: 'モード',
      minimum: '最小値',
      maximum: '最大値',
      direct_input: '直接入力',
      outlier: '異常値',
      outlier_handling: '異常値の処理',
      outlier_add: '異常値の決定（新しいカラムの追加）',
      outlier_remove: '異常値の削除（データ行の削除）',
      filtering: 'フィルタリング',
      var_change: '値を置き換えます',
      unit_var_input: '値を1つ入力してください',
      range_var_input: '範囲の入力',
      no_basic_stat: 'デフォルト統計情報はありません',
      ask_info: 'このタスクを保存せずに終了しますか?',
      exit_info: '終了',
      continue_modify: '修正を続行します',
      yes_info: 'いいえ,実施しません',
      ask_info2: '変更を反映せずに退出しますか？',
      del_info:
        'このタスクを削除すると,それ以降のタスクはすべて削除され,戻ることはできません。\n削除してよろしいですか?',
      del_yes_info: 'はい,削除します',
      modify_info:
        'このアクションを変更すると,以降のアクションはすべて削除され,元に戻すことはできません。\n変更しますか?',
      modify_yes_info: 'はい,変更します',
      select_op_value: '演算子または値を選択',
      label_enc_only_for_str: '文字列型にのみ適用可能です',
      add_value_replace: '値の置換を追加',
      select_var_info: '変数の選択',
      upload_success: 'アップロードに成功しました',
      upload_fail: 'アップロードに失敗しました',
      before_process: '処理前',
      after_process: '処理後',
      row: '行',
      column: 'カラム',
      convert_result: '変換結果',
      outlier_number: '外れ値',
      pre_min: '既存の最小値',
      pre_max: '既存の最大値',
      new_min: '新しい最小値',
      new_max: '新しい最大値',
      mean2: '平均',
      std: '標準偏差',
      preview_fail: 'プレビューに失敗しました',
      yes: 'はい',
      no: 'いいえ',
      select_add_btn: '追加ボタンをクリックします',
      select_pre_type: '前処理タイプを選択してください',
      add2: '手順の追加',
      do_preprocess: '前処理',
    },

    EDA: {
      page_guide: '基本的な統計とグラフを確認し,データに対する洞察力を得る',
      title: 'データ分析の調査',
      page_info: '変数を選択すると,デフォルトの統計情報とグラフを確認できます',
      data: 'データ',
      data_placeholder: 'データの選択',
      y_var: 'y軸変数',
      y_value: 'y軸の値',
      Legend_vars: '色変数/凡例変数/...',
      ask_info: 'データと変数の選択',
      m1: '頻度',
      m2: '合計',
      m3: '平均',
      m4: '配布',
      m5: 'バー',
      m6: '線',
      m7: 'ドット',
      m8: 'パイ',
      m9: 'ボックス',
      m10: '棒グラフ',
      msg1: 'データセットが見つかりません',
      raw: 'ソース',
      learn: '学習',
    },

    model_list: {
      page_guide: '最新かつ最高のモデルをお試しください',
      title: 'モデルリスト',
      page_info: 'ニューラルワークスの最新のSOTAモデルをチェックしてください',
      model_type: 'モデル一覧',
      model_small_type: '分離',
      add_model: 'モデルの追加',
      search_placeholder: 'モデル名,説明を入力',
      do_learning: '学習',
      no_search: '検索は見つかりませんでした',
      prepare_model: 'モデルは準備中です',
      is_loading: 'モデルのロード',
      model_type2: 'モデルタイプ',
      publish_time: 'プレゼンテーションの日付',
      bench_mark: 'ベンチマークパフォーマンス',
      analysis_method: '分析手法',
      keyword: 'キーワード',
      more_view: '詳細',
      public_document: '公式ドキュメント',
      wiki: 'Wiki百科事典',
      no_model: 'モデルの説明が見つかりません',
      paper: '論文',
      no_paper: '論文なし',
      no_model_info: 'モデルが見つかりません',
    },
    model_detail: {
      page_guide: '',
    },
    model_category: {
      page_guide: '',
    },
    profile: {
      title: 'プロファイル',
      page_info: '個人プロファイルを変更できます',
      change_photo: '写真の変更',
      email: 'メールアドレス',
      password: 'パスワード',
      change_password: 'パスワードの変更',
      change_moduleo_ok: 'プロファイルイメージが変更されました',
      change_photo_fail: 'プロファイルイメージの変更に失敗しました',
    },
    handling: {
      title: 'データ処理',
      page_info: '実行するアクションの選択',
      table_data: 'テーブルデータ',
      eda: '探索的データ分析(EDA)',
      eda_info:
        'データを分析する前にグラフや統計的な数値を通じて直感的に見ていくプロセスです',
      data_preprocess: 'データ前処理',
      data_moduleprocess_info: '分析に適した形式のデータ処理',
      data_concat: 'データのマージ',
      data_concat_info: '別のデータセットとマージできます',
      title2: 'データのマージ',
      page_info2: '複数のデータをマージできます',
      data_concat2: 'データの組み合わせ',
      vertical: '垂直',
      horizontal: '水平',
      original_view: '元のビュー',
      preview: 'プレビュー',
      data_store: 'データを保存します',
      data_row: 'データ（行）',
      var_column: '変数（カラム）',
      table_close: 'テーブルを閉じる',
      table_open: 'テーブルを開く',
      no_image_data: 'イメージデータセットが存在しません',
      data_concat_ing: 'データのマージ',
      data_concat3: 'データのマージ',
      dataset_name: 'データセット名',
      dataset_name_placeholder: 'データセット名を入力',
      explain: '説明',
      explain_placeholder:
        'このデータセットについて簡単なメモを残してください。 入力せずにアップロードできます。',
      upload: 'アップロード',
    },
    workspace: {
      title: 'ワークスペース',
      page_info: 'ワークスペースを管理および変更できます',
      title2: 'ワークスペース管理',
      add_workspace: 'ワークスペースの作成',
      member_number: '参加者:20人',
      manage: '管理',
      create_info: 'ワークスペースの作成',
      workspace_modal_title: 'ワークスペース名と説明を入力してください',
      cover_set: 'カバーの設定',
      name: '名前',
      name_placeholder: 'ワークスペースの名前を入力してください',
      explain: '説明',
      expand_placeholder: 'ワークスペースの説明を入力してください',
      create: '作成',
      create_info2: 'ワークスペースを作成しました',
      create_fail: 'ワークスペースの作成に失敗しました',
      page_info2: 'ワークスペースで基本情報とチームメンバーを管理できます',
      cover_change: 'カバーの変更',
      delete: '削除',
      raw_data: 'ソースデータ',
      dataset: '学習データ',
      annotation: '注釈',
      learning: '学習',
      zero: 'ゼロ',
      member_manage: 'チームメンバー管理',
      member_invite: 'チームメンバーを招待します',
      Total_member: 'すべての担当者',
      manager: '管理者',
      normal: '一般',
      waiting: '待機中',
      unit: '人',
      unit2: 'ピース',
      confirm_info: '人数を削除してもよろしいですか?',
      yes: 'はい,削除します',
      enter_email:
        'NeuralStudioに参加したチームメンバーからのEメールを入力してください。',
      enter_info: '[完了]ボタンをクリックして,登録用の電子メールを送信します。',
      email: 'メールアドレス',
      invite: '招待',
      remove: '削除',
      done: '完了',
      normal_user: '一般ユーザー',
      owner: 'オーナー',
    },
    invite: {
      msg1: '招待されたユーザーではありません',
      to_main: 'メイン',
      member_size: '参加者',
      member_unit: '名前',
      apply: '参加',
      no_workspace: 'ワークスペースが見つかりません',
      not_valid_token: '有効なトークンではありません',
      workspace_error1: 'ワークスペースは既に参加しています。',
      workspace_error2: 'ワークスペースが見つかりません',
      apply_workspace: 'ワークスペースに参加しました',
      not_apply_workspace: 'ワークスペースに参加できませんでした。',
    },
    instruction: {
      title: 'モデルタイプの選択',
      page_info: '学習するモデルのタイプを選択してください。',
      image: 'イメージ',
      image_classification: 'イメージ分類',
      image_classification_info: 'イメージをカテゴリ別にソートします',
      detection: 'オブジェクト検出',
      detection_info: 'イメージ内のオブジェクトを検出して分類します',
      segmentation: 'オブジェクトのセグメンテーション',
      segmentation_info: 'イメージ内のオブジェクトをピクセル単位で分割します',
      data_analysis: 'データ分析',
      regression: '回帰分析',
      regression_info: '変数の関係をモデル化します',
      classification_analysis: '分類分析',
      classification_analysis_info:
        '複数の変数を分析し,あらかじめ決められたカテゴリに分類する',
      clustering_analysis: 'クラウド分析',
      clustering_analysis_info:
        '類似の特性を持つオブジェクトをあらかじめ定義されたカテゴリなしでグループ化する',
      timeseries_analysis: '時系列分析',
      timeseries_analysis_info: '時間データを分析して将来を予測します',
      anonymous_detection: '異常検出',
      anonymous_detection_info: 'データ内の異常な値を検出します',
      page_info2: 'データセット,変数,モデルを選択することで学習を開始できます',
      データセット: 'データセット',
      rechoice: 'もう一度選択',
      search_placeholder: 'データセット名,説明を入力してください',
      no_info: '情報なし',
      model: 'モデル',
      search_placeholder_model: 'モデル名,説明を入力してください',
      start_learning: '学習の開始',
      start_learning_info: '学習を開始します',
      view_learning_list: '学習リストの表示',
      learning_ing_info: '学習を作成しています!',
      gpu_check_ing_info: 'GPUを確認しています...',
      delay_info: '約10秒かかります。',
      add_model: 'モデルの追加',
      learning_name: '学習名',
      ask_learning_name: '学習名を入力してください',
      explain: '説明',
      exclude_placeholder: 'この学習について簡単なメモを残します',
      inform_learning_done: '学習後にEメールで通知を受け取ります',
      start_learning2: '学習の開始',
      confirm_msg1:
        'モデルを削除すると,元に戻すことができません。\n削除してよろしいですか?',
      yes: 'はい',
      data_model_select_info: 'データセットまたはモデルを選択してください',
    },

    image: {
      page_guide: 'イメージ',
    },
    image_classification: {
      page_guide: 'イメージをクラスにソートする',
    },
    object_vlan: {
      page_guide: 'イメージ内のオブジェクトを検出して分類します',
    },
    object_segmentation: {
      page_guide: 'イメージ内のオブジェクトをピクセル単位で分割します',
    },

    semantic_segmentation: {
      page_guide: '選択的セグメンテーションを実行します。',
    },

    training_list: {
      page_guide: 'マシンラーニングタスクタイプを選択します。',
    },
    training_detail: {
      page_guide: 'マシンラーニングタスクタイプを選択します。',
    },

    data_analisys: {
      page_guide:
        'データを分析するには,マシンラーニングテクニックを使用します。',
    },
    regression: {
      page_guide: '変数の関係をモデル化し,値を予測します。',
    },
    classificatoin: {
      page_guide: '新しいデータを分類するための予測を実行します',
    },
    clustering: {
      page_guide:
        'ディクショナリでカテゴリを定義するのではなく,類似の属性を持つオブジェクトをグループ化します。',
    },

    timeseries_analysis: {
      page_guide: '時系列データを分析して将来を予測する',
    },
    anomaly_variable: {
      page_guide: '異常値/異常を検出します',
    },

    result: {
      page_guide:
        'これは訓練されたモデルです。 詳細については,モデル名を押してください。',
      title: 'モデル学習の結果',
      d1: '合計すると',
      d2: ' 学習されたモデルの断片 詳細については,モデル名を押してください。',
      waiting: '待機中',
      running: '進行中',
      done: '完了',
      search_placeholder: '学習名,説明を入力',
      no_name: '学習名が見つかりません',
      done2: '終了',
      go: 'ショートカット',
      deploy: '展開',
      no_search: '検索が見つかりません',
      no_waiting: '学習保留中はありません',
      no_running: '学習中ではありません',
      no_done: '学習が完了していません',
      no_learning: '学習なし',
      no_name2: '名前なし',
      is_loading: 'ロード中',
      no_loading: '学習をロードできません',
      graph: '学習グラフ',
      result: '結果テーブル',
      test_set_result: 'テストセットの結果',
      save_resultcsv: '予測値を保存',
      test_graph: 'テストグラフ',
      no_graph: 'グラフを描けません',
      test_graph2: 'テストグラフ(ソート)',
      test_graph3: '学習·テストグラフ',
      group_graph: 'クラスタごとのシルエットグラフ',
      elbow_chart: 'エルボーチャート',
      var_value_graph: '重要度を示すグラフを表示',
      not_finished_learning: '学習はまだ完了していません',
      delete_learning: '学習の削除',
      no_image_loading: 'イメージを取得できません',
      learning_result: '学習結果',
      model_test: 'モデルテスト',
      text:
        '学習を削除した場合,元に戻すことはできません。 \n学習を削除してもよろしいですか?',
      confirm_yes: 'はい,削除します',
      model_deploy: 'モデルの展開',
      model_deploy_details:
        'あなたは無事に勉強を終えました！ モデルのパフォーマンスのテスト,導入,および使用。',
      go_deploy: '導入のショートカット',
      deploy_tilte2:
        '一生懸命学習しています！ リアルタイム学習の状況を確認してください。',
      prediction_value: '予測値',
      no_dataset_loading: 'データセットをロードできません',
      log_error: 'ログをロードできません',
      delete_learning_info: '学習は削除されました',
      delete_learning_error: '学習の削除に失敗しました',
    },

    compare_results_of_training: {
      page_guide:
        '学習に使用したデータとモデルタイプを選択し,パフォーマンスを比較できます。',
      title: 'モデル学習結果の比較',
      page_info:
        '学習に使用したデータとモデルタイプを選択すると,パフォーマンスを比較できます。',
      learning_data: '学習データ',
      data_placeholder: '学習に使用したデータを選択します',
      model_type: 'モデルタイプの学習',
      select_type: 'タイプの選択',
      view: '問い合わせ',
      data: 'data',
      error_info: '検索中にエラーが発生しました',
      retry_info: '再試行してください',
      error_info2: 'クエリー結果はありません。',
      retry_info2: '学習日を再設定します',
      main_info_p: '代表的なパフォーマンスインジケータ',
      select_info_p: 'インジケータの選択',
      result_var: '結果変数',
      result_var_placeholder: '特定の結果変数を選択できます',
      used_var: '学習に使用される変数',
      basic_option: 'デフォルトオプション',
      high_option: '詳細オプション',
      input_var: '入力変数',
      time_var: '日付変数',
      data_view: 'データビュー',
      regression: ' 回帰分析',
      clustering: 'クラスタ分析',
      classification: '分類分析',
      timeseries: '時系列分析',
      anomaly: '異常検出',
      msg1: 'データセットの選択',
      msg2: 'モデルタイプの選択',
      msg3: '日付の選択',
      msg4: 'データセットが無効です',
      msg5: '学習タイプが無効です',
      no_name_learning: '名前のない学習',
      learning_name: '学習名',
      model: 'モデル',
    },
    model_deployment: {
      page_guide:
        '現在導入されているモデル。モデル名を押して,詳細を確認します。',
      title: 'モデルの展開',
      info1: 'ある',
      info2: '導入済みモデルの一部。 詳細については,モデル名を押してください。',
      btn_deploy: '別のモデルを展開します',
      search_placeholder: 'モデル名,説明を入力',
      active: '有効にする',
      no_active: 'ディセーブル',
      deploy_deploying: '展開中',
      no_deploy_msg: '学習は展開されていません',
      is_loading: 'ロード中',
      page_info: '推論を実行するか,APIをコピーします',
      do_active: '有効にする',
      model_detail: 'モデルの詳細',
      log: '参照ログ',
      state: 'ステータス',
      learning_name: '学習名',
      type: 'タイプ',
      データセット: 'データセットの学習',
      model: '学習モデル',
      deploy_time: '展開日',
      copy: 'コピー',
      is_loading2: 'ロード中です。',
      conference: '推論',
      del_deploy: '展開の削除',
      classification: 'イメージ分類',
      detection: 'オブジェクト検出',
      segmentation: 'オブジェクトのセグメンテーション',
      msg1: 'コピーは成功しました',
      msg2: 'コピーに失敗しました',
      msg3: '展開が削除されました',
      msg4: '削除に失敗しました',
    },
    gpu: {
      page_guide: 'GPUサーバのステータスを確認できます。',
      title: 'GPUサーバ',
      page_info: 'GPUサーバのステータスを確認します',
      gpu_info: 'GPUサーバのステータスを取得中です。',
      memory_info: '使用可能なメモリ',
      cpu_info: 'CPU使用率',
      memory_usage: 'メモリ使用率',
      gpu_memory_info: 'GPU空きメモリ',
      gpu_usage_ratio: 'GPU使用率',
    },
    layout: {
      profile: 'プロファイル',
      workspace: 'ワークスペース',
      logout: 'ログアウト',
      error_info: 'サイトエラーのため,要求された操作を処理できません。',
      back_to_prepage: '前のページに戻ります',
    },

    membership: {
      payment_step: '購入ステップ',
      my_order: '私の注文',
      check_product: '選択した製品を確認してください。',
      general_payment: '一般支払い',
      regular_payment: '定期支払い',
      monthly_payment: '月額支払い',
      regular: '定期',
      month: 'ヶ月',
      won: 'ウォン',
      paying: '購入中',
      payment_info_save_fail: '支払情報の保存に失敗しました',
      succeed_payment: '支払いに成功しました。',
      failed_payment: '支払いに失敗しました。',
      neural_plan: 'ニューラルスタジオプラン',
      point_history: 'ポイント履歴',
      payment_history: '支払履歴',
      history_description: '支払いをリクエストしたリストを集めてください',
      payment_request: '支払いリクエスト',
      payment_complete: '支払い完了',
      payment_fail: '支払い失敗',
      refund_request: '返金リクエスト',
      refund: '返金',
      payment_type: '支払タイプ',
      period: '期間',
      state: '状態',
      created_date: '作成日',
      membership: 'メンバーシップ',
      membership_description: '変更するメンバーシッププランを選んでください',
      current_pricing: '現在のメンバーシップ',
      regular_subscription: '定期購読',
      unsubscription: 'メンバーシップ停止',
      one_month: '1ヶ月',
      three_months: '3ヶ月',
      six_months: '6ヶ月',
      twelve_months: '12ヶ月',
      max_csv_row_count: 'csv row 数',
      max_csv_column_count: 'csv col 数',
      create_workspace: 'ワークスペースを作成',
      create_dataset_inJoined_workspace: '他のワークスペースでデータセットをアップロード',
      create_job_inJoined_workspace: '参加したワークスペースで学習を作成',
      max_workspace_member_count: 'ワークスペースに参加可能なチームメンバー数',
      max_workspace_count: '作成可能なワークスペース数',
      max_workspace_dataset_count: 'ワークスペースにアップロード可能なデータセット数',
      max_workspace_job_count: 'ワークスペースで作成可能な学習数',
      max_workspace_inference_count: 'ワークスペースで作成可能な推論数',
      price: '価格',
      free: '無料',
      select: '選択',
    },
    components: {
      class_list: 'クラスリスト',
      guide_box: 'ガイドボックス',
      image_move: '画像移動',
      bounding_box: 'バウンディングボックス',
      poly_box: 'ポリゴンボックス',
      pretrained_info: '事前訓練情報',
      learning_data: '学習データ',
      model: 'モデル',
      view_per_class: 'クラスごとの表示',
      label: 'ラベル',
      kind: '種類',
      learning_type: '学習タイプ',
      select_learning_type: '学習タイプを選択してください。',
      format: 'フォーマット',
      learning_data_name: '学習データセット名',
      explain: '説明',
      explain_placeholder2: 'このデータセットについて簡潔に説明してください。',
      explain_placeholder: 'データセットについて簡潔なメモを残してください。入力しなくても変換できます。',
      convert: '変換する',
      start_convert: '変換を開始します！',
      convert_info: '学習データリストで確認できます。',
      no_convert: 'このファイルを変換できません。',
      no_convert_info: 'ファイルのフォーマットとアノテーションの状態を確認して再試行してください。',
      retry: '再試行',
      select_anno_method: 'アノテーション方法を選択してください。',
      file_convert: 'ファイルを変換する',
      use_neuralworks: 'ニューラルワークスツールを使用',
      prepare_ing: '(準備中)',
      vanish_info: 'このウィンドウは5秒後に閉じます。',
      data_name: 'データセット名',
      done: '完了',
      select_format: 'フォーマットを選択してファイルをアップロードしてください。',
      drag_info: 'ここにアノテーションzipファイルをドラッグ＆ドロップしてください。',
      find_file: 'ファイルを探す',
      camera: 'カメラ',
      check_before_upload: 'ファイルをアップロードする前に必ず確認してください',
      raw_data_anno: '原始データの全ての画像にアノテーションがされています。',
      raw_data_name: '各ファイルは原始データのファイル名と同じです。',
      class_zip: 'ラベル名をリストアップした"class.txt"ファイルがzipファイルに含まれています。',
      anno_data_name: 'アノテーションデータセット名',
      image_classification: '画像分類',
      detection: 'オブジェクト検出',
      segmentation: 'オブジェクト分割',
      step1: 'ステップ1. データセットのタイプを選択してください',
      from_server_title: 'サーバーから取得',
      from_server: '取得する',
      image: '画像',
      video: 'ビデオ',
      general: '一般',
      lang: '自然言語',
      voice: '音声',
      step2: 'ステップ2. zipファイルをアップロードしてください (ドラッグ＆ドロップ)',
      zip_upload: 'zipファイルをアップロードしてください',
      data_accept_types_csv: '利用可能なファイル: csv (最大1つ)',
      data_accept_types_image: '利用可能なファイル: png, jpg, jpeg',
      data_accept_types_text: '利用可能なファイル: txt',
      image_upload_check: '画像アップロードの注意事項',
      upload: 'アップロード',
      upload_start: 'アップロードを開始します！',
      upload_status: '画面上部で進行状況を確認できます。',
      data_upload_start: 'データセットのアップロードを開始',
      confirm: '確認',
      start_deploy: '推論を実行するためにモデルをデプロイ',
      learning_name: '学習名',
      learning_data2: '学習データセット',
      learning_model: '学習モデル',
      api_address: 'APIアドレス',
      name_tip: '英字（大文字・小文字）、数字のみ入力してください',
      confirm2: '重複確認',
      do_deploy: 'デプロイ',
      deploy_cancel_info: 'デプロイをキャンセルすると元に戻せません。',
      cancel_ask: 'キャンセルしますか？',
      cancel_no: 'いいえ、続けます',
      cancel_yes: 'はい、キャンセルします',
      start_deploy_ing: 'デプロイを開始します！',
      no_file: 'ファイルがありません。',
      select_var: '変数リストから変数を選択してください',
      select_var_info: '出力変数を先に選択すると、相関係数を見ながら入力変数を選択できます。',
      var_list: '変数リスト',
      var_input: '入力変数',
      var_output: '出力変数',
      var_time: '日付変数',
      view_data: 'データを見る',
      var_selection_done: '変数選択完了',
      data_error: 'データセットにエラーがあります。',
      set_model_option: 'モデルのオプションを設定してください。',
      basic_option: '基本オプション（必須）',
      basic_value: '基本値：',
      set_value_init: '基本値にリセット',
      no_basic_option: '基本オプションがありません。',
      high_option: '高度なオプション（選択）',
      no_high_option: '高度なオプションがありません。',
      set_done: '設定完了',
      set_select_model_option: 'モデルを選択してオプションを設定してください',
      page_out_info: 'ページを離れると内容が保存されません。',
      exit_info: '退出しますか？',
      no: 'いいえ',
      yes: 'はい、退出します',
      original: 'オリジナル',
      apply_preprocess: '前処理を適用',
      save_resultcsv: '予測値を保存',
      test_file: '他のファイルでテストする',
      performance: '性能',
      no_performance: '性能情報がありません。',
      inference: '推論する',
      error_for_infer: '推論中に問題が発生しました。',
      retry_info: 'しばらくしてから他のファイルで再試行してください。',
      retry_info2: 'しばらくしてから他の画像で再試行してください。',
      drag_file: 'ここにファイルをドラッグ＆ドロップしてください！',
      can_use_file_info: '使用可能なファイル：csv（最大1つ）',
      can_use_file_info2: '使用可能なファイル：png, jpg（最大10個）',
      contain_all_input_var: 'モデル学習に使用した入力変数がすべて含まれています。',
      same_info: '入力変数の名前と値の形式が原データと同じです。',
      no_valid_column: '有効なカラムがありません。',
      short_valid_column: '有効なカラムが不足しています。',
      infer_value: '予測値',
      no_server_response: 'サーバーが応答しません。',
      cannot_request: 'サーバーにリクエストを送信できません。',
      basic_info: '基本情報',
      test_p: 'テストセット性能',
      delete: '削除',
      add: '追加',
      deployed_model: '既にデプロイされている学習です。',
      test_image: '他の画像でテストする',
      infer_result: '推論結果',
      no_detection: 'オブジェクトが検出されません',
      total: '合計',
      detail_result: '詳細結果',
      error: 'エラー',
      detected_object: '検出されたオブジェクト',
      data: 'データ',
      row: 'データ（行）',
      column: '変数（列）',
      class: 'クラス',
      size: 'サイズ',
      used_var: '学習に使用された変数',
      input_var: '入力変数',
      result_var: '出力変数',
      identifier_var: '識別子変数',
      only_one_var_allowed: 'は1つのみ可能です。',
      time_var: '日付変数',
      data_option3: 'データオプション',
      flip: '左右反転、上下反転',
      rotate: '左に90度回転',
      not_find_data: 'データセットが見つかりません。',
      library: 'ライブラリ',
      method: '分析技法',
      bench_mark: 'ベンチマーク性能',
      tag: 'タグ',
      basic_option2: '基本オプション',
      no_basic_option2: '基本オプションがありません。',
      high_option2: '高度なオプション',
      no_high_option2: '高度なオプションがありません。',
      no_model: 'モデルが見つかりません。',
      view_data2: 'データを見る',
      done2: '終了',
      data_model_option: 'データセット、モデル、オプション情報',
      back: '戻る',
      no_table_header: 'テーブルヘッダーが空です。',
      table_empty: 'テーブルが空です。',
      error_alarm: '誤った通知です。',
      no_alarm: '通知内容がありません。',
      inform: '通知',
      all_delete: 'すべて削除',
      no_message: '通知メッセージがありません。',
      when_no_input: '入力しない場合',
      use_default_value: 'デフォルト値を使用',
      use_recommended_value: '推奨値を使用',
      confirm_yes: 'はい',
      confirm_no: 'いいえ',
    },
  }, // mesg
}
