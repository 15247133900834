//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import throttle from '../../util/throttle'
import { castFixed } from '../../util/numeric'
import useModalHelper from '../../helpers/useModalHelper'
export default {
  props: {
    src: {
      default: '',
      type: String,
    },
  },
  setup(_, { emit }) {
    const modalHelper = useModalHelper()
    const MIN_SCALE = 0.1
    const MAX_SCALE = 8
    const viewportRef = ref(null)
    const imageRef = ref(null)
    const baseSize = ref({})
    const margin = ref({ x: 0, y: 0 })
    const scale = ref(1)
    const size = computed(() => {
      const size = {
        width: (baseSize.value.width || 100) * scale.value,
        height: (baseSize.value.height || 100) * scale.value,
      }
      const maxMargin = { x: 0, y: 0 }
      const { clientWidth, clientHeight } = viewportRef.value || {}
      if (clientWidth || clientHeight) {
        const marginX = size.width - clientWidth
        const marginY = size.height - clientHeight
        if (marginX > 0) {
          maxMargin.x = marginX
        }
        if (marginY > 0) {
          maxMargin.y = marginY
        }
      }
      return {
        ...size,
        ...maxMargin,
      }
    })

    function handleZoomClick(value) {
      if (!value) {
        value = 0
      }
      if (scale.value + value < 1 || scale.value < 1) {
        value /= 5
      }
      scale.value += value
      scale.value = Math.round(scale.value * 10) / 10
      if (scale.value < MIN_SCALE) {
        scale.value = MIN_SCALE
        margin.value = {
          x: 0,
          y: 0,
        }
      }
      if (scale.value > MAX_SCALE) {
        scale.value = MAX_SCALE
      }
      let { x, y } = margin.value
      const { x: ax, y: ay } = size.value
      if (x < -ax) {
        x = -ax
      } else if (x > ax) {
        x = ax
      }

      if (y < -ay) {
        y = -ay
      } else if (y > ay) {
        y = ay
      }

      margin.value = {
        x,
        y,
      }
    }

    function handleZoomKeyPress(e) {
      if (e.key === '=') {
        handleZoomClick(0.5)
      } else if (e.key === '-') {
        handleZoomClick(-0.5)
      }
    }

    let startPosition = { x: 0, y: 0 }
    let originMargin = {}
    const mouseDown = ref(false)

    function handleMouseDown(e) {
      mouseDown.value = true
      const { clientX, clientY } = e
      originMargin = {
        ...margin.value,
      }
      startPosition = {
        x: clientX,
        y: clientY,
      }
    }

    const handleMouseMove = throttle(function (e) {
      if (mouseDown.value) {
        const { clientX, clientY } = e

        let x = (clientX - startPosition.x) * 2 + originMargin.x
        let y = (clientY - startPosition.y) * 2 + originMargin.y

        const { x: ax, y: ay } = size.value

        if (x < -ax) {
          x = -ax
        } else if (x > ax) {
          x = ax
        }

        if (y < -ay) {
          y = -ay
        } else if (y > ay) {
          y = ay
        }
        margin.value = {
          x,
          y,
        }
      }
    }, 30)

    function handleMouseUp() {
      clearState()
    }

    function handleMouseLeave() {
      clearState()
    }

    function clearState() {
      mouseDown.value = false
      startPosition = { x: 0, y: 0 }
    }

    function handleCloseClick() {
      emit('close')
    }

    function calcBaseSize() {
      const { clientWidth, clientHeight } = viewportRef.value
      const { naturalWidth, naturalHeight } = imageRef.value
      const viewportRatio = clientWidth / clientHeight
      const imageRatio = naturalWidth / naturalHeight
      if (viewportRatio > imageRatio) {
        // image height base
        baseSize.value = {
          width: (naturalWidth / naturalHeight) * clientHeight || 0,
          height: clientHeight || 0,
        }
      } else {
        // image width base
        baseSize.value = {
          width: clientWidth || 0,
          height: (naturalHeight / naturalWidth) * clientWidth || 0,
        }
      }

      if (baseSize.value.width === 0 || baseSize.value.height === 0) {
        setTimeout(calcBaseSize, 100)
      }
    }

    onMounted(() => {
      calcBaseSize()
      imageRef.value.addEventListener('mousedown', handleMouseDown)
      imageRef.value.addEventListener('mousemove', handleMouseMove)
      imageRef.value.addEventListener('mouseup', handleMouseUp)
      imageRef.value.addEventListener('mouseleave', handleMouseLeave)
      window.addEventListener('keydown', handleZoomKeyPress)
      modalHelper.addModal()
    })

    onUnmounted(() => {
      imageRef.value.removeEventListener('mousedown', handleMouseDown)
      imageRef.value.removeEventListener('mousemove', handleMouseMove)
      imageRef.value.removeEventListener('mouseup', handleMouseUp)
      imageRef.value.removeEventListener('mouseleave', handleMouseLeave)
      window.removeEventListener('keydown', handleZoomKeyPress)
      modalHelper.removeModal()
    })

    return {
      viewportRef,
      imageRef,
      size,
      margin,
      handleZoomClick,
      handleCloseClick,
      handleZoomKeyPress,
      scale,
      castFixed,
      mouseDown,
    }
  },
}
