import footer from './footer'
import navigation from './navigation'
import theme from './theme'
import locales from './locales'

export default {
  // footer configs
  footer,

  // navigation configs
  navigation,

  // theme configs
  theme,

  locales,
}
