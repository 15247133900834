const state = {
  context: null,
  route: null,
  router: null,
  signin: null,
  env: {
    EXTERNAL_BE_NEURAL_STUDIO: null,
    X_API_KEY: 'neuralworksmycampus',
    DEDICATED: false,
  },
}

function setContext(context, route, router, signin) {
  state.context = context
  state.route = route
  state.router = router
  state.signin = signin
  setEnv(context.$config)
  Object.keys(context.$config).forEach(
    (key) => (process.env[key] = context.$config[key])
  )
}

function setEnv(env) {
  const _env = Object.keys(env).reduce(
    (acc, key) =>
      env[key] === undefined ? { ...acc } : { ...acc, [key]: env[key] },
    {}
  )
  Object.assign(state.env, _env)
}

export default function useGlobal() {
  return {
    state,
    setContext,
    setEnv,
  }
}
