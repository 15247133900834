//
//
//
//
//
//
//

import useI18nHelper from '@/helpers/i18nHelper'
import config from '../configs'

export default {
  setup(_, context) {
    useI18nHelper(context.root)
  },
  data() {
    return {
      footer: config.footer,
    }
  },
}
