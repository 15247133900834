//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '검색어를 입력하세요.',
    },
  },
  setup(props, { emit }) {
    const text = ref('')
    const changeText = ({ target: { value } }) =>
      emit('change', (text.value = value))
    return {
      props,
      text,
      changeText,
    }
  },
}
