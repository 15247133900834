//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import useI18N from '../../translations/i18n'
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    no: {
      type: String,
      default: '',
    },
    yes: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default: () => {},
    },
    loading: Boolean,
  },
  setup(props) {
    const $t = useI18N()

    function noClick() {
      if (props.loading) return
      props.callback(false)
    }
    function yesClick() {
      if (props.loading) return
      props.callback(true)
    }
    return {
      noClick,
      yesClick,
      confirm_yes: props.yes || $t('mesg.components.confirm_yes'),
      confirm_no: props.no || $t('mesg.components.confirm_no'),
    }
  },
}
