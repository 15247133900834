/* eslint-disable import/no-mutable-exports */
/* eslint-disable eqeqeq */
import { OFF } from './domClasses'

import { change, dppxchange } from './strings'

import { assign } from './utils'

export const doc = document
export const win = window
export let pxRatio

let query

function setPxRatio() {
  const _pxRatio = devicePixelRatio

  // during print preview, Chrome fires off these dppx queries even without changes
  if (pxRatio != _pxRatio) {
    pxRatio = _pxRatio

    query && off(change, query, setPxRatio)
    query = matchMedia(
      `(min-resolution: ${pxRatio - 0.001}dppx) and (max-resolution: ${
        pxRatio + 0.001
      }dppx)`
    )
    on(change, query, setPxRatio)

    win.dispatchEvent(new CustomEvent(dppxchange))
  }
}

export function addClass(el, c) {
  if (c != null) {
    const cl = el.classList
    !cl.contains(c) && cl.add(c)
  }
}

export function remClass(el, c) {
  const cl = el.classList
  cl.contains(c) && cl.remove(c)
}

export function setStylePx(el, name, value) {
  el.style[name] = value + 'px'
}

export function placeTag(tag, cls, targ, refEl) {
  const el = doc.createElement(tag)

  if (cls != null) addClass(el, cls)

  if (targ != null) targ.insertBefore(el, refEl)

  return el
}

export function placeDiv(cls, targ) {
  return placeTag('div', cls, targ)
}

const xformCache = new WeakMap()

export function elTrans(el, xPos, yPos, xMax, yMax) {
  const xform = 'translate(' + xPos + 'px,' + yPos + 'px)'
  const xformOld = xformCache.get(el)

  if (xform != xformOld) {
    el.style.transform = xform
    xformCache.set(el, xform)

    if (xPos < 0 || yPos < 0 || xPos > xMax || yPos > yMax) addClass(el, OFF)
    else remClass(el, OFF)
  }
}

const colorCache = new WeakMap()

export function elColor(el, background, borderColor) {
  const newColor = background + borderColor
  const oldColor = colorCache.get(el)

  if (newColor != oldColor) {
    colorCache.set(el, newColor)
    el.style.background = background
    el.style.borderColor = borderColor
  }
}

const sizeCache = new WeakMap()

export function elSize(el, newSize) {
  const oldSize = sizeCache.get(el)

  if (newSize != oldSize) {
    sizeCache.set(el, newSize)
    el.style.height = el.style.width = newSize + 'px'
    el.style.marginLeft = el.style.marginTop = -newSize / 2 + 'px'
  }
}

const evOpts = { passive: true }
const evOpts2 = assign({ capture: true }, evOpts)

export function on(ev, el, cb, capt) {
  el.addEventListener(ev, cb, capt ? evOpts2 : evOpts)
}

export function off(ev, el, cb, capt) {
  el.removeEventListener(ev, cb, capt ? evOpts2 : evOpts)
}

setPxRatio()
