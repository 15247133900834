//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: [Number, String],
      default: '',
    },
    subtext: {
      type: String,
      default: '',
    },
    crownImage: {
      type: String,
      default: '',
    },
    status: Boolean,
    error: Boolean,
    double: Boolean,
    textonly: Boolean,
  },
  setup(props, { emit }) {
    const nsClick = (e) => emit('click', e)

    return { props, nsClick }
  },
}
