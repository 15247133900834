import { render, staticRenderFns } from "./NsImageView.vue?vue&type=template&id=b1418f28&"
import script from "./NsImageView.vue?vue&type=script&lang=js&"
export * from "./NsImageView.vue?vue&type=script&lang=js&"
import style0 from "./NsImageView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NsIcon: require('/usr/app/src/components/ns/NsIcon.vue').default})
