import { setupWorker } from 'msw'
import { getHandlers } from '.'

export default ({ $config }) => {
  console.log('mock server start')
  console.log($config.EXTERNAL_BE_NEURAL_STUDIO)
  if (process.env.NODE_ENV === 'production') return
  if (!$config.MOCK) return
  const worker = setupWorker(...getHandlers($config.EXTERNAL_BE_NEURAL_STUDIO))
  worker.start({
    serviceWorker: {
      url: '/studio/mockServiceWorker.js',
    },
    onUnhandledRequest: 'bypass',
  })
}
