export default {
  menu: [
    {
      icon: require('assets/navigation/learn.svg'),
      // name: '학습 하기',
      name: 'menu.training',
      to: '/instruction',
    },
    {
      icon: require('assets/navigation/result.svg'),
      // name: '학습 보기',
      name: 'menu.result',
      to: '/job',
    },
    {
      icon: require('assets/navigation/deploy.svg'),
      // name: '추론 하기',
      name: 'menu.deployment',
      to: '/deploy',
    },
    {
      icon: require('assets/navigation/dataset.svg'),
      // name: '데이터셋 보기',
      name: 'menu.dataset',
      to: '/datasets',
    },
    {
      icon: 'insights',
      css: true,
      // name: '데이터 다루기',
      name: 'menu.handling',
      to: '/handling',
    },
    {
      icon: require('assets/navigation/model.svg'),
      // name: '모델 보기',
      name: 'menu.model',
      to: '/model',
    },
    {
      icon: 'pageview',
      css: true,
      // name: '비교 하기',
      name: 'menu.comparison',
      to: '/compare',
    },
    {
      icon: require('assets/navigation/annotation.svg'),
      // name: '어노테이션',
      name: 'menu.annotation',
      to: '/annotation',
      adminAccessOnly: true,
    },
    {
      icon: require('assets/navigation/gpu.svg'),
      // name: 'GPU 자원 관리',
      name: 'menu.GPU',
      to: '/machine',
      adminAccessOnly: true,
    },
  ],
}
